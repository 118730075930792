import { DropdownOption } from '@ubnt/ui-components/Dropdown'
import { ALL_KEY } from 'sharedConstants'

export const createDropdownList = (
  subscriptionsList?: string[]
): DropdownOption[] => {
  if (!subscriptionsList) {
    return []
  }

  const dropdownOptions = [{ label: 'All Services', value: ALL_KEY }]

  subscriptionsList.forEach((sub) => {
    dropdownOptions.push({
      label: sub,
      value: sub,
    })
  })

  return dropdownOptions
}

export const isSubscriptionNameIncluded = (
  subscriptionName: string,
  activeFilter: string
) => {
  if (activeFilter === ALL_KEY) {
    return true
  }
  return subscriptionName === activeFilter
}

import React from 'react'
import { NumericFormat } from 'react-number-format'
import { SubscriptionServiceTableItem } from '../Components.styles'
import { getCurrencySymbol } from '../utils'
import { StripeRegionCode } from 'features/stripe/ui/types'
import { CountryFlag } from 'components/CountryFlag'
import { FormattedMessage } from 'react-intl'
import { Tooltip } from '@ubnt/ui-components/Tooltip'
import InfoIcon from '@ubnt/icons/InfoIcon'

interface Props {
  amount: number
  currency: string
  region: StripeRegionCode
  isCanceled?: boolean
}

export const Amount: React.FC<Props> = ({
  amount,
  currency,
  region,
  isCanceled,
}) => {
  if (isCanceled) {
    return (
      <SubscriptionServiceTableItem>
        <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CANCELED_AT_MONTH_END_ROW" />
        <Tooltip
          tooltipClassName="customSubscriptionsTooltipWidth override-index"
          description={
            <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_TABLE_CANCELED_TOOLTIP" />
          }
        >
          <InfoIcon size={20} isActive />
        </Tooltip>
      </SubscriptionServiceTableItem>
    )
  }

  return (
    <SubscriptionServiceTableItem>
      <CountryFlag countryCode={region} noMargin />
      <NumericFormat
        value={amount}
        displayType={'text'}
        thousandSeparator={true}
        prefix={getCurrencySymbol(currency)}
        decimalScale={2}
        fixedDecimalScale
      />
    </SubscriptionServiceTableItem>
  )
}

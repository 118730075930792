import { boolean, object, string } from 'yup'
import { PASSWORD_MIN_LENGTH } from '../checkPasswprdRequirements'

export const registerFormValidationSchema = object().shape({
  username: string()
    .required()
    .min(1)
    .max(30)
    .username()
    .label('COMMON_LABEL_USERNAME'),
  email: string().required().email().label('COMMON_LABEL_EMAIL'),
  password: string()
    .required()
    .min(PASSWORD_MIN_LENGTH)
    .password()
    .label('COMMON_LABEL_PASSWORD'),
  passwordConfirm: string().required().label('COMMON_LABEL_PASSWORD_CONFIRM'),
  agreedToTerms: boolean()
    .oneOf([true], 'Please agree to the terms and conditions.')
    .required(),
  captcha: string().recaptcha(),
})

export type RegisterFormSchema = {
  email: string
  username: string
  password: string
  passwordConfirm: string
  agreedToTerms: boolean
  mailinglist?: boolean
  curr_terms_rev?: string
  curr_privacy_rev?: string
  curr_terms_aircrm_rev?: string
  site_key?: string
}

import { all, put, spawn, takeEvery } from 'redux-saga/effects'
import { api } from 'api'

import createDataModule from 'utils/moduleCreator'
import { RootState } from 'types/types'

const {
  api: { sso },
} = __CONFIG__

export const dataKey = 'roles'

export interface RolesState {
  roles?: string[]
}

const getUrl = (url: string, { profileUuid }: { profileUuid?: string } = {}) =>
  profileUuid ? sso.paths.rolesBeta.replace('{uuid}', profileUuid) : url

const dataModule = createDataModule<RolesState>(
  dataKey,
  sso.paths.roles,
  api.ssoBase,
  undefined,
  getUrl
)

export const {
  fetch: fetchRoles,
  create: addBetaRole,
  selectIsLoading: selectIsRolesLoading,
  selectHasFetchedOnce: selectIsRolesFetched,
  reducer: rolesReducer,
} = dataModule

export const selectRoles = (state: RootState) => {
  const data = dataModule.selectData(state)
  return data.roles || []
}

function* addRoleDoneSaga() {
  yield put(fetchRoles())
}

function* subscribeToAddRoleDoneSaga() {
  yield takeEvery(dataModule.CREATE_DONE, addRoleDoneSaga)
}

export function* rolesRootSaga() {
  yield all([spawn(dataModule.rootSaga), spawn(subscribeToAddRoleDoneSaga)])
}

import { useMutation } from '@tanstack/react-query'
import { SsoAccountApi } from 'api/sso/createAccount'

const ssoAccountApi = new SsoAccountApi()

export const useCreateAccount = () => {
  const createAccount = useMutation({
    mutationFn: ssoAccountApi.create,
    onSuccess: () => {
      window.location.href = '/register/success'
    },
  })

  return {
    createAccount: createAccount.mutate,
    createAccountError: createAccount.error,
    isCreateAccountLoading: createAccount.isPending,
    resetCreateAccountErrors: createAccount.reset,
  }
}

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { BillingCardsApi } from 'api/billing/billingCards'
import { closeVisibleModal } from 'modules/modals'
import { QueryKey } from 'store/types'
import { useToasts } from 'pages/payments/components/useToasts'

const billingCardsApi = new BillingCardsApi()

export const useUpdateCard = () => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const { paymentMethodSuccessToast } = useToasts()

  const updateCard = useMutation({
    mutationFn: billingCardsApi.update,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.BILLING_CARDS],
      })
      paymentMethodSuccessToast('updated')
      dispatch(closeVisibleModal())
    },
  })

  return {
    updateCard: updateCard.mutate,
    updateCardError: updateCard.error,
    isUpdateCardLoading: updateCard.isPending,
    resetUpdateCardErrors: updateCard.reset,
  }
}

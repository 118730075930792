import React from 'react'
import { SubscriptionServiceTableItem } from '../Components.styles'
import { SubscriptionWarning } from '../../subscriptions/SubscriptionWarning'

interface Props {
  plan?: string | null
  isFailed: boolean
  cardNotFound: boolean
}

export const Plan: React.FC<Props> = ({ plan, isFailed, cardNotFound }) => {
  return (
    <SubscriptionServiceTableItem>
      {(isFailed || cardNotFound) && (
        <SubscriptionWarning
          message={
            isFailed
              ? 'SETTINGS_SUBSCRIPTIONS_TOOLTIP_WARNING'
              : 'SETTINGS_SUBSCRIPTIONS_PAYMENT_CARD_NOT_FOUND'
          }
        />
      )}
      {plan ?? '-'}
    </SubscriptionServiceTableItem>
  )
}

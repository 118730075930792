import React, { useContext } from 'react'
import { NumericFormat } from 'react-number-format'
import { FormattedMessage } from 'react-intl'
import { Button } from '@ubnt/ui-components/Button'
import { InvoicePill } from './InvoicePill'
import { StripeStatus } from 'sharedConstants'
import { ValueCell } from '../Invoices.styles'
import styled from 'theme/styled'
import { GridRow } from './InvoicesTable.styles'

import { getAmount, getCurrencySymbol } from './utils'
import { ParsedInvoice } from '../types'
import { InvoiceContext } from '../../InvoiceContext'
import { StripeInvoice } from 'features/subscriptions/module/types'
import { CountryFlag } from 'components/CountryFlag'

type Props = ParsedInvoice & {
  invoice: StripeInvoice
}

export const InvoicesTableRow: React.FC<Props> = ({
  name,
  date,
  billedCard,
  subscriptionName,
  status,
  total,
  currency,
  invoice,
}) => {
  const { handleOpenInvoice } = useContext(InvoiceContext)
  return (
    <GridRow>
      <InvoicePill variant={status} />
      <ValueCell>{subscriptionName}</ValueCell>
      <ValueCell className="extra-data">{name}</ValueCell>
      <ValueCell className="extra-data">{date}</ValueCell>
      <ValueCell
        className="extra-data"
        color={status === StripeStatus.OPEN ? 'red-06' : 'inherit'}
      >
        {billedCard}
      </ValueCell>
      <AmountCell>
        <CountryFlag countryCode={invoice.region} noMargin />
        <NumericFormat
          value={getAmount(total, currency)}
          displayType="text"
          thousandSeparator
          prefix={getCurrencySymbol(currency)}
          decimalScale={2}
          fixedDecimalScale
        />
      </AmountCell>
      <StyledButton
        variant="link"
        onClick={() => {
          handleOpenInvoice?.(invoice)
        }}
      >
        <FormattedMessage id="COMMON_ACTION_VIEW" />
      </StyledButton>
    </GridRow>
  )
}

const AmountCell = styled(ValueCell)`
  display: flex;
  gap: 4px;
`

const StyledButton = styled(Button)`
  padding: 0;
`

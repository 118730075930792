import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'

import { GenericModal } from 'components/generic-modal/GenericModal'
import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import { FooterAction } from '@ubnt/ui-components/Footer/Footer'
import { useDispatch } from 'react-redux'
import { updateProfileData } from 'modules/profile'

export const PASSKEY_PROMPT_PRIMARY_MODAL_ID = 'PASSKEY_PROMPT_PRIMARY_MODAL_ID'

const PasskeyPromptPrimaryModal: React.FC<ModalProps & { id?: string }> = ({
  isOpen,
  onClose,
  id,
}) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const setPrimary = useCallback(() => {
    const data = {
      default_mfa: id,
    }
    dispatch(updateProfileData(data))
  }, [dispatch, id])

  const actions: FooterAction[] = [
    {
      text: intl.formatMessage({
        id: 'COMMON_ACTION_CLOSE',
      }),
      variant: 'tertiary',
      onClick: onClose,
    },
    {
      text: intl.formatMessage({
        id: 'SETTINGS_MFA_SET_PRIMARY_METHOD',
      }),
      variant: 'primary',
      onClick: () => setPrimary(),
    },
  ]

  return (
    <GenericModal
      isOpen={isOpen}
      onRequestClose={onClose}
      title={intl.formatMessage(
        { id: 'SETTINGS_MFA_TOAST' },
        { method: 'Passkey' }
      )}
      actions={actions}
    >
      {intl.formatMessage({
        id: 'SETTINGS_MFA_PROMPT_METHOD_AS_PRIMARY_MODAL',
      })}
    </GenericModal>
  )
}

const PasskeyPromptPrimaryModalWrapped: React.FC<{ id?: string }> = ({
  id,
}) => {
  return (
    <ModalWrapper modalId={PASSKEY_PROMPT_PRIMARY_MODAL_ID}>
      <PasskeyPromptPrimaryModal id={id} />
    </ModalWrapper>
  )
}

export default PasskeyPromptPrimaryModalWrapped

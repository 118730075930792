import React, { useMemo } from 'react'
import ArrowRightTertiaryIcon from '@ubnt/icons/ArrowRightTertiaryIcon'
import { useFullSubscriptions } from 'features/subscriptions/fullSubscriptions/useFullSubscriptions'
import {
  ServiceGrid,
  ServiceName,
  SubscriptionsGridBody,
  SubscriptionsTableItem,
  TableData,
} from './Components.styles'
import {
  getSubscriptionIcon,
  getSubscriptionServiceText,
  getSubscriptionServiceUrl,
} from './utils'
import { SubscriptionWarning } from './SubscriptionWarning'
import { useHistory } from 'react-router-dom'
import { ProductName } from 'types/enums'

export const SubscriptionGroupRow: React.FC<{ group: string }> = ({
  group,
}) => {
  const {
    fullSubscriptionsToDisplay: fullSubscriptions,
    groupedSubscriptions,
  } = useFullSubscriptions()
  const history = useHistory()

  const getHasFailed = (group: string) =>
    !!fullSubscriptions.find(
      (fullSubscription) =>
        fullSubscription.isFailed && fullSubscription.name === group
    )
  const hasFailed = getHasFailed(group)

  const getHasCardNotFound = (group: string) =>
    !!fullSubscriptions.find(
      (fullSubscription) =>
        fullSubscription.cardNotFound && fullSubscription.name === group
    )
  const hasCardNotFound = getHasCardNotFound(group)

  const handleNavigate = () => {
    const url = getSubscriptionServiceUrl(group)
    history.push(url)
  }

  const nrOfSubscriptions = useMemo(() => {
    if (group === ProductName.HOSTING || group === ProductName.UISP_CLOUD) {
      let amount = 0
      groupedSubscriptions[group].forEach((sub) => {
        if (sub.items.data.length > 1) {
          sub.items.data.forEach((item) => {
            amount += item.quantity ?? 0
          })
        } else {
          amount += sub.quantity ?? 0
        }
      })
      return amount
    }

    return groupedSubscriptions[group].length
  }, [group, groupedSubscriptions])

  return (
    <SubscriptionsGridBody onClick={handleNavigate}>
      <SubscriptionsTableItem variant="body-primary" color="text-2">
        <ServiceGrid>
          {getSubscriptionIcon(group)}
          <ServiceName>{group}</ServiceName>
        </ServiceGrid>
      </SubscriptionsTableItem>
      <SubscriptionsTableItem variant="body-primary" color="text-2">
        <TableData>
          {(hasFailed || hasCardNotFound) && (
            <SubscriptionWarning
              message={
                hasFailed
                  ? 'SETTINGS_SUBSCRIPTIONS_TOOLTIP_WARNING'
                  : 'SETTINGS_SUBSCRIPTIONS_PAYMENT_CARD_NOT_FOUND'
              }
            />
          )}
          {getSubscriptionServiceText(group, nrOfSubscriptions)}
        </TableData>
        <ArrowRightTertiaryIcon className="arrow-icon" size={20} />
      </SubscriptionsTableItem>
    </SubscriptionsGridBody>
  )
}

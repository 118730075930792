import React, { useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import type { Tab } from '@ubnt/ui-components/Tabs'
import { EmptyResult } from '@ubnt/ui-components/EmptyResult'
import { ProtectedPage } from 'pages/ProtectedPage'
import AppViewHeader from 'components/AppViewHeader'
import BackupsSkeleton from './BackupsSkeleton'
import { useNcaDevicesQuery } from 'store/queries/useNcaDevicesQuery'
import { useNcaBackupsQuery } from 'store/queries/useNcaBackupsQuery'
import { useNcaArchivedBackupsQuery } from 'store/queries/useNcaArchivedBackupsQuery'
import { BackupsErrorPlaceholder } from './components/BackupsErrorPlaceholder'
import { BackupsGrid } from './components/BackupsGrid'
import { BackupsTabs } from './components/BackupsTabs'
import { BackupsSidePanel } from './components/sidepanel/BackupsSidePanel'
import { TabState, MixedDeviceBackups, SortState } from './components/types'
import { getSortingFunctionForBackupDevices } from './components/utils'
import WrappedSubmitPasswordModal from './components/SubmitPasswordModal'
import DeleteBackupModal from './components/DeleteBackupModal'

const Backups: React.FC = () => {
  const { areNcaDevicesLoading, areNcaDevicesFetched } = useNcaDevicesQuery()
  const { backupsFromAllDevices, areNcaBackupsLoading, ncaBackupsError } =
    useNcaBackupsQuery()
  const {
    parsedNcaArchivedBackups,
    areNcaArchivedBackupsLoading,
    ncaArchivedBackupsError,
  } = useNcaArchivedBackupsQuery()

  const [selectedTab, setSelectedTab] = useState<TabState>(TabState.ACTIVE)
  const [clickedDevice, setClickedDevice] = useState<string | null>(null)
  const [sortMethod, setSortMethod] = useState<SortState>(SortState.ASC_NAME)
  const [searchTerm, setSearchTerm] = useState<string | null>(null)

  const isActiveBackupsTabSelected = selectedTab === TabState.ACTIVE

  const areBackupsLoading = isActiveBackupsTabSelected
    ? areNcaBackupsLoading
    : areNcaArchivedBackupsLoading

  const isPageLoading =
    areBackupsLoading || areNcaDevicesLoading || !areNcaDevicesFetched

  const hasError = isActiveBackupsTabSelected
    ? !!ncaBackupsError
    : !!ncaArchivedBackupsError

  const hasBackups = useMemo(() => {
    return isActiveBackupsTabSelected
      ? !!backupsFromAllDevices?.length
      : !!parsedNcaArchivedBackups?.length
  }, [
    backupsFromAllDevices?.length,
    isActiveBackupsTabSelected,
    parsedNcaArchivedBackups,
  ])

  const handleOnSearchChange = (
    _e: React.ChangeEvent<HTMLInputElement>,
    value: string | number
  ) => {
    setSearchTerm((value as string) ?? null)
  }

  const displayedDevices = useMemo(() => {
    let devices: MixedDeviceBackups[] =
      selectedTab === TabState.ACTIVE
        ? backupsFromAllDevices
        : parsedNcaArchivedBackups

    if (searchTerm) {
      devices = devices.filter((device) =>
        device.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    }

    return devices.sort(getSortingFunctionForBackupDevices(sortMethod))
  }, [
    backupsFromAllDevices,
    parsedNcaArchivedBackups,
    searchTerm,
    selectedTab,
    sortMethod,
  ])

  const handleSelectDevice = (deviceId: string) => setClickedDevice(deviceId)

  const handleCloseSidePanel = () => setClickedDevice(null)

  const handleClickSortMethod = () => {
    if (sortMethod === SortState.ASC_NAME) {
      return setSortMethod(SortState.DESC_NAME)
    }

    return setSortMethod(SortState.ASC_NAME)
  }

  const handleClickTab = (tab: Tab) => {
    setSelectedTab(tab.id as TabState)
  }

  const renderPageContent = () => {
    if (isPageLoading) {
      return <BackupsSkeleton />
    }

    if (hasError) {
      return <BackupsErrorPlaceholder />
    }

    if (!hasBackups) {
      return (
        <EmptyResult
          title={
            <FormattedMessage
              id="SETTINGS_BACKUPS_EMPTY_TITLE"
              values={{
                type: isActiveBackupsTabSelected ? '' : 'Archived',
              }}
            />
          }
          description={
            <FormattedMessage
              id="SETTINGS_BACKUPS_EMPTY_DESC"
              values={{
                type: isActiveBackupsTabSelected ? '' : 'Archived',
              }}
            />
          }
          size="large"
        />
      )
    }

    return (
      <>
        <BackupsGrid
          devices={displayedDevices}
          onClickRow={handleSelectDevice}
          clickedDevice={clickedDevice}
          onClickSortIcon={handleClickSortMethod}
          sort={sortMethod}
          onSearchChange={handleOnSearchChange}
        />
        <BackupsSidePanel
          selectedDeviceId={clickedDevice}
          selectedTab={selectedTab}
          onClose={handleCloseSidePanel}
        />
      </>
    )
  }

  return (
    <ProtectedPage renderMargin>
      <AppViewHeader headerId="COMMON_SIDE_NAV_CLOUD_BACKUPS" />
      <BackupsTabs onClick={handleClickTab} />
      {renderPageContent()}
      <WrappedSubmitPasswordModal />
      <DeleteBackupModal />
    </ProtectedPage>
  )
}

export default Backups

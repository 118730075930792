import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import { FormattedMessage } from 'react-intl'
import Tabs from '@ubnt/ui-components/Tabs/Tabs'
import RadioList from '@ubnt/ui-components/Radio/RadioList'
import Button from '@ubnt/ui-components/Button/Button'
import NetworkLogo from '@ubnt/icons/NetworkLogo'
import { Alert } from '@ubnt/ui-components/Alert'
import { Text } from '@ubnt/ui-components/aria'
import { FormType } from 'pages/supportForm/types'
import styled from 'theme/styled'
import theme, { typography } from 'theme'
import supportImage from 'assets/images/support/supportHeader.png'
import supportImageLow from 'assets/images/support/supportHeader_low.png'
import { UniFiLogoIcon } from 'components/UniFiLogoIcon'
import { HeaderFadeIn } from 'components/animations'
import { BackButton } from 'components/BackButton'
import { MediaSizingContext } from 'components/mediaSizing/MediaSizingContext'
import { boldText } from 'components/SharedComponents'
import ExternalLink from 'components/ExternalLink'
import { uiLinks } from 'redirectLinks'
import { MaintenanceInfo } from 'features/status/ui'

export const Header: React.FC<{
  handleChange: Dispatch<SetStateAction<FormType>>
  selectedForm: FormType
  isFormSubmitted: boolean
  followUpTicketId?: string
  followUpTicketDesc?: string
}> = ({
  handleChange,
  selectedForm,
  isFormSubmitted = false,
  followUpTicketDesc,
  followUpTicketId,
}) => {
  const { isMaxTabletLarge } = useContext(MediaSizingContext)
  const [imageLoaded, setImageLoaded] = useState(false)

  useEffect(() => {
    const imageToLoad = new Image()
    imageToLoad.src = supportImage
    imageToLoad.onload = () => setImageLoaded(true)
  }, [])

  const getIsRadioChecked = (id: FormType) => selectedForm === id
  const isUniFiFormSelected = selectedForm === FormType.UNIFI

  return (
    <Wrapper $isLargeTablet={isMaxTabletLarge}>
      <ButtonWrapper>
        <BackButton to="/requests" />
        <MaintenanceInfo omitMarginBottom />
      </ButtonWrapper>
      <Title $isLargeTablet={isMaxTabletLarge}>
        <FormattedMessage id="SUPPORT_HEADER_TITLE" />
      </Title>
      {followUpTicketId && (
        <FollowUpContainer>
          <FollowUpDescription>
            <FormattedMessage
              id="SUPPORT_HEADER_FOLLOW_TICKET_DESCRIPTION"
              values={{
                ticketId: followUpTicketId,
                ticketDesc: followUpTicketDesc,
                b: boldText,
              }}
            />
          </FollowUpDescription>
        </FollowUpContainer>
      )}
      <Description
        $isLargeTablet={isMaxTabletLarge}
        $isFormSubmitted={isFormSubmitted}
      >
        <FormattedMessage id="SUPPORT_HEADER_DESCRIPTION" />
      </Description>
      {!isFormSubmitted && (
        <>
          <Label $isLargeTablet={isMaxTabletLarge}>
            <FormattedMessage id="SUPPORT_HEADER_LABEL" />
          </Label>
          {isMaxTabletLarge ? (
            <ButtonContainer>
              <Button
                variant={isUniFiFormSelected ? 'primary' : 'tertiary'}
                onClick={() => handleChange(FormType.UNIFI)}
                wrapClassName={
                  isUniFiFormSelected
                    ? 'button-unifi'
                    : 'button-unifi button-white'
                }
              >
                <NetworkLogo width="20px" height="20px" variant="aqua" />
                <UniFiLogoIcon
                  fill={isUniFiFormSelected ? 'white' : '#808893'}
                />
                <FormattedMessage id="SUPPORT_UNIFI_FORM_TITLE" />
              </Button>
              <Button
                variant={isUniFiFormSelected ? 'tertiary' : 'primary'}
                onClick={() => handleChange(FormType.UISP)}
                wrapClassName={isUniFiFormSelected ? 'button-white' : ''}
              >
                <FormattedMessage id="SUPPORT_OTHER_FORM_TITLE" />
              </Button>
              <Button<'a'>
                variant="tertiary"
                href={uiLinks.rma}
                target="_blank"
                wrapClassName="button-white"
              >
                <FormattedMessage id="SUPPORT_RMA_FORM_TITLE" />
              </Button>
            </ButtonContainer>
          ) : (
            <StyledTabs
              $isLargeTablet={isMaxTabletLarge}
              onSelectTab={(tab) =>
                tab.id === 'OTHER'
                  ? handleChange(FormType.UISP)
                  : handleChange(FormType.UNIFI)
              }
              selectedTab={
                selectedForm === FormType.UNIFI ? FormType.UNIFI : 'OTHER'
              }
              tabs={[
                {
                  id: FormType.UNIFI,
                  label: (
                    <TabLabel>
                      <NetworkLogo width="20px" height="20px" variant="aqua" />
                      <UniFiLogoIcon
                        fill={isUniFiFormSelected ? 'white' : '#808893'}
                      />
                      <FormattedMessage id="SUPPORT_UNIFI_FORM_TITLE" />
                    </TabLabel>
                  ),
                },
                {
                  id: 'OTHER',
                  label: <FormattedMessage id="SUPPORT_OTHER_FORM_TITLE" />,
                },
                {
                  id: 'RMA',
                  label: (
                    <ExternalLink href={uiLinks.rma}>
                      <FormattedMessage id="SUPPORT_RMA_FORM_TITLE" />
                    </ExternalLink>
                  ),
                },
              ]}
              variant="filter"
              selectedTabClassName="formType-selected"
              tabClassName="formType"
            />
          )}
          <StyledRadioList
            direction={isMaxTabletLarge ? 'column' : 'row'}
            $isLargeTablet={isMaxTabletLarge}
            $isRadioHidden={isUniFiFormSelected}
            items={[
              {
                checked: getIsRadioChecked(FormType.UISP),
                children: <FormattedMessage id="SUPPORT_UISP_FORM_TITLE" />,
                id: FormType.UISP,
                onChange: () => handleChange(FormType.UISP),
              },
              {
                checked: getIsRadioChecked(FormType.STORE),
                children: <FormattedMessage id="SUPPORT_STORE_FORM_TITLE" />,
                id: FormType.STORE,
                onChange: () => handleChange(FormType.STORE),
              },
              {
                checked: getIsRadioChecked(FormType.BILLING),
                children: <FormattedMessage id="SUPPORT_BILLING_FORM_TITLE" />,
                id: FormType.BILLING,
                onChange: () => handleChange(FormType.BILLING),
              },
              {
                checked: getIsRadioChecked(FormType.UMR),
                children: <FormattedMessage id="SUPPORT_MOBILITY_FORM_TITLE" />,
                id: FormType.UMR,
                onChange: () => handleChange(FormType.UMR),
              },
            ]}
            variant="boxed"
          />
          <StyledAlert
            variant={isMaxTabletLarge ? 'global' : 'inline'}
            type="info"
            text={
              <SyledText variant="body-primary">
                <FormattedMessage
                  id="SUPPORT_RMA_BANNER_TEXT"
                  values={{
                    a: (text) => (
                      <a href={uiLinks.rma} target="_blank">
                        {text}
                      </a>
                    ),
                  }}
                />
              </SyledText>
            }
          />
        </>
      )}
      {!isMaxTabletLarge && <HeaderImage $imageLoaded={imageLoaded} />}
    </Wrapper>
  )
}

const ButtonWrapper = styled.div`
  display: flex;
  gap: 6px;
  align-items: baseline;
  @media (max-width: ${theme.media.tabletLarge}) {
    align-self: flex-start;
  }

  @media (max-width: ${theme.media.mobileXLarge}) {
    flex-direction: column;
  }
`

const Wrapper = styled.div<{
  $isLargeTablet: boolean
}>`
  position: relative;
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: ${({ $isLargeTablet }) =>
    $isLargeTablet ? 'center' : 'flex-start'};
  padding-top: 18px;
`

const Title = styled.div<{ $isLargeTablet: boolean }>`
  max-width: 345px;
  width: 100%;
  font: ${typography['support-header']};
  color: ${({ theme }) => theme.neutral11};
  padding: 32px 0 8px 0;
  text-align: ${({ $isLargeTablet }) => ($isLargeTablet ? 'center' : 'left')};

  @media (max-width: ${theme.media.mobileLarge}) {
    line-height: normal;
  }
`

const Description = styled.div<{
  $isLargeTablet: boolean
  $isFormSubmitted: boolean
}>`
  max-width: 345px;
  width: 100%;
  font: ${typography['body-large']};
  color: #858585;
  padding-bottom: 32px;
  text-align: ${({ $isLargeTablet }) => ($isLargeTablet ? 'center' : 'left')};
  /* This will hide Part of the form header on submit success screen */
  margin-bottom: ${({ $isFormSubmitted }) =>
    $isFormSubmitted ? '128px' : '0'};
`

const Label = styled.div<{ $isLargeTablet: boolean }>`
  max-width: 345px;
  width: 100%;
  font: ${typography['header-label']};
  color: ${({ theme }) => theme.neutral10};
  padding-bottom: 16px;
  text-align: ${({ $isLargeTablet }) => ($isLargeTablet ? 'center' : 'left')};
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 24px;

  .button-unifi {
    span {
      gap: 4px;
    }
  }

  .button-white {
    background-color: ${({ theme }) => theme.neutral00};
    a:hover {
      color: ${({ theme }) => theme.text2};
    }
  }
`

const StyledTabs = styled(Tabs, {
  shouldForwardProp: (props) => props !== '$isLargeTablet',
})<{ $isLargeTablet: boolean }>`
  justify-content: ${({ $isLargeTablet }) =>
    $isLargeTablet ? 'center' : 'flex-start'};
  padding-bottom: 24px;

  div {
    margin: 0;
  }

  button::after {
    display: none;
  }

  .formType {
    border: none;
    background-color: ${({ theme }) => theme.neutral00};
    padding: 8px 16px;

    a {
      color: ${({ theme }) => theme.neutral08};
    }

    a:hover {
      color: ${({ theme }) => theme.neutral06};
    }

    &:last-of-type {
      padding: 8px 24px;
    }
  }

  .formType-selected {
    background-color: ${({ theme }) => theme.ublue06};
    span {
      color: #ffffff !important;
      font-weight: 700;
    }
  }
`

const TabLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

const HeaderImage = styled.div<{
  $imageLoaded: boolean
}>`
  position: absolute;
  top: -50px;
  right: -60px;
  width: 616px;
  height: 520px;
  background-size: contain;
  ${({ $imageLoaded }) => {
    if (!$imageLoaded) {
      return `
      -webkit-filter: blur(6px);
      -moz-filter: blur(6px);
      -o-filter: blur(6px);
      -ms-filter: blur(6px);
      filter: blur(6px);
      background-image: url(${supportImageLow})
    `
    }
    if ($imageLoaded) {
      return `
      background-image: url(${supportImage})

    `
    }
  }}
`

const StyledRadioList = styled(RadioList, {
  shouldForwardProp: (props) =>
    props !== '$isLargeTablet' && props !== '$isRadioHidden',
})<{
  $isLargeTablet: boolean
  $isRadioHidden: boolean
}>`
  display: ${({ $isRadioHidden }) => ($isRadioHidden ? 'none' : 'flex')};
  height: ${({ $isRadioHidden }) => ($isRadioHidden ? '0' : 'max-content')};
  z-index: 10;
  width: ${({ $isLargeTablet }) => ($isLargeTablet ? '208px' : '100%')};
  padding-bottom: 24px;
  gap: 8px;

  animation: ${HeaderFadeIn} 0.2s linear;

  label {
    background-color: ${({ theme }) => theme.neutral00};
    margin: 0 !important;
  }
`

const FollowUpDescription = styled.div`
  font: ${typography['body-large']};
  color: ${({ theme }) => theme.text2};
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`

const FollowUpContainer = styled.div`
  display: flex;
  padding: 8px;
  border-radius: 4px;
  max-width: 336px;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: ${({ theme }) => theme.neutral03};
`

const StyledAlert = styled(Alert)`
  background-color: ${({ theme }) => theme.neutral00};
  box-shadow: 0px 0px 30px 0px rgba(49, 98, 125, 0.1);
  z-index: 100;
`

const SyledText = styled(Text)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  a {
    padding-left: 4px;
  }
`

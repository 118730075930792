import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { Text } from '@ubnt/ui-components/aria'
import { Button } from '@ubnt/ui-components/Button'
import { CustomTaxId } from 'features/payment-methods/modules/types'
import { ButtonContainer, TaxData } from './Components.styles'
import { useDeleteTaxId } from 'store/mutations/billingTaxId/useDeleteTaxId'
import { setVisibleModal } from 'modules/modals'
import { TAX_ID_MODAL_ID } from 'features/payment-methods/ui/tax/TaxIdModal'
import { CountryFlag } from 'components/CountryFlag'

export const TaxId: React.FC<{ taxId: CustomTaxId }> = ({ taxId }) => {
  const dispatch = useDispatch()
  const { isDeleteTaxIdLoading, deleteTaxId } = useDeleteTaxId()

  return (
    <>
      <TaxData>
        {taxId.country && (
          <>
            <CountryFlag countryCode={taxId.country.toLowerCase()} noMargin />
            <Text variant="body-primary" color="text-2">
              {taxId.country}
            </Text>
          </>
        )}
        <Text variant="body-primary" color="text-2">
          {taxId.value}
        </Text>
      </TaxData>
      <ButtonContainer>
        <Button
          variant="inline"
          size="medium"
          onClick={() => dispatch(setVisibleModal(TAX_ID_MODAL_ID))}
        >
          <FormattedMessage id={'SETTINGS_PAYMENTS_TAX_ID_CHANGE'} />
        </Button>
        <Button
          variant="inline"
          size="medium"
          color="danger"
          onClick={() => deleteTaxId()}
          loader={isDeleteTaxIdLoading ? 'dots' : undefined}
        >
          <FormattedMessage id={'COMMON_ACTION_REMOVE'} />
        </Button>
      </ButtonContainer>
    </>
  )
}

import { postRequestWithCookies } from '../axios'

const {
  api: { ssoUi, sso },
} = __CONFIG__

type CreateSsoAccountArgs = any

export class SsoAccountApi {
  private getBaseUrl = (): string => {
    if (window.location.host.match(/\.ui\.com/)) {
      return ssoUi.base
    }
    return sso.base
  }

  create = async (data: CreateSsoAccountArgs) => {
    const url = new URL(sso.paths.createAccount, this.getBaseUrl())

    await postRequestWithCookies(url.href, data)
  }
}

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { Toggle } from '@ubnt/ui-components/Toggle'
import { Text } from '@ubnt/ui-components/aria'
import { Section, SectionHeader } from 'components/Section.styles'
import { ErrorPageFourOhFour } from 'core/error-handling/ErrorPages'
import { selectIsAuthenticated } from 'features/auth/modules/auth'
import { selectIsLoading } from 'features/auth/modules/resetPassword'
import { selectIsRolesFetched } from 'features/early-access/module/roles'
import { selectProfileIsFetched } from 'modules/profile'
import { ProtectedPage } from 'pages/ProtectedPage'
import { useUpdateChatAdminSchedule } from 'store/mutations/chat/useUpdateChatAdminSchedule'
import { useChatAdminScheduleQuery } from 'store/queries/useChatAdminSchedule'
import { FlexWrapper } from 'components/SharedComponents'

export const SupportAdmin = () => {
  const isProfileFetched = useSelector(selectProfileIsFetched)
  const areRolesFetched = useSelector(selectIsRolesFetched)
  const isAuthLoading = useSelector(selectIsLoading)
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const { isAdminFlagOn, isSupportAdmin } = useChatAdminScheduleQuery()
  const { updateChatAdminSchedule } = useUpdateChatAdminSchedule()

  if (
    (!isSupportAdmin && isProfileFetched && areRolesFetched) ||
    (!isAuthenticated && !isAuthLoading)
  ) {
    return <ErrorPageFourOhFour />
  }

  if (!isProfileFetched || !areRolesFetched || isAuthLoading) {
    return null
  }

  return (
    <ProtectedPage>
      <Section>
        <SectionHeader $marginBottom={16}>
          <FormattedMessage id="SUPPORT_ADMIN_HEADER" />
        </SectionHeader>
        <Toggle
          checked={isAdminFlagOn}
          onChange={() =>
            updateChatAdminSchedule({ isAdminFlagOn: !isAdminFlagOn })
          }
        >
          <FormattedMessage id="SUPPORT_ADMIN_HIDE_CHAT" />
        </Toggle>
        <Text variant="caption">
          <FormattedMessage id="SUPPORT_ADMIN_CHAT_DESCRIPTION" />
        </Text>
        <FlexWrapper marginTop={16}>
          <Text variant="body-secondary">
            <FormattedMessage id="SUPPORT_ADMIN_RECOMMENDATION" />
          </Text>
        </FlexWrapper>
      </Section>
    </ProtectedPage>
  )
}

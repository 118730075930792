import ExclamationMarkIcon from '@ubnt/icons/ExclamationMarkIcon'
import MoreVerticalIcon from '@ubnt/icons/MoreVerticalIcon'
import { Text } from '@ubnt/ui-components/aria'
import { SearchInput_Default } from '@ubnt/ui-components/aria/experimental'
import { Button } from '@ubnt/ui-components/Button'
import { media } from 'theme'
import styled from 'theme/styled'

const SubscriptionServiceGridRow = styled.div<{ $template: string }>`
  display: grid;
  grid-template-columns: ${({ $template }) => $template};
  border-bottom: ${({ theme }) => `1px solid ${theme.neutral03}`};

  &:last-of-type {
    border-bottom: none;
  }

  @media (max-width: ${media.xSmall}) {
    grid-template-columns: 1fr 100px 24px;

    .extra-data {
      display: none;
    }
  }
`

const SubscriptionServiceTableItem = styled(Text)`
  display: flex;
  align-items: center;
  gap: 4px;
  height: 28px;
  padding: 4px;
`

const TruncatedSubscriptionDeviceName = styled(Text)<{ $hasIcon?: boolean }>`
  display: grid;
  grid-template-columns: ${({ $hasIcon }) => ($hasIcon ? '20px 1fr' : '1fr')};
  gap: 4px;
  align-items: center;
  height: 28px;
  padding: 4px;
`

const TruncatedText = styled(Text)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  align-self: center;
`

const EmptySubscriptionServiceTableItem = SubscriptionServiceTableItem

const PopoverIcon = styled(MoreVerticalIcon)`
  color: ${({ theme }) => theme.neutral08};
  cursor: pointer;
`

const PopoverButton = styled(Button)`
  text-align: left;
  justify-content: flex-start;
  border-radius: 0;
`

const CardImg = styled.img`
  width: 20px;
  border-radius: 4px;
`

const WarningIcon = styled(ExclamationMarkIcon)`
  color: ${({ theme }) => theme.orange06};
`

const Search = styled(SearchInput_Default)`
  max-width: 248px;
  margin-bottom: 8px;
`

export {
  SubscriptionServiceGridRow,
  SubscriptionServiceTableItem,
  EmptySubscriptionServiceTableItem,
  PopoverIcon,
  PopoverButton,
  CardImg,
  WarningIcon,
  Search,
  TruncatedSubscriptionDeviceName,
  TruncatedText,
}

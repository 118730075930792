import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { MfaBanner } from './MfaBanner'

export const AddNewMethodBanner: React.FC = () => {
  const history = useHistory()

  const handleSetup = () => {
    history.push('/security?addmfa=true')
  }

  return (
    <MfaBanner
      message={<FormattedMessage id="MFA_BANNER_TEXT" />}
      onClick={handleSetup}
      buttonText="COMMON_ACTION_SETUP"
    />
  )
}

import React, { useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Alert } from '@ubnt/ui-components/Alert'
import { Text } from '@ubnt/ui-components/aria'
import { useFullSubscriptions } from 'features/subscriptions/fullSubscriptions/useFullSubscriptions'
import { ProductName } from 'types/enums'
import { Section } from 'components/Section.styles'
import { getSearchedSubscriptions, getSubscriptionColumns } from './utils'
import {
  EmptySubscriptionServiceTableItem,
  SubscriptionServiceGridRow,
  SubscriptionServiceTableItem,
} from './Components.styles'
import { SubscriptionServicesRow } from './SubscriptionServicesRow'
import { FlexWrapper } from 'components/SharedComponents'
import { SubscriptionServicesSearch } from './SubscriptionServicesSearch'
import { useDebouncedCallback } from 'use-debounce'
import { EmptySearchResults } from 'components/EmptySearchResults'

export const SubscriptionServicesList: React.FC<{
  group: ProductName
}> = ({ group }) => {
  const [searchValue, setSearchValue] = useState('')
  const { groupedSubscriptions } = useFullSubscriptions()

  const fullSubscriptions = groupedSubscriptions[group]

  const columnsTemplate = getSubscriptionColumns(group)

  const subscriptionsToDisplay = useMemo(() => {
    if (searchValue) {
      return getSearchedSubscriptions(fullSubscriptions, group, searchValue)
    }
    return fullSubscriptions
  }, [fullSubscriptions, group, searchValue])

  const onSearchChange = useDebouncedCallback((value: string) => {
    setSearchValue(value)
  }, 300)

  if (!columnsTemplate) {
    return null
  }

  const displayWarningBanner = subscriptionsToDisplay?.some(
    (subscription) => subscription.isFailed || subscription.isSuspended
  )

  const hasSearch =
    group !== ProductName.HOSTING &&
    group !== ProductName.UISP_CLOUD &&
    groupedSubscriptions[group]?.length > 1

  const renderContent = () => {
    if (!subscriptionsToDisplay?.length) {
      return <EmptySearchResults />
    }
    return subscriptionsToDisplay?.map((subscription) => (
      <SubscriptionServicesRow
        subscription={subscription}
        template={columnsTemplate.template}
        group={group}
        key={`service-body-${subscription.id}`}
      />
    ))
  }

  return (
    <FlexWrapper column gap={16}>
      {displayWarningBanner && (
        <Alert
          type="warning"
          text={
            <Text variant="body-primary" color="text-2">
              <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_WARNING_ALERT_TEXT" />
            </Text>
          }
        />
      )}
      <Section $omitMarginTop>
        {hasSearch && (
          <SubscriptionServicesSearch onSearchChange={onSearchChange} />
        )}
        <SubscriptionServiceGridRow $template={columnsTemplate.template}>
          {columnsTemplate.columns.map((id, i) => {
            if (id) {
              return (
                <SubscriptionServiceTableItem
                  variant="heading-small"
                  color="text-1"
                  key={`service-header-${i}`}
                  className={
                    i === 0 || i === columnsTemplate.columns.length - 2
                      ? ''
                      : 'extra-data'
                  }
                >
                  <FormattedMessage id={id} />
                </SubscriptionServiceTableItem>
              )
            }
            return (
              <EmptySubscriptionServiceTableItem key={`service-header-${i}`} />
            )
          })}
        </SubscriptionServiceGridRow>
        {renderContent()}
      </Section>
    </FlexWrapper>
  )
}

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { BillingCardsApi } from 'api/billing/billingCards'
import { closeVisibleModal } from 'modules/modals'
import { useToasts } from 'pages/payments/components/useToasts'
import { useDispatch } from 'react-redux'
import { QueryKey } from 'store/types'
import { BrowserUtils } from 'utils/browserUtils'
import { sendWebKitMessage } from 'utils/sendWebKitMessage'

const billingCardsApi = new BillingCardsApi()

const addPaymentViewQuery = BrowserUtils.getQueryValue(
  window.location.toString(),
  'addpaymentview'
)

export const useCreateCard = () => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const { paymentMethodSuccessToast } = useToasts()

  const createCard = useMutation({
    mutationFn: billingCardsApi.create,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.BILLING_CUSTOMER],
      })
      queryClient.invalidateQueries({
        queryKey: [QueryKey.BILLING_CARDS],
      })
      if (addPaymentViewQuery) {
        sendWebKitMessage('success')
      }
      paymentMethodSuccessToast('added')
      dispatch(closeVisibleModal())
    },
  })

  return {
    createCard: createCard.mutate,
    createCardError: createCard.error,
    isCreateCardLoading: createCard.isPending,
    resetCreateCardErrors: createCard.reset,
  }
}

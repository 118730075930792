import React, { useContext, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import EmptyResult from '@ubnt/ui-components/EmptyResult/EmptyResult'
import styled from 'theme/styled'
import { RequestsContext } from './RequestsContext'

export const EmptyResults: React.FC = () => {
  const { statusToShow, searchData } = useContext(RequestsContext)

  const titleId = useMemo<string>(() => {
    if (statusToShow === 'open') {
      return 'SUPPORT_MY_REQUESTS_EMPTY_OPEN'
    }
    if (statusToShow === 'closed') {
      return 'SUPPORT_MY_REQUESTS_EMPTY_CLOSED'
    }
    return 'SUPPORT_MY_REQUESTS_EMPTY_CCD'
  }, [statusToShow])

  if (searchData.error) {
    return (
      <Wrapper>
        <EmptyResult
          title={<FormattedMessage id="GENERIC_ERROR_SEARCH_EMPTY" />}
          description={
            <FormattedMessage id="GENERIC_ERROR_SEARCH_EMPTY_DESCRIPTION" />
          }
        />
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <EmptyResult title={<FormattedMessage id={titleId} />} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  min-height: 350px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  gap: 16px;
`

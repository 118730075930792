import { media } from 'theme'
import styled from 'theme/styled'

export const GridRow = styled.div<{ $noBorder?: boolean }>`
  padding: 6px 4px;
  display: grid;
  grid-template-columns: minmax(auto, 100px) 1fr 1fr 100px 80px 100px 32px;
  border-top: 1px solid
    ${({ theme, $noBorder }) => ($noBorder ? 'none' : theme.neutral03)};

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.neutral01};
  }

  @media (max-width: ${media.mobileXLarge}) {
    grid-template-columns: 1fr 100px 52px;

    .extra-data {
      display: none;
    }
  }
`

export const GridHeaderRow = styled(GridRow)`
  &:hover {
    background-color: ${({ theme }) => theme.neutral00};
  }
`

import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Text, type TextProps } from '@ubnt/ui-components/aria'
import { StripeStatus } from 'sharedConstants'
import styled from 'theme/styled'
import { MotifPaletteType } from 'theme/UICPalette'

type InvoicePillProps = {
  variant: StripeStatus
}

type Variables = {
  text: string
  color: TextProps['color']
  background: keyof MotifPaletteType
}

export const InvoicePill: React.FC<InvoicePillProps> = ({ variant }) => {
  const intl = useIntl()
  const { text, color, background }: Variables = useMemo(() => {
    switch (variant) {
      case StripeStatus.OPEN:
        return {
          text: intl.formatMessage({
            id: 'SETTINGS_SUBSCRIPTIONS_OVERDUE',
          }),
          color: 'red-06',
          background: 'red01',
        }
      case StripeStatus.PAID:
        return {
          text: intl.formatMessage({
            id: 'SETTINGS_SUBSCRIPTIONS_PAID',
          }),
          color: 'green-06',
          background: 'green01',
        }
      case StripeStatus.VOID:
        return {
          text: intl.formatMessage({
            id: 'SETTINGS_SUBSCRIPTIONS_VOIDED',
          }),
          color: 'text-3',
          background: 'neutral01',
        }
      case StripeStatus.DRAFT:
        return {
          text: intl.formatMessage({
            id: 'SETTINGS_SUBSCRIPTIONS_DRAFT',
          }),
          color: 'text-3',
          background: 'neutral01',
        }
      default:
        return {
          text: '',
          color: 'text-3',
          background: 'neutral01',
        }
    }
  }, [intl, variant])

  return (
    <Wrapper $background={background} className="extra-data">
      <Text variant="caption" color={color}>
        {text}
      </Text>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ $background: keyof MotifPaletteType }>`
  width: max-content;
  padding: 2px 8px;
  display: flex;
  align-items: center;
  border-radius: 16px;
  background-color: ${({ $background, theme }) => theme[$background]}};
`

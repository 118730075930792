import { FullSubscription } from 'features/subscriptions/fullSubscriptions/useFullSubscriptions'

export const hasPendingSubscriptions = (
  fullSubscriptions: FullSubscription[]
): boolean => {
  const activeSubscriptions = fullSubscriptions.filter(
    ({ isActive, cancelAtPeriodEnd }) => isActive && !cancelAtPeriodEnd
  )

  return activeSubscriptions.length > 0
}

export enum ProductName {
  MOBILITY = 'UniFi Mobile Routing',
  HOSTING = 'Official UniFi Hosting',
  UNIFI_HOSTING = 'unifi-hosting',
  ULTE = 'UniFi LTE',
  UNIFI_TALK = 'UniFi Talk',
  TALK = 'talk',
  SITE_SUPPORT = 'Site Support',
  MSP_SITE_SUPPORT = 'MSP Site Support',
  UISP_HOSTING = 'Official UISP Hosting',
  UISP_CLOUD = 'UISP Cloud Hosting',
  ENHANCED_THREAT_UPDATES = 'Enhanced Threat Updates',
  CYBERSECURE = 'CyberSecure',
  CYBERSECURE_ENTERPRISE = 'CyberSecure Enterprise',
  UNKNOWN = 'Unknown',
  UNIFI_ACCESS = 'UniFi Access',
}

export enum DomException {
  INVALID_STATE_ERROR = 'InvalidStateError',
  NOT_ALLOWED_ERROR = 'NotAllowedError',
  ABORT_ERROR = 'AbortError',
}

export enum SubscriptionUrl {
  MOBILITY = '/subscriptions/umr',
  HOSTING = '/subscriptions/unifi-hosting',
  ULTE = '/subscriptions/lte',
  UNIFI_TALK = '/subscriptions/talk',
  SITE_SUPPORT = '/subscriptions/site-support',
  MSP_SITE_SUPPORT = '/subscriptions/msp-site-support',
  UISP_CLOUD = '/subscriptions/uisp-hosting',
  CYBERSECURE = '/subscriptions/cybersecure',
  UNKNOWN = '/subscriptions',
}

import React from 'react'
import { FullSubscription } from 'features/subscriptions/fullSubscriptions/useFullSubscriptions'
import { SubscriptionServiceGridRow } from './Components.styles'
import { ProductName } from 'types/enums'
import { DeviceName } from './tableCells/DeviceName'
import { Quantity } from './tableCells/Quantity'
import { Plan } from './tableCells/Plan'
import { DeviceNumber } from './tableCells/DeviceNumber'
import { DataLimit } from './tableCells/DataLimit'
import { CommonColumns } from './CommonColumns'
import { Product } from './tableCells/Product'

interface Props {
  subscription: FullSubscription
  template: string
  group: string
}

export const SubscriptionServicesRow: React.FC<Props> = ({
  subscription,
  template,
  group,
}) => {
  const {
    metadata,
    name,
    deviceUmrInfo,
    id,
    quantity,
    items,
    plan,
    cardNotFound,
    isFailed,
  } = subscription

  const umrData = deviceUmrInfo?.find((umr) => umr.stripe_id === id)

  return {
    [ProductName.CYBERSECURE]: (
      <SubscriptionServiceGridRow $template={template}>
        <DeviceName
          siteName={metadata?.site_name}
          deviceName={metadata?.device_name}
          fallback={metadata?.device_shortname}
          cardNotFound={cardNotFound}
          isFailed={isFailed}
        />
        <CommonColumns group={group} subscription={subscription} />
      </SubscriptionServiceGridRow>
    ),
    [ProductName.HOSTING]: items.data.map((item) => (
      <SubscriptionServiceGridRow $template={template} key={item.id}>
        <DeviceNumber
          plan={items.data.length > 1 ? item.plan?.nickname : plan?.nickname}
          cardNotFound={cardNotFound}
          isFailed={isFailed}
        />
        <Quantity quantity={items.data.length > 1 ? item.quantity : quantity} />
        <CommonColumns group={group} subscription={subscription} />
      </SubscriptionServiceGridRow>
    )),
    [ProductName.UNIFI_TALK]: (
      <SubscriptionServiceGridRow $template={template}>
        <DeviceName
          deviceName={metadata?.ui_device_name}
          fallback={name}
          cardNotFound={cardNotFound}
          isFailed={isFailed}
        />
        <Quantity quantity={quantity} />
        <CommonColumns group={group} subscription={subscription} />
      </SubscriptionServiceGridRow>
    ),
    [ProductName.MSP_SITE_SUPPORT]: (
      <SubscriptionServiceGridRow $template={template}>
        <Product
          product="SETTINGS_SUBSCRIPTIONS_MSP_PRODUCT_NAME"
          cardNotFound={cardNotFound}
          isFailed={isFailed}
        />
        <CommonColumns group={group} subscription={subscription} />
      </SubscriptionServiceGridRow>
    ),
    [ProductName.SITE_SUPPORT]: (
      <SubscriptionServiceGridRow $template={template}>
        <DeviceName
          deviceName={metadata?.deviceName}
          fallback="-"
          cardNotFound={cardNotFound}
          isFailed={isFailed}
        />
        <DeviceName
          deviceName={metadata?.model}
          fallback="-"
          className="extra-data"
          isFailed={false}
          cardNotFound={false}
        />
        <CommonColumns group={group} subscription={subscription} />
      </SubscriptionServiceGridRow>
    ),
    [ProductName.ULTE]: (
      <SubscriptionServiceGridRow $template={template}>
        <DeviceName
          siteName={metadata?.site}
          deviceName={metadata?.controller}
          fallback={name}
          cardNotFound={cardNotFound}
          isFailed={isFailed}
        />
        <CommonColumns group={group} subscription={subscription} />
      </SubscriptionServiceGridRow>
    ),
    [ProductName.MOBILITY]: (
      <SubscriptionServiceGridRow $template={template}>
        <DeviceName
          deviceName={umrData?.device.name ?? metadata?.ui_device_name}
          fallback={name}
          location={umrData?.device.location}
          cardNotFound={cardNotFound}
          isFailed={isFailed}
        />
        <DataLimit plan={plan} />
        <CommonColumns group={group} subscription={subscription} />
      </SubscriptionServiceGridRow>
    ),
    [ProductName.UISP_CLOUD]: items.data.map((item) => (
      <SubscriptionServiceGridRow $template={template} key={item.id}>
        <Plan
          plan={items.data.length > 1 ? item.plan?.nickname : plan?.nickname}
          cardNotFound={cardNotFound}
          isFailed={isFailed}
        />
        <Quantity quantity={items.data.length > 1 ? item.quantity : quantity} />
        <CommonColumns group={group} subscription={subscription} />
      </SubscriptionServiceGridRow>
    )),
  }[group]
}

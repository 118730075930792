import React from 'react'
import {
  EmptySubscriptionsTableItem,
  SubscriptionsGridHeader,
  SubscriptionsTableItem,
} from './Components.styles'
import { FormattedMessage } from 'react-intl'
import { useFullSubscriptions } from 'features/subscriptions/fullSubscriptions/useFullSubscriptions'
import { SubscriptionGroupRow } from './SubscriptionGroupRow'

export const SubscriptionsTable = () => {
  const { subscriptionGroups } = useFullSubscriptions()

  return (
    <>
      <SubscriptionsGridHeader>
        <SubscriptionsTableItem variant="heading-medium" color="text-1">
          <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_SERVICE" />
        </SubscriptionsTableItem>
        <EmptySubscriptionsTableItem />
      </SubscriptionsGridHeader>
      {subscriptionGroups.map((group, i) => (
        <SubscriptionGroupRow group={group} key={`subscription-group-${i}`} />
      ))}
    </>
  )
}

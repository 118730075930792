import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Accordion } from '@ubnt/ui-components/Accordion'
import { Text } from '@ubnt/ui-components/aria'
import styled from 'theme/styled'
import { AccordionLabel } from './AccordionLabel'
import { InvoicesTableHeader } from '../InvoicesTable/InvoicesTableHeader'
import { InvoicesTableRow } from '../InvoicesTable/InvoiceTableRow'
import { useBillingProductsQuery } from 'store/queries/useBillingProductsQuery'
import { useNcaDevicesQuery } from 'store/queries/useNcaDevicesQuery'
import { parseTableInvoice } from 'pages/subscriptions/hooks/utils'
import { useBillingOpenInvoicesQuery } from 'store/queries/useBillingOpenInvoicesQuery'

export const UnpaidInvoicesAccordion = () => {
  const { openInvoices } = useBillingOpenInvoicesQuery()
  const { getProduct } = useBillingProductsQuery()
  const { selectOwnedUcoreDevices } = useNcaDevicesQuery()

  if (!openInvoices.length) {
    return null
  }

  const items = [
    {
      id: 'unpaid-invoices',
      label: <AccordionLabel />,
      renderContent: () => (
        <Wrapper>
          <InvoicesTableHeader />
          {openInvoices.map((invoice) => {
            const parsedInvoice = parseTableInvoice(
              invoice,
              getProduct,
              selectOwnedUcoreDevices
            )

            return (
              <InvoicesTableRow
                key={parsedInvoice.id}
                {...parsedInvoice}
                invoice={invoice}
              />
            )
          })}
        </Wrapper>
      ),
    },
  ]

  return (
    <StyledAccordion
      contentContainerClassName="contentCard"
      items={items}
      arrowDownBefore={
        <TotalOpenInvoices variant="caption">
          <FormattedMessage
            id="SETTINGS_INVOICES_LABEL_WITH_PREFIX"
            values={{ amount: openInvoices.length }}
          />
        </TotalOpenInvoices>
      }
    />
  )
}

const StyledAccordion = styled(Accordion)`
  margin-bottom: 16px;
  border: 1px solid ${({ theme }) => theme.neutral03};
  border-radius: 6px;

  li > div:first-child {
    background-color: ${({ theme }) => theme.neutral00};
  }

  &:active {
    box-shadow: none;
  }

  .contentCard {
    > div {
      padding: 16px 16px 0;
    }
  }
`

const Wrapper = styled.div`
  display: gird;
`

const TotalOpenInvoices = styled(Text)`
  margin-right: 4px;
`

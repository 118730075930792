import { spawn } from 'redux-saga/effects'
import { api } from 'api'
import { createSelector } from 'reselect'

import createDataModule from 'utils/moduleCreator'
import { AddressResponse, FullAddressData } from 'types/types'
import { selectProfileDefaultAddress } from 'modules/profile'

const {
  api: { sso },
} = __CONFIG__

export const addressesDataKey = 'addresses'

const dataModule = createDataModule<AddressResponse>(
  addressesDataKey,
  sso.paths.addresses,
  api.ssoBase
)

export const {
  fetch: fetchAddresses,
  selectIsLoading: selectFetchAddressIsLoading,
  reducer: addressesReducer,
} = dataModule

export const selectDefaultAddress = createSelector(
  dataModule.selectData,
  selectProfileDefaultAddress,
  ({ addresses }, defaultAddress) => {
    if (!defaultAddress) return null
    if (!addresses) return null
    return (
      addresses.find(
        (address: FullAddressData) => address.id === defaultAddress
      ) || null
    )
  }
)

export function* addressesRootSaga() {
  yield spawn(dataModule.rootSaga)
}

import { useState } from 'react'
import { useIntl } from 'react-intl'
import { api } from 'api'
import { useDebouncedCallback } from 'use-debounce'
import Yup from 'validators/yupLocaleConfig'

export const useVerifyEmail = () => {
  const intl = useIntl()
  const [verifyEmailError, setVerifyEmailError] = useState<string | null>(null)
  const [isVerifyEmailLoading, setIsVerifyEmailLoading] =
    useState<boolean>(false)

  const verifyEmail = useDebouncedCallback(async (email: string) => {
    const validator = Yup.string().email()

    if (!validator.isValidSync(email)) return

    setIsVerifyEmailLoading(true)
    try {
      const encodedEmail = encodeURIComponent(email)

      await api.checkEmail(encodedEmail)
      setVerifyEmailError(
        intl.formatMessage({
          id: 'COMMON_AUTH_REGISTER_EMAIL_TAKEN',
        })
      )
    } catch (e) {
      setVerifyEmailError(null)
    }
    setIsVerifyEmailLoading(false)
  }, 200)

  return { verifyEmail, isVerifyEmailLoading, verifyEmailError }
}

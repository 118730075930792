import type Stripe from 'stripe'
import { AxiosRequestConfig } from 'axios'
import { last } from 'lodash-es'
import { DateTime } from 'luxon'
import { fetchRequestWithCookies, postRequestWithCookies } from 'api/axios'
import { STRIPE_FETCH_LIMIT } from 'api/constants'
import { StripeRegionCode } from 'features/stripe/ui/types'
import {
  InvoiceData,
  InvoiceLinesData,
  UpcomingInvoice,
} from 'features/subscriptions/module/types'
import { mergeStripeData } from './billingUtils'
import { StripeStatus } from 'sharedConstants'

const {
  api: { billing },
} = __CONFIG__

type PayInvoiceData = {
  id: string
  region: StripeRegionCode
  source?: string | Stripe.CustomerSource | null
  payment_method?: string | Stripe.PaymentMethod
}

type GetInvoiceLinesArgs = {
  id: string
  region: StripeRegionCode
  startingAfter?: string
}

export type PageParams = {
  startingAfter: string | null
  region: StripeRegionCode | null
}[]

const STRIPE_INVOICES_DEFAULTS = {
  data: [],
  object: 'list' as const,
  has_more: false,
  url: '',
}

type InvoiceYears = {
  [Property in StripeRegionCode]: {
    customerId: string
    availableYears: number[]
  }
}

export class BillingInvoicesApi {
  private extraPagesResponseDefaults: InvoiceData = {
    us: STRIPE_INVOICES_DEFAULTS,
    ca: STRIPE_INVOICES_DEFAULTS,
    gb: STRIPE_INVOICES_DEFAULTS,
    eu: STRIPE_INVOICES_DEFAULTS,
    au: STRIPE_INVOICES_DEFAULTS,
    ae: STRIPE_INVOICES_DEFAULTS,
    sg: STRIPE_INVOICES_DEFAULTS,
    row: STRIPE_INVOICES_DEFAULTS,
    jp: STRIPE_INVOICES_DEFAULTS,
    mx: STRIPE_INVOICES_DEFAULTS,
    br: STRIPE_INVOICES_DEFAULTS,
  }

  private fetchExtraPages = async (
    pageParam: PageParams,
    url: URL,
    params: AxiosRequestConfig['params']
  ) => {
    let response = this.extraPagesResponseDefaults
    for (let i = 0; i < pageParam.length; i++) {
      const { startingAfter, region } = pageParam[i]
      if (startingAfter && region) {
        url.searchParams.set('starting_after', startingAfter)
        url.searchParams.set('region', region)

        const { data } = await fetchRequestWithCookies<InvoiceData>(url.href, {
          params,
        })
        response = { ...response, ...data }
      }
    }

    return response
  }

  private createParamsFromYear = (year: string) => {
    const parsedYear = parseInt(year)

    const startOfYearEpox = Math.floor(
      DateTime.utc(parsedYear, 1, 1).startOf('day').toSeconds()
    ).toString()
    const endOfYearEpox = Math.floor(
      DateTime.utc(parsedYear, 12, 31).endOf('day').toSeconds()
    ).toString()

    return {
      gte: startOfYearEpox,
      lte: endOfYearEpox,
    }
  }

  public getPaginated = async ({
    pageParam = [{ startingAfter: null, region: null }],
    queryKey,
  }: {
    pageParam: PageParams
    queryKey: string[]
  }) => {
    const year = queryKey[1]

    const params = { created: this.createParamsFromYear(year) }

    const url = new URL(billing.paths.invoices, billing.base)
    url.searchParams.append('limit', STRIPE_FETCH_LIMIT)
    url.searchParams.append('expand[]', 'data.charge')

    if (!pageParam[0].region || !pageParam[0].startingAfter) {
      const { data } = await fetchRequestWithCookies<InvoiceData>(url.href, {
        params,
      })
      return data
    }

    return await this.fetchExtraPages(pageParam, url, params)
  }

  public getUpcoming = async (id: string, region: StripeRegionCode) => {
    const url = new URL(billing.paths.invoicesUpcoming, billing.base)
    url.searchParams.append('subscriptionId', id)
    url.searchParams.append('region', region)

    const { data } = await fetchRequestWithCookies<UpcomingInvoice>(url.href)
    return data
  }

  public updateOne = async ({
    id,
    region,
    source,
    payment_method,
  }: PayInvoiceData) => {
    const url = new URL(
      billing.paths.invoicePay.replace('{invoiceId}', id),
      billing.base
    )
    url.searchParams.append('region', region)

    const payload = payment_method ? { payment_method } : { source }

    await postRequestWithCookies(url.href, payload)
  }

  private getInvoiceLines = async ({
    id,
    region,
    startingAfter,
  }: GetInvoiceLinesArgs) => {
    const url = new URL(
      billing.paths.invoiceLines.replace('{invoiceId}', id),
      billing.base
    )
    url.searchParams.append('region', region)
    url.searchParams.append('limit', STRIPE_FETCH_LIMIT)
    startingAfter && url.searchParams.append('starting_after', startingAfter)

    const { data } = await fetchRequestWithCookies<InvoiceLinesData>(url.href)
    return data
  }

  public getAllInvoiceLines = async ({ id, region }: GetInvoiceLinesArgs) => {
    let i = 0
    let invoiceLines = await this.getInvoiceLines({ id, region })

    while (invoiceLines[region].has_more && i < 10) {
      i++
      const lastFetchedLineId = last(invoiceLines[region].data)?.id

      const extraLines = await this.getInvoiceLines({
        id,
        region,
        startingAfter: lastFetchedLineId,
      })

      invoiceLines = mergeStripeData<Stripe.InvoiceLineItem>(
        invoiceLines,
        extraLines,
        region
      )
    }

    return invoiceLines[region].data
  }

  public getInvoiceYears = async () => {
    const url = new URL(billing.paths.invoiceYears, billing.base)

    const { data } = await fetchRequestWithCookies<InvoiceYears>(url.href)

    return data
  }

  public getOpenInvoices = async () => {
    const url = new URL(billing.paths.invoices, billing.base)

    url.searchParams.append('status', StripeStatus.OPEN)
    url.searchParams.append('limit', STRIPE_FETCH_LIMIT)
    url.searchParams.append('expand[]', 'data.charge')

    const { data } = await fetchRequestWithCookies<InvoiceData>(url.href)

    return data
  }
}

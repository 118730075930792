import React from 'react'
import { Search } from './Components.styles'

interface Props {
  onSearchChange: (value: string) => void
}

export const SubscriptionServicesSearch: React.FC<Props> = ({
  onSearchChange,
}) => {
  return (
    <Search
      placeholder="Search"
      onChange={onSearchChange}
      height="medium"
      aria-label="subscriptions_search-field"
      onClear={() => onSearchChange('')}
    />
  )
}

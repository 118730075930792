import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'theme/styled'
import { selectIsAuthenticated } from 'features/auth/modules/auth'
import ProfilePictureModal from 'features/profile-picture/ui/ProfilePictureModal'
import { MaintenanceInfo } from 'features/status/ui'
import { useAppView } from 'utils/useAppView'
import { SidebarLayoutProvider } from 'components/sidebarLayoutProvider/SidebarLayoutProvider'
import { Page } from 'components/Page'
import { MediaSizingContext } from 'components/mediaSizing/MediaSizingContext'
import MFAToasts from './MFAToasts'
import { Toasts } from './Toasts'
import GlobalNav from './GlobalNav'
import { RequiresAuth } from './RequiresAuth'

interface Props {
  renderMargin?: boolean
  renderFooter?(): React.ReactNode
  hideUserSection?: boolean
  children: React.ReactNode
}

export const ProtectedPage: React.FC<Props> = ({
  renderFooter,
  children,
  hideUserSection,
}) => {
  const isAuthenticated = useSelector(selectIsAuthenticated)

  const [mobileNavOpen, setMobileNavOpen] = useState<boolean>(false)

  const [isAppView] = useAppView()

  const { isMaxMediumV2 } = useContext(MediaSizingContext)

  useEffect(() => {
    if (!isMaxMediumV2) setMobileNavOpen(false)
  }, [isMaxMediumV2])

  return (
    <RequiresAuth>
      <>
        {Toasts()}
        <MFAToasts />
        {!isAppView && (
          <StyledGlobalNav
            isAuthenticated={isAuthenticated}
            key="global-nav"
            setMobileNavOpen={setMobileNavOpen}
            mobileNavOpen={mobileNavOpen}
          />
        )}
        {!hideUserSection ? (
          <SidebarLayoutProvider>
            <MaintenanceInfo />
            <ProfilePictureModal />
            <Page renderFooter={renderFooter}>{children}</Page>
          </SidebarLayoutProvider>
        ) : (
          <SupportWrapper $showNavBar={isAuthenticated && !isAppView}>
            {children}
          </SupportWrapper>
        )}
      </>
    </RequiresAuth>
  )
}

const StyledGlobalNav = styled(GlobalNav)`
  position: fixed !important;
`

const SupportWrapper = styled.div<{ $showNavBar?: boolean }>`
  background: ${({ theme }) => theme.neutral01};
  width: 100%;
  min-height: 100%;

  ${({ $showNavBar }) =>
    $showNavBar &&
    `
    position: relative;
    padding-top: 50px;
  `}
`

import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import Button from '@ubnt/ui-components/Button/Button'
import { CardType } from 'features/payment-methods/modules/types'
import { ALL_KEY } from 'sharedConstants'
import { useBillingCardsQuery } from 'store/queries/useBillingCardsQuery'
import { useBillingCustomerQuery } from 'store/queries/useBillingCustomerQuery'
import { CardListContainer } from './Components.styles'
import { CardTable } from './CardTable'

interface Props {
  handleAddPayment: () => void
  filter: string
}

const pushDefaultsFirst = (a: CardType, b: CardType) =>
  !a.is_default && b.is_default ? 1 : -1

export const CardList: React.FC<Props> = ({ handleAddPayment, filter }) => {
  const { customerDefaultCards } = useBillingCustomerQuery()
  const { customerCards } = useBillingCardsQuery()

  const cardsToDisplay = useMemo(() => {
    const extendedCards = customerCards.map((card) => {
      const isDefault =
        customerDefaultCards &&
        Object.values(customerDefaultCards).some((id) => id === card.id)
      return { ...card, is_default: isDefault }
    })

    if (filter === ALL_KEY) return extendedCards.sort(pushDefaultsFirst)

    return extendedCards
      .filter(
        (card) =>
          card.billing_details.address?.country?.toLowerCase() === filter
      )
      .sort(pushDefaultsFirst)
  }, [customerCards, customerDefaultCards, filter])

  return (
    <CardListContainer>
      <CardTable cards={cardsToDisplay} />
      <Button variant="link" onClick={handleAddPayment}>
        <FormattedMessage id="SETTINGS_PAYMENTS_MODAL_TITLE_ADD" />
      </Button>
    </CardListContainer>
  )
}

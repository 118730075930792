import React from 'react'
import { Text } from '@ubnt/ui-components/aria'
import { UidbImage_Console } from '@ubnt/ui-components/aria/UidbImage'
import styled from 'theme/styled'
import { DEFAULT_UIDB_IMG } from './types'
import { isActiveDevice, MixedDeviceBackups } from '../types'
import { getDeviceTypeLabel, getMacLabel } from '../utils'
import { media } from 'theme'

type DeviceViewProps = {
  device: MixedDeviceBackups | null
}

export const DeviceView: React.FC<DeviceViewProps> = ({ device }) => {
  const mac = getMacLabel(device)

  const deviceType = getDeviceTypeLabel(device)

  const shortname =
    device && isActiveDevice(device)
      ? device?.shadow?.state?.reported?.hardware?.shortname
      : DEFAULT_UIDB_IMG

  return (
    <Wrapper>
      <UidbImage_Console shortname={shortname} size="40px" />
      <DeviceViewInfo>
        <Text variant="heading-medium">{deviceType}</Text>
        <Text variant="body-secondary">{mac}</Text>
      </DeviceViewInfo>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 16px;
  display: flex;
  gap: 8px;
  background-color: ${({ theme }) => theme.neutral00};
  border-radius: 4px;

  @media (max-width: ${media.mobileLarge}) {
    margin-left: 8px;
  }
`

const DeviceViewInfo = styled.div`
  display: flex;
  flex-direction: column;
`

import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { Tab, Tabs } from '@ubnt/ui-components/Tabs'
import { EmptyResult } from '@ubnt/ui-components/EmptyResult'
import { ProtectedPage } from 'pages/ProtectedPage'
import AppViewHeader from 'components/AppViewHeader'
import { BrowserUtils } from 'utils/browserUtils'
import { useBillingInvoicesQuery } from 'store/queries/useBillingInvoicesQuery'
import { useBillingSubscriptionsQuery } from 'store/queries/useBillingSubscriptionsQuery'
import { InvoicesList } from './components/InvoicesList'
import { SubscriptionsList } from './components/subscriptions/SubscriptionsList'
import { SkeletonLoaders } from './components/subscriptions/SkeletonLoaders'
import WrappedInvoiceModal from 'pages/subscriptions/components/invoices/InvoiceModal'
import { useBillingUpcomingInvoicesQuery } from 'store/queries/useBillingUpcomingInvoicesQuery'
import { HeaderContainer } from './components/subscriptions/Components.styles'
import { useInvoices } from './hooks/useInvoices'
import { useBillingInvoiceYearsQuery } from 'store/queries/useBillingInvoiceYearsQuery'
import { useBillingProductsQuery } from 'store/queries/useBillingProductsQuery'

enum ViewType {
  SUBSCRIPTIONS = 'subscriptions',
  INVOICES = 'invoices',
}

type ViewState = {
  displayTabs: boolean
  viewType: ViewType
}

export const Subscriptions = () => {
  const {
    userSubscriptions,
    areSubscriptionsLoading,
    areSubscriptionsFetched,
  } = useBillingSubscriptionsQuery()
  const { areInvoicesLoading, areInvoicesFetched } = useBillingInvoicesQuery()
  const { invoiceYears, areInvoiceYearsFetched } = useBillingInvoiceYearsQuery()
  const { areBillingProductsLoading } = useBillingProductsQuery()
  const { allInvoices } = useInvoices()
  const history = useHistory()

  const { areUpcomingInvoicesLoading } = useBillingUpcomingInvoicesQuery()

  const getInitialView = (): ViewType => {
    const viewType =
      BrowserUtils.getQueryValue(window.location.href, 'type') ===
      ViewType.INVOICES
        ? ViewType.INVOICES
        : ViewType.SUBSCRIPTIONS

    // clear the query params
    history.replace(location.pathname)

    return viewType
  }

  const [{ displayTabs, viewType }, setView] = useState<ViewState>({
    displayTabs: true,
    viewType: getInitialView(),
  })

  const hasSubscriptions =
    areSubscriptionsFetched && userSubscriptions.length > 0

  const hasInvoices =
    areInvoiceYearsFetched &&
    !!invoiceYears.length &&
    areInvoicesFetched &&
    !!allInvoices.length

  const displayInvoices = viewType === ViewType.INVOICES && hasInvoices
  const displaySubscriptions =
    viewType === ViewType.SUBSCRIPTIONS && hasSubscriptions

  const tabs = [
    {
      id: ViewType.SUBSCRIPTIONS,
      label: <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_HEADER" />,
    },
    {
      id: ViewType.INVOICES,
      label: <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_HEADER_ARCHIVE" />,
    },
  ]

  const onChangeView = (tab: Tab) => {
    if (tab.id !== viewType) {
      setView((prev) => ({ ...prev, viewType: tab.id as ViewType }))
    }
  }

  const onUpdateDisplayTabs = (displayTabs: boolean) => {
    return setView((prev) => ({ ...prev, displayTabs }))
  }

  const areUserInvoicesLoading =
    areInvoicesLoading ||
    (!areInvoicesFetched && !!invoiceYears.length) ||
    !areInvoiceYearsFetched ||
    areBillingProductsLoading

  const areUserSubscriptionsLoading =
    areSubscriptionsLoading ||
    !areSubscriptionsFetched ||
    areUpcomingInvoicesLoading ||
    areBillingProductsLoading

  const renderContent = () => {
    if (
      (viewType === ViewType.INVOICES && areUserInvoicesLoading) ||
      (viewType === ViewType.SUBSCRIPTIONS && areUserSubscriptionsLoading)
    ) {
      return <SkeletonLoaders />
    }

    if (displayInvoices) {
      return <InvoicesList onUpdateDisplayTabs={onUpdateDisplayTabs} />
    }

    if (displaySubscriptions) {
      return <SubscriptionsList />
    }

    return (
      <EmptyResult
        title={
          <FormattedMessage
            id={
              viewType === ViewType.SUBSCRIPTIONS
                ? 'SETTINGS_SUBSCRIPTIONS_EMPTY_TITLE'
                : 'SETTINGS_INVOICES_EMPTY_TITLE'
            }
          />
        }
        description={
          <FormattedMessage
            id={
              viewType === ViewType.SUBSCRIPTIONS
                ? 'SETTINGS_SUBSCRIPTIONS_EMPTY_MESSAGE'
                : 'SETTINGS_INVOICES_EMPTY_MESSAGE'
            }
          />
        }
        size="large"
      />
    )
  }

  return (
    <ProtectedPage renderMargin={true}>
      <HeaderContainer>
        <AppViewHeader omitMarginBottom />
        {displayTabs && (
          <Tabs
            tabs={tabs}
            variant="capsule"
            selectedTab={viewType}
            onSelectTab={onChangeView}
          />
        )}
      </HeaderContainer>
      {renderContent()}
      <WrappedInvoiceModal />
    </ProtectedPage>
  )
}

import React from 'react'
import { type Tab, Tabs } from '@ubnt/ui-components'
import styled from 'theme/styled'
import { TabState } from './types'
import { useIntl } from 'react-intl'

type BackupsTabsProps = {
  onClick: (tab: Tab) => void
}

export const BackupsTabs: React.FC<BackupsTabsProps> = ({ onClick }) => {
  const intl = useIntl()
  return (
    <StyledTabs
      onSelectTab={onClick}
      variant="capsule"
      tabs={[
        {
          id: TabState.ACTIVE,
          label: intl.formatMessage({ id: 'SETTINGS_BACKUPS_TABS_ACTIVE' }),
        },
        {
          id: TabState.ARCHIVED,
          label: intl.formatMessage({ id: 'SETTINGS_BACKUPS_TABS_ARCHIVED' }),
        },
      ]}
    />
  )
}

const StyledTabs = styled(Tabs)`
  width: max-content;
  padding-bottom: 16px;
`

import { useQuery } from '@tanstack/react-query'
import { QueryKey } from 'store/types'

export const usePasskeyAvailableQuery = () => {
  const hasPublicCredential =
    !!window.PublicKeyCredential?.isUserVerifyingPlatformAuthenticatorAvailable

  const passkey = useQuery({
    queryKey: [QueryKey.PASSKEY_AVAILABLE],
    queryFn:
      window.PublicKeyCredential?.isUserVerifyingPlatformAuthenticatorAvailable,
    enabled: !!hasPublicCredential,
    staleTime: Infinity,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  })

  return { isPasskeyAvailable: passkey.data }
}

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { BillingCardsApi } from 'api/billing/billingCards'
import { QueryKey } from 'store/types'
import { closeVisibleModal } from 'modules/modals'
import { useToasts } from 'pages/payments/components/useToasts'

const billingCardsApi = new BillingCardsApi()

export const useDeleteCard = () => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const { paymentMethodSuccessToast } = useToasts()

  const deleteCard = useMutation({
    mutationFn: billingCardsApi.delete,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.BILLING_CARDS],
      })
      queryClient.invalidateQueries({
        queryKey: [QueryKey.BILLING_CUSTOMER],
      })
      paymentMethodSuccessToast('removed')
      dispatch(closeVisibleModal())
    },
  })

  return {
    deleteCard: deleteCard.mutate,
    isDeleteCardLoading: deleteCard.isPending,
    deleteCardError: deleteCard.error,
    resetDeleteCardError: deleteCard.reset,
  }
}

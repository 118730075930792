import { css } from '@emotion/react'
import styled from 'theme/styled'

interface HamburgerProps {
  active?: boolean
  onClick?(): void
}

const hamburgerBar = css`
  width: 23px;
  height: 1px;
  border-radius: 10px;
`

const transition = '0.2s cubic-bezier(0.23, 1, 0.32, 1)'

export const HamburgerWrapper = styled('div')`
  flex: 1;
  padding: 10px 0;
  margin-right: 18px;
`

export const Hamburger = styled.div<HamburgerProps>`
  position: relative;
  background: ${({ theme }) => theme.neutral08};
  transition: all 0ms 0.2s;

  ${hamburgerBar}

  &:before {
    content: '';
    position: absolute;
    bottom: 8px;
    left: 0;
    background: ${({ theme }) => theme.neutral08};
    transition:
      bottom 0.2s ${transition},
      transform ${transition};

    ${hamburgerBar}
  }

  &:after {
    content: '';
    position: absolute;
    top: 8px;
    left: 0;
    background: ${({ theme }) => theme.neutral08};
    transition:
      top 0.2s ${transition},
      transform ${transition};

    ${hamburgerBar}
  }

  ${({ active, theme }) =>
    active &&
    css`
      background: ${theme.neutral03};

      &:before {
        bottom: 0;
        transform: rotate(-45deg);
        transition:
          bottom ${transition},
          transform 0.2s ${transition};
      }

      &:after {
        top: 0;
        transform: rotate(45deg);
        transition:
          top ${transition},
          transform 0.2s ${transition};
      }
    `}
`

import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import parse, { HTMLReactParserOptions } from 'html-react-parser'
import { useMotif } from '@ubnt/ui-components'
import { Link } from '@ubnt/ui-components/aria/experimental'
import { AttachmentIcon } from '@ubnt/icons'

import { LinkWrapper, Message, MessageAuthor, MessageWrapper } from './styles'
import { FullScaleImage } from './FullScaleImage'
import { ZendeskCommentAttachment } from 'api/zendesk/types'

const CommentMessage: FC<{
  isUserComment: boolean
  isTicketOpen: boolean
  showAuthorName: boolean
  attachmentUrl?: string | null
  author_name: string
  html_body: string
  attachments: ZendeskCommentAttachment[]
}> = ({
  isUserComment,
  isTicketOpen,
  showAuthorName,
  attachmentUrl,
  author_name,
  html_body,
  attachments,
}) => {
  const { motif } = useMotif()
  const isDarkTheme = motif === 'dark'

  // Need to parse the html that comes from the BE, to inject modal functionality
  const options: HTMLReactParserOptions = {
    transform(domNode) {
      const parent = domNode as JSX.Element

      if (
        !parent.props?.children ||
        (!Array.isArray(parent.props?.children) &&
          parent.props?.children.type !== 'img')
      ) {
        return domNode as JSX.Element
      }

      if (!Array.isArray(parent.props?.children)) {
        return <FullScaleImage src={parent.props?.children?.props?.src} />
      }

      const parsedChildren = parent.props.children.map((child: JSX.Element) => {
        if (child.type === 'img') {
          return <FullScaleImage src={child.props.src}>{child}</FullScaleImage>
        }
        return child
      })

      const parsedNode = {
        ...parent,
        props: { ...parent.props, children: parsedChildren },
      }
      return parsedNode as JSX.Element
    },
  }

  return (
    <MessageWrapper
      $isDarkTheme={isDarkTheme}
      $isUserComment={isUserComment}
      $isTicketOpen={isTicketOpen}
    >
      {showAuthorName && <MessageAuthor>{author_name}</MessageAuthor>}
      <Message $haslink={!!attachmentUrl}>{parse(html_body, options)}</Message>
      {attachmentUrl && (
        <LinkWrapper>
          <AttachmentIcon size={20} variant="twoTone" isActive />
          <Link href={attachmentUrl} target="_blank">
            <FormattedMessage id="REQUEST_MESSAGE_SEE_ATTACHMENT" />
          </Link>
        </LinkWrapper>
      )}
      {attachments.map(({ content_url, file_name }, i) => (
        <LinkWrapper key={`attachment-${i}`}>
          <AttachmentIcon size={20} variant="twoTone" isActive />
          <Link href={content_url} target="_blank">
            {file_name}
          </Link>
        </LinkWrapper>
      ))}
    </MessageWrapper>
  )
}

export default CommentMessage

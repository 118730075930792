import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Dropdown } from '@ubnt/ui-components/Dropdown'

import { NewTaxIdSate, RegionDropdownOption } from '../types'
import { countryDropdownOptions } from './utils'

type Props = {
  value: string
  onChange: (value: NewTaxIdSate) => void
}

const TaxIdDropdown: React.FC<Props> = ({ value, onChange }) => {
  const handleChange = (option: RegionDropdownOption) => {
    const newTaxIdState = {
      dropdownValue: option.value,
      country: option.country,
      type: option.type,
      placeholder: option.taxPlaceholder,
      region: option.region,
    }
    onChange(newTaxIdState)
  }

  return (
    <Dropdown<RegionDropdownOption>
      searchable
      variant="secondary"
      label={<FormattedMessage id="COMMON_LABEL_COUNTRY" />}
      options={countryDropdownOptions}
      onChange={handleChange}
      value={value}
      width="auto"
    />
  )
}

export default TaxIdDropdown

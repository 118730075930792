import { MFAAuthenticatorStatus } from 'enums'
import { selectMFAAuthenticators } from 'modules/mfa'
import { MFAType } from 'modules/types'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useMarkPasskeyPromptAsSeen } from 'store/mutations/account/useMarkPasskeyPromptAsSeen'
import { usePasskeyAvailableQuery } from 'store/queries/usePasskeyAvailableQuery'
import { useShouldDisplayPasskeyBannerQuery } from 'store/queries/useShouldDisplayPasskeyBannerQuery'

export const useShowMfaBanner = () => {
  const [displayPasskeyBanner, setDisplayPasskeyBanner] = useState(false)
  const { isPasskeyAvailable } = usePasskeyAvailableQuery()
  const { shouldDisplayBanner } = useShouldDisplayPasskeyBannerQuery()
  const { markPasskeyPromptAsSeen } = useMarkPasskeyPromptAsSeen()
  const mfaMethods = useSelector(selectMFAAuthenticators)

  const activeMfaMethods = useMemo(
    () =>
      mfaMethods?.filter(
        (method) => method.status === MFAAuthenticatorStatus.ACTIVE
      ),
    [mfaMethods]
  )

  const onlyHasOneEmailMfa =
    activeMfaMethods.length === 1 && activeMfaMethods[0].type === MFAType.email

  useEffect(() => {
    if (shouldDisplayBanner && isPasskeyAvailable && !onlyHasOneEmailMfa) {
      setDisplayPasskeyBanner(true)
    }
  }, [shouldDisplayBanner, isPasskeyAvailable, onlyHasOneEmailMfa])

  const handleCloseBanner = () => {
    markPasskeyPromptAsSeen()
    setDisplayPasskeyBanner(false)
  }

  return { displayPasskeyBanner, handleCloseBanner, onlyHasOneEmailMfa }
}

import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import axios from 'axios'
import styled from '@emotion/styled'
import { useFormik } from 'formik'
import { api } from 'api'
import { ErrorMessage } from 'api/api'

import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import { selectProfileData } from 'modules/profile'
import { GenericModal } from 'components/generic-modal/GenericModal'
import { typography } from 'theme'
import Yup from 'validators/yupLocaleConfig'
import { ExternalLink } from 'utils/ExternalLink'
import { ModalForm } from 'components/ModalForm'
import { Input } from 'components/Input'

import { useToasts } from './useToasts'
import { makePasswordHash, parseFileName } from './utils'
import { BackupItem } from 'api/nca/types'

interface Props extends ModalProps {
  item?: BackupItem
}

interface FormValues {
  password: string
}

const SubmitPasswordModal: React.FC<Props> = ({ isOpen, onClose, item }) => {
  const intl = useIntl()
  const profile = useSelector(selectProfileData)
  const { backupDownloadSuccessToast } = useToasts()

  const [isDownloading, setIsDownloading] = useState(false)
  const [downloadError, setDownloadError] = useState<JSX.Element | undefined>(
    undefined
  )

  const downloadFile = (fileAsBlob: Blob, fileName: string) => {
    const file = window.URL.createObjectURL(fileAsBlob)
    const link = document.createElement('a')
    link.href = file
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    link.parentNode?.removeChild(link)
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      password: '',
    },
    enableReinitialize: true,
    onSubmit: ({ password }: FormValues) =>
      item && onDownloadBackup(item, password),
    validationSchema: Yup.object().shape({
      password: Yup.string().required().label('COMMON_SSO_LABEL_PASSWORD'),
    }),
  })

  const onDownloadBackup = async (item: BackupItem, password: string) => {
    if (!profile?.uuid) return

    try {
      setDownloadError?.(undefined)
      setIsDownloading(true)

      const hash = await makePasswordHash(password, profile.uuid)
      const fileLocationResponse = await api.downloadBackup(item, true, hash)
      const response = await axios.get(fileLocationResponse.url, {
        responseType: 'blob',
        withCredentials: true,
      })

      downloadFile(response.data, parseFileName(item.filename))
      setIsDownloading(false)
      onClose?.()
      backupDownloadSuccessToast()
    } catch (e) {
      if (e instanceof Error && e?.message === ErrorMessage.BAD_REQUEST) {
        formik.setFieldError(
          'password',
          intl.formatMessage({ id: 'COMMON_VALIDATION_INVALID_PASSWORD' })
        )
      } else if (
        e instanceof Error &&
        e?.message === ErrorMessage.FILE_TOO_BIG
      ) {
        setDownloadError?.(
          <FormattedMessage
            id="SETTINGS_BACKUPS_DOWNLOAD_FILE_TOO_BIG"
            values={{
              learnMore: (
                <ExternalLink link="https://help.ui.com/hc/en-us/articles/360008976393">
                  {intl.formatMessage({ id: 'COMMON_ACTION_LEARN_MORE' })}
                </ExternalLink>
              ),
            }}
          />
        )
      } else {
        setDownloadError?.(
          <FormattedMessage id="SETTINGS_BACKUPS_DOWNLOAD_GENERIC_ERROR" />
        )
      }

      setIsDownloading(false)
    }
  }

  const resetForm = () => {
    setDownloadError?.(undefined)
    formik.resetForm()
  }

  return (
    <GenericModal
      isOpen={isOpen}
      onRequestClose={() => {
        resetForm()
        onClose?.()
      }}
      onAfterOpen={resetForm}
      title={<FormattedMessage id="SETTINGS_BACKUPS_DOWNLOAD_MODAL_TITLE" />}
      size="small"
      onAfterClose={resetForm}
      actions={[
        {
          text: <FormattedMessage id="COMMON_ACTION_CLOSE" />,
          onClick: onClose,
        },
        {
          text: <FormattedMessage id="COMMON_ACTION_DOWNLOAD" />,
          variant: 'primary',
          onClick: () => formik.handleSubmit(),
          loader: isDownloading ? 'dots' : undefined,
          type: 'submit',
          disabled: !!isDownloading || !formik.values.password,
        },
      ]}
    >
      <Description>
        <FormattedMessage id="SETTINGS_BACKUPS_DOWNLOAD_MODAL_DESCRIPTION" />
      </Description>

      <ModalForm onSubmit={formik.handleSubmit}>
        <Input
          variant="secondary"
          type="password"
          name="password"
          label={intl.formatMessage({
            id: 'COMMON_SSO_LABEL_PASSWORD',
          })}
          value={formik.values.password}
          disabled={!!isDownloading}
          invalid={formik.touched.password && formik.errors.password}
          onChange={({ currentTarget }) => {
            setDownloadError?.(undefined)
            formik.setFieldValue('password', currentTarget.value)
          }}
          onBlur={formik.handleBlur}
          autoComplete="password"
          passwordToggle
          focus
          full
        />
        {downloadError && <ErrorContainer>{downloadError}</ErrorContainer>}
      </ModalForm>
    </GenericModal>
  )
}

export const PASSWORD_MODAL_ID = 'PASSWORD_MODAL_ID'

const WrappedSubmitPasswordModal = () => (
  <ModalWrapper modalId={PASSWORD_MODAL_ID}>
    <SubmitPasswordModal />
  </ModalWrapper>
)

export default WrappedSubmitPasswordModal

const Description = styled.div`
  margin-bottom: 16px;
  margin-top: 12px;
  color: ${(p) => p.theme.text2};
  text-align: left;
`

const ErrorContainer = styled.div`
  color: ${({ theme }) => theme.red06};
  font: ${typography['desktop-typography-body']};
  margin: 20px 0;
`

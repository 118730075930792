import React, { SetStateAction, useCallback, useContext, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Button from '@ubnt/ui-components/Button/Button'
import CheckmarkIcon from '@ubnt/icons/CheckmarkIcon'
import { BasicToast, ToastContext } from '@ubnt/ui-components/Toast'
import axios from 'axios'
import { typography } from 'theme'
import styled from 'theme/styled'
import { StatusPill } from 'components/RequestsStatusPill'
import { FlexWrapper } from 'components/SharedComponents'

import {
  getDaysToClose,
  getFormattedTime,
  pendingClosure,
} from '../requests/utils'

import { SecondaryText } from './styles'
import {
  ZendeskTicketWithComments,
  TicketStatus,
  TicketType,
} from 'api/zendesk/types'
import { useClosedTicketsQuery } from 'store/queries/useClosedTicketsQuery'
import { useOpenTicketsQuery } from 'store/queries/useOpenTicketsQuery'

const {
  api: { accountBE },
} = __CONFIG__

export const RequestTitle: React.FC<
  ZendeskTicketWithComments & {
    setTicket: React.Dispatch<
      SetStateAction<{
        loading: boolean
        ticket?: ZendeskTicketWithComments
      }>
    >
  }
> = ({
  status,
  assignee_id,
  type,
  id,
  created_at,
  description,
  is_pending,
  updated_at,
  setTicket,
}) => {
  const intl = useIntl()
  const { createNotification } = useContext(ToastContext)
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const { refetchOpenTickets } = useOpenTicketsQuery()
  const { refetchClosedTickets } = useClosedTicketsQuery()

  const createGenericErrorNotfication = useCallback(() => {
    createNotification(
      <BasicToast
        stateIndicator="danger"
        title={<FormattedMessage id="SUPPORT_SUBMIT_FAILED_TOAST_TITLE" />}
        details={<FormattedMessage id="REQUEST_COMMENT_ERROR" />}
      />
    )
  }, [createNotification])

  const handleMarkAsClosed = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      setIsSubmitLoading(true)
      const url = `${accountBE.base}/${accountBE.paths.zendeskMarkTicketAsClosed}/${id}`
      await axios
        .put(url, {}, { withCredentials: true })
        .then(({ data }) => {
          setTicket(() => ({
            loading: false,
            ticket: data?.ticket,
          }))
          refetchOpenTickets()
          refetchClosedTickets()
          setIsSubmitLoading(false)
        })
        .catch(() => {
          setIsSubmitLoading(false)
          createGenericErrorNotfication()
        })
    },
    [
      id,
      setTicket,
      refetchOpenTickets,
      refetchClosedTickets,
      createGenericErrorNotfication,
    ]
  )

  const canCloseTicket =
    status !== TicketStatus.Closed &&
    type !== TicketType.Problem &&
    !!assignee_id

  const displayCloseMessage = status === TicketStatus.Answered

  return (
    <FlexWrapper column>
      <FlexWrapper justifyContent="space-between" gap={20}>
        <TicketTitle title={description}>{description}</TicketTitle>
        <StatusPill className={status.toLowerCase()}>
          {displayCloseMessage ? pendingClosure : status}
        </StatusPill>
      </FlexWrapper>
      <SecondaryText>
        #{id}
        {is_pending && (
          <span className="pending">
            <FormattedMessage id="SUPPORT_MY_REQUESTS_AWAITING" />
          </span>
        )}
      </SecondaryText>

      <SecondaryText>
        <FormattedMessage
          id="REQUEST_CREATED_AT"
          values={{ date: getFormattedTime(created_at, intl, true) }}
        />
      </SecondaryText>

      {canCloseTicket && (
        <Container $displayCloseMessage={displayCloseMessage}>
          {displayCloseMessage && (
            <InfoWrapper>
              <StyledCheckmarkIcon variant="fill" size="original" />
              <FormattedMessage
                id="REQUEST_WILL_CLOSE"
                values={{ number: getDaysToClose(updated_at) }}
              />
            </InfoWrapper>
          )}
          <Button
            variant="primary"
            size="small"
            onClick={handleMarkAsClosed}
            loader={isSubmitLoading ? 'dots' : undefined}
          >
            <FormattedMessage id="REQUEST_CLOSE_NOW" />
          </Button>
        </Container>
      )}
    </FlexWrapper>
  )
}

const TicketTitle = styled.span`
  font: ${typography['desktop-heading-large']};
  color: ${({ theme }) => theme.text1};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const Container = styled.div<{
  $displayCloseMessage: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: ${({ $displayCloseMessage }) =>
    $displayCloseMessage ? 'space-between' : 'flex-end'};
  padding: 14px 12px;
  background-color: ${({ theme, $displayCloseMessage }) => {
    if ($displayCloseMessage) {
      return theme.green01
    }
    return 'transparent'
  }};
  border-radius: 4px;
  margin-top: 16px;
  gap: 12px;
`

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font: ${typography['desktop-caption']};
  color: ${({ theme }) => theme.text2};
`

const StyledCheckmarkIcon = styled(CheckmarkIcon)`
  color: ${({ theme }) => theme.green06};
`

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Text } from '@ubnt/ui-components/aria'
import styled from 'theme/styled'
import { GridHeaderRow } from './InvoicesTable.styles'

export const InvoicesTableHeader = () => {
  return (
    <GridHeaderRow $noBorder>
      <HeaderCell
        variant="heading-medium"
        color="text-1"
        className="extra-data"
      >
        <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_TABLE_COL_STATUS" />
      </HeaderCell>
      <HeaderCell variant="heading-medium" color="text-1">
        <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_TABLE_COL_SUBSCRIPTION" />
      </HeaderCell>
      <HeaderCell
        variant="heading-medium"
        color="text-1"
        className="extra-data"
      >
        <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_TABLE_COL_NAME" />
      </HeaderCell>
      <HeaderCell
        variant="heading-medium"
        color="text-1"
        className="extra-data"
      >
        <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_COLUMN_LABEL_BILLING_DATE" />
      </HeaderCell>
      <HeaderCell
        variant="heading-medium"
        color="text-1"
        className="extra-data"
      >
        <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_TABLE_COL_BILLED_TO" />
      </HeaderCell>
      <HeaderCell variant="heading-medium" color="text-1">
        <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_TABLE_COL_AMOUNT" />
      </HeaderCell>
      <Placeholder />
    </GridHeaderRow>
  )
}

const HeaderCell = styled(Text)`
  padding: 0 4px;
`

const Placeholder = styled.div``

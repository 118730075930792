import type Stripe from 'stripe'

import { StripeTaxId } from 'features/payment-methods/modules/types'
import { StripeRegionCode, TaxIdType } from 'features/stripe/ui/types'
import { StripeData } from 'types/types'

import {
  deleteRequestWithCookies,
  fetchRequestWithCookies,
  postRequestWithCookies,
  putRequestWithCookies,
} from '../axios'
import { BillingCustomerApi } from './billingCustomer'

type TaxIdData = {
  type: TaxIdType
  value: string
}

type CreateTaxIdArgs = {
  taxData: TaxIdData
  hasCustomer: boolean
  region: StripeRegionCode
}

const {
  api: { billing },
} = __CONFIG__

const billingCustomerApi = new BillingCustomerApi()

export class BillingTaxIdApi {
  public get = async () => {
    const url = new URL(billing.paths.taxId, billing.base)

    const { data } = await fetchRequestWithCookies<StripeTaxId>(url.href)
    return data
  }

  public create = async (data: CreateTaxIdArgs) => {
    if (!data.hasCustomer) {
      await billingCustomerApi.create(data.region)
    }

    const url = new URL(billing.paths.taxId, billing.base)

    const { taxData } = data
    await postRequestWithCookies<TaxIdData, StripeData<Stripe.TaxId>>(
      url.href,
      taxData
    )
  }

  public update = async (data: TaxIdData) => {
    const url = new URL(billing.paths.taxId, billing.base)
    await putRequestWithCookies<TaxIdData, StripeData<Stripe.TaxId>>(
      url.href,
      data
    )
  }

  public delete = async () => {
    const url = new URL(billing.paths.taxId, billing.base)

    await deleteRequestWithCookies(url.href)
  }
}

import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'theme/styled'
import { Badge } from 'pages/security/components/SetupMFAModal/MFAOption'
import { ShowOtherButton } from 'components/showOtherButton/ShowOtherButton'
import { HideOptions } from 'components/hideOptions/HideOptions'
import { GenericModal } from 'components/generic-modal/GenericModal'
import { useVerifyCountryLimitation } from 'features/auth/ui/useVerifyCountryLimitation'
import ModalWrapper, { ModalProps } from '../ModalWrapper'
import { ModalHeader } from './ModalHeader'
import { Option } from './Option'
import { getMfaOptions } from './getMfaOptions'
import { usePasskeyAvailableQuery } from 'store/queries/usePasskeyAvailableQuery'

const AddMFAPrompt: React.FC<ModalProps> = (props) => {
  const [showOtherMethods, setShowOtherMethods] = useState(false)
  const { hasVerifyCountryLimitation } = useVerifyCountryLimitation()
  const { isPasskeyAvailable } = usePasskeyAvailableQuery()

  const mfaOptions = getMfaOptions({
    hasVerifyCountryLimitation,
    isPasskeyAvailable,
  })

  return (
    <GenericModal
      contentClassName="modalContent"
      isOpen={props.isOpen}
      onRequestClose={props.onClose}
      footer={<></>}
      size="medium"
    >
      <InnerWrapper>
        <ModalHeader />
        <InformationCard>
          <FormattedMessage
            id="ADD_MFA_PROMPT_INFORMATION"
            values={{
              p: (text) => <p>{text}</p>,
              b: (text) => <b>{text}</b>,
            }}
          />
        </InformationCard>
        <Options>
          <SectionHeaderColumns>
            <Line />
            <Badge>
              <FormattedMessage id="COMMON_LABEL_RECOMMENDED" />
            </Badge>
          </SectionHeaderColumns>
          <Option {...mfaOptions.featured} isFeatured />
        </Options>
        <SectionHeader>
          <ShowOtherButton
            handleClick={() => setShowOtherMethods(!showOtherMethods)}
            isOpen={showOtherMethods}
          />
          <Line />
        </SectionHeader>
        <HideOptions isShown={showOtherMethods}>
          {mfaOptions.other.map((option, index) => (
            <Option key={`${option.title}-${index}`} {...option} />
          ))}
        </HideOptions>
      </InnerWrapper>
    </GenericModal>
  )
}

export const ADD_MFA_PROMPT_MODAL_ID = 'ADD_MFA_PROMPT_MODAL_ID'

export const WrappedAddMFAPrompt = () => (
  <ModalWrapper modalId={ADD_MFA_PROMPT_MODAL_ID}>
    <AddMFAPrompt />
  </ModalWrapper>
)

const SectionHeader = styled.div`
  display: grid;
  align-items: center;
  gap: 12px;
  grid-template-columns: fit-content(200px) 1fr;
`

const SectionHeaderColumns = styled(SectionHeader)`
  grid-template-columns: 1fr fit-content(120px);
`

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const InformationCard = styled.div`
  background-color: ${({ theme }) => theme.neutral01};
  padding: 0 16px;
  border-radius: 8px;
`

const Options = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
`

const Line = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.neutral04};
`

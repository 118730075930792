import React from 'react'
import { ProtectedPage } from 'pages/ProtectedPage'
import AppViewHeader from 'components/AppViewHeader'
import ChangeRegionModal from 'features/payment-methods/ui/ChangeRegionModal'
import AddPaymentMethodModal from 'features/payment-methods/ui/AddPaymentMethodModal'
import EditPaymentMethodModal from 'features/payment-methods/ui/EditPaymentMethodModal'
import RemovePaymentMethodModal from 'features/payment-methods/ui/RemovePaymentMethodModal'
import TaxIdModal from 'features/payment-methods/ui/tax/TaxIdModal'
import { Cards } from './components/Cards'
import { Tax } from './components/Tax'

export const Payments = () => {
  return (
    <ProtectedPage renderMargin={true}>
      <AppViewHeader />
      <Cards />
      <Tax />
      <ChangeRegionModal />
      <AddPaymentMethodModal />
      <EditPaymentMethodModal />
      <RemovePaymentMethodModal />
      <TaxIdModal />
    </ProtectedPage>
  )
}

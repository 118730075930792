import { IntlShape } from 'react-intl/src/types'

const { SEND_SAFELY_HOST } = __CONFIG__
const ZENDESK_HOST = 'https://ubnt.zendesk.com'

export const formatDate = (timestamp: Date, intl: IntlShape) =>
  ` ${intl.formatTime(timestamp, { hourCycle: 'h24' })}, ${intl.formatDate(
    timestamp,
    {
      month: 'short',
      day: 'numeric',
    }
  )}`

export const parseAttachmentUrl = (comment: string | null): string | null => {
  if (!comment) {
    return null
  }

  try {
    const splitString = comment.split('Attachments: ')
    const parsedHref = splitString?.[1]

    // INFO: This is done to avoid XSS
    const urlFromParsedHref = new URL(parsedHref)

    if (
      urlFromParsedHref.origin !== SEND_SAFELY_HOST &&
      urlFromParsedHref.origin !== ZENDESK_HOST
    ) {
      return null
    }

    return parsedHref
  } catch {
    return null
  }
}

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { BillingSubscriptionsApi } from 'api/billing/billingSubscriptions'
import { closeVisibleModal } from 'modules/modals'
import { useDispatch } from 'react-redux'
import { QueryKey } from 'store/types'

const billingSubscriptionsApi = new BillingSubscriptionsApi()

export const useCancelMspSiteSupportSubscription = () => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  const cancelMspSiteSupportSubscription = useMutation({
    mutationFn: billingSubscriptionsApi.cancelMSPSiteSupport,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.BILLING_SUBSCRIPTIONS],
      })
      queryClient.invalidateQueries({
        queryKey: [QueryKey.BILLING_INVOICES],
      })
      dispatch(closeVisibleModal())
    },
  })

  return {
    cancelMspSiteSupportSubscription: cancelMspSiteSupportSubscription.mutate,
    isCancelMspSiteSupportSubscriptionLoading:
      cancelMspSiteSupportSubscription.isPending,
    cancelMspSiteSupportSubscriptionErrors:
      cancelMspSiteSupportSubscription.error,
    resetCancelMspSiteSupportSubscriptionErrors:
      cancelMspSiteSupportSubscription.reset,
  }
}

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { MfaBanner } from './MfaBanner'

export const AddPasskeyBanner: React.FC<{ onClose: () => void }> = ({
  onClose,
}) => {
  const history = useHistory()

  const handleEnable = () => {
    onClose()
    history.push('/security?addmfa=webAuthn')
  }

  return (
    <MfaBanner
      message={
        <FormattedMessage
          id="WEB_AUTHN_BANNER_TEXT"
          values={{ b: (text) => <b>{text}</b> }}
        />
      }
      onClick={handleEnable}
      buttonText="COMMON_ACTION_ENABLE"
      onClose={onClose}
    />
  )
}

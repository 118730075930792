import React from 'react'
import { SkeletonLoader } from '@ubnt/ui-components/SkeletonLoader'
import Table from '@ubnt/ui-components/SkeletonLoader/Skeleton/Table'
import Block from '@ubnt/ui-components/SkeletonLoader/Skeleton/Block'
import { Section } from 'components/Section.styles'

export const SkeletonLoaders: React.FC = () => {
  return (
    <Section $omitMarginTop>
      <SkeletonLoader>
        <Block height="16px" width="18%" />
      </SkeletonLoader>
      <SkeletonLoader>
        <Table rowCounts={4} colCounts={5} rowHeight={28} />
      </SkeletonLoader>
    </Section>
  )
}

import { useSelector } from 'react-redux'
import { useQuery } from '@tanstack/react-query'
import { QueryKey } from 'store/types'
import { FIVE_MINUTES_IN_MILLISECONDS } from 'store/constants'
import { ChatApi } from 'api/chat'
import { selectIsEmployeeAccount } from 'modules/profile'
import { selectRoles } from 'features/early-access/module/roles'
import { useMemo } from 'react'

const chatApi = new ChatApi()

const SUPPORT_ADMIN_ROLE = 'account-support-admin'

export const useChatAdminScheduleQuery = () => {
  const isEmployee = useSelector(selectIsEmployeeAccount)
  const userRoles = useSelector(selectRoles)

  const isSupportAdmin = useMemo(
    () => isEmployee && userRoles.some((role) => role === SUPPORT_ADMIN_ROLE),
    [isEmployee, userRoles]
  )

  const chatAdminScheduleStatus = useQuery({
    queryKey: [QueryKey.CHAT_ADMIN_SCHEDULE],
    queryFn: chatApi.getAdminScheduleOverride,
    enabled: !!isSupportAdmin,
    staleTime: FIVE_MINUTES_IN_MILLISECONDS,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  })

  return {
    isAdminFlagOn: chatAdminScheduleStatus.data?.isAdminFlagOn,
    isChatScheduleFetched: chatAdminScheduleStatus.isFetched,
    isSupportAdmin,
  }
}

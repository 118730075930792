import React, { useCallback, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'theme/styled'
import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import { GenericModal } from 'components/generic-modal/GenericModal'
import { useHistory } from 'react-router'
import { FullSubscription } from 'features/subscriptions/fullSubscriptions/useFullSubscriptions'
import { useCancelSiteSupportSubscription } from 'store/mutations/billingSubscriptions/useCancelSiteSupportSubscription'

interface Props extends ModalProps {
  fullSubscription?: FullSubscription
}

const CancelSubscriptionSiteSupportModal: React.FC<Props> = ({
  isOpen,
  onClose,
  fullSubscription,
}) => {
  const history = useHistory()
  const {
    cancelSiteSupportSubscription,
    isCancelSiteSupportSubscriptionLoading,
    cancelSiteSupportSubscriptionErrors,
    resetCancelSiteSupportSubscriptionErrors,
  } = useCancelSiteSupportSubscription()

  const error = useMemo(() => {
    if (
      cancelSiteSupportSubscriptionErrors &&
      (Object.values(cancelSiteSupportSubscriptionErrors as Error).length ||
        typeof cancelSiteSupportSubscriptionErrors === 'string')
    ) {
      return <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CANCEL_ERROR" />
    } else if (!fullSubscription) {
      return <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_FETCH_ERROR" />
    } else {
      return null
    }
  }, [cancelSiteSupportSubscriptionErrors, fullSubscription])

  const onConfirm = useCallback(() => {
    if (error) {
      history.push('/supportform')
    } else {
      if (!fullSubscription) {
        return
      }

      cancelSiteSupportSubscription({
        id: fullSubscription.id,
      })
    }
  }, [error, history, fullSubscription, cancelSiteSupportSubscription])

  return (
    <GenericModal
      isOpen={isOpen}
      onRequestClose={onClose}
      title={<Title />}
      size="small"
      onAfterClose={() => resetCancelSiteSupportSubscriptionErrors()}
      actions={[
        {
          text: <FormattedMessage id="COMMON_ACTION_CANCEL" />,
          onClick: onClose,
        },
        {
          text: error ? (
            <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CONTACT_SUPPORT" />
          ) : (
            <FormattedMessage id="COMMON_ACTION_CONFIRM" />
          ),
          variant: 'primary',
          onClick: onConfirm,
          loader: isCancelSiteSupportSubscriptionLoading ? 'dots' : undefined,
          type: 'submit',
          disabled: isCancelSiteSupportSubscriptionLoading,
        },
      ]}
    >
      {error ? (
        <ErrorMessage>{error}</ErrorMessage>
      ) : (
        <FormattedMessage
          id="SETTINGS_SUBSCRIPTIONS_CANCEL_SUBSCRIPTION_MESSAGE"
          values={{ name: fullSubscription?.name }}
          tagName="div"
        />
      )}
    </GenericModal>
  )
}

export const CANCEL_SUBSCRIPTION_SITE_SUPPORT_MODAL_ID =
  'CANCEL_SUBSCRIPTION_SITE_SUPPORT_MODAL_ID'

const WrappedCancelSubscriptionSiteSupportModal = () => (
  <ModalWrapper modalId={CANCEL_SUBSCRIPTION_SITE_SUPPORT_MODAL_ID}>
    <CancelSubscriptionSiteSupportModal />
  </ModalWrapper>
)

export default WrappedCancelSubscriptionSiteSupportModal

const Title = () => (
  <TitleWrapper>
    <FormattedMessage
      id="SETTINGS_SUBSCRIPTIONS_CANCEL_SUBSCRIPTION"
      tagName="div"
    />
  </TitleWrapper>
)

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const ErrorMessage = styled.div`
  color: ${(p) => p.theme.red06};
`

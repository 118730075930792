import { DateTime } from 'luxon'
import { BackupDetail } from 'api/nca/types'
import { ParsedBackup } from './types'

export const prepareData = ({
  metadata,
  time,
  fileKey,
  filename,
  deviceId,
}: BackupDetail): ParsedBackup => {
  return {
    deviceId,
    id: metadata.id,
    time: DateTime.fromMillis(time).toFormat('yyyy-MM-dd hh:mm'),
    key: fileKey,
    isArchived: !!metadata['x-amz-expiration'],
    filename,
  }
}

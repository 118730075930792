import React from 'react'
import { SubscriptionServiceTableItem } from '../Components.styles'

interface Props {
  quantity?: number
}

export const Quantity: React.FC<Props> = ({ quantity }) => {
  return (
    <SubscriptionServiceTableItem className="extra-data">
      {quantity ?? 1}
    </SubscriptionServiceTableItem>
  )
}

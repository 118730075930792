import ExclamationMarkIcon from '@ubnt/icons/ExclamationMarkIcon'
import { Text } from '@ubnt/ui-components/aria'
import { media } from 'theme'
import styled from 'theme/styled'

const AlertTextContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;

  @media (max-width: ${media.xSmall}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }
`

const PaymentAmount = styled(Text)`
  display: flex;
  align-items: center;
  gap: 4px;
`

const SubscriptionsGridHeader = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 150px;
  border-bottom: ${({ theme }) => `1px solid ${theme.neutral03}`};

  @media (max-width: ${media.mobileLarge}) {
    grid-template-columns: 1fr 150px;
  }
`

const SubscriptionsGridBody = styled(SubscriptionsGridHeader)`
  &:last-of-type {
    border-bottom: none;
  }

  .arrow-icon {
    display: none;
  }

  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.neutral01};

    .arrow-icon {
      display: flex;
    }
  }

  @media (max-width: ${media.xSmall}) {
    .arrow-icon {
      display: flex;
    }
  }
`

const SubscriptionsTableItem = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  padding: 6px 0;
`

const EmptySubscriptionsTableItem = SubscriptionsTableItem

const TableData = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

const ServiceGrid = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr;
  column-gap: 4px;
`

const ServiceName = styled.div`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 4px;
`

const WarningIcon = styled(ExclamationMarkIcon)`
  color: ${({ theme }) => theme.orange06};
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  width: max-content;
  margin-bottom: 16px;
`

const PaymentAmountsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;

  @media (max-width: ${media.xSmall}) {
    margin-left: -28px;
  }
`

const PaymentDueDate = styled(Text)`
  @media (max-width: ${media.xSmall}) {
    margin-left: -28px;
  }
`

export {
  AlertTextContainer,
  PaymentAmount,
  SubscriptionsGridHeader,
  SubscriptionsGridBody,
  SubscriptionsTableItem,
  EmptySubscriptionsTableItem,
  TableData,
  WarningIcon,
  HeaderContainer,
  PaymentAmountsWrapper,
  PaymentDueDate,
  ServiceName,
  ServiceGrid,
}

import React from 'react'
import GlobeMapIconL from '@ubnt/icons/GlobeMapIconL'
import { CircleFlag } from 'react-circle-flags'
import styled from 'theme/styled'
import { StripeRegionCode } from 'features/stripe/ui/types'

interface Props {
  countryCode: string
  size?: 'small'
  noMargin?: boolean
  marginRight?: string
}

export const CountryFlag: React.FC<Props> = ({
  countryCode,
  size,
  noMargin = false,
  marginRight,
}) => {
  return (
    <Container $noMargin={noMargin} $marginRight={marginRight}>
      {countryCode === StripeRegionCode.ROW ? (
        <GlobeMapIconL
          variant="twoTone"
          motif="light"
          isActive
          size="original"
        />
      ) : (
        <StyledFlag countryCode={countryCode.toLowerCase()} $size={size} />
      )}
    </Container>
  )
}

const Container = styled.div<{
  $noMargin?: boolean
  $marginRight?: string
}>`
  display: flex;
  align-items: center;
  margin-left: ${({ $noMargin }) => ($noMargin ? '0px' : '-8px')};
  ${({ $marginRight }) => $marginRight && `margin-right: ${$marginRight};`}
`

const StyledFlag = styled(CircleFlag)<{ $size?: 'small' }>`
  height: ${({ $size }) => ($size === 'small' ? '16px' : '18px')};
`

import { Breadcrumbs } from 'components/Breadcrumbs'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { MonthViewGrid } from './MonthViewGrid'
import { DateTime } from 'luxon'
import styled from 'theme/styled'
import { UnpaidInvoicesAlert } from './UnpaidInvoicesAlert'
import { InvoiceItem } from 'pages/subscriptions/hooks/utils'
import { useInvoices } from 'pages/subscriptions/hooks/useInvoices'
import { InvoiceContext } from '../../InvoiceContext'

type Props = {
  selectedMonth: string
  onResetSelectedMonth: () => void
}

export const MonthView: React.FC<Props> = ({
  selectedMonth,
  onResetSelectedMonth,
}) => {
  const { selectedYear } = useContext(InvoiceContext)

  const { dataByYear } = useInvoices()

  const [selectedMonthInvoices, setSelectedMonthInvoices] = useState<
    InvoiceItem | undefined
  >(undefined)

  useEffect(() => {
    if (!selectedMonthInvoices) {
      setSelectedMonthInvoices(
        dataByYear[selectedYear]?.invoiceTableItems?.find(
          (item) => item.id === selectedMonth
        )
      )
    }
  }, [dataByYear, selectedMonth, selectedMonthInvoices, selectedYear])

  const breadcrumbLinks = [
    {
      url: '/subscriptions?type=invoices',
      label: <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_HEADER_ARCHIVE" />,
      onClick: onResetSelectedMonth,
    },
  ]

  const formattedMonth = useMemo(() => {
    const stringToDate = DateTime.fromFormat(selectedMonth, 'M.yyyy')

    return stringToDate.toFormat('MMMM, yyyy')
  }, [selectedMonth])

  return (
    <Wrapper>
      <Breadcrumbs links={breadcrumbLinks} currentPage={formattedMonth} />
      <UnpaidInvoicesAlert selectedMonthInvoices={selectedMonthInvoices} />
      <MonthViewGrid selectedMonthInvoices={selectedMonthInvoices} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export type ParsedBackup = {
  deviceId: string
  id: string
  time: string
  key: string
  isArchived: boolean
  filename: string
}

export const DEFAULT_UIDB_IMG = 'SELF-HOSTED-NETWORK-SERVER'

import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, useLocation } from 'react-router-dom'
import {
  selectIsAuthenticated,
  selectIsLoading,
} from 'features/auth/modules/auth'
import { handleRequiresAuth } from 'utils/handleRequiresAuth'

interface Props {
  children: React.ReactNode
}

export const RequiresAuth: React.FC<Props> = ({ children }) => {
  const isAuthenticating = useSelector(selectIsLoading)
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const location = useLocation()

  if (!isAuthenticated && !isAuthenticating) {
    const redirectUrl = handleRequiresAuth(location)

    return <Redirect to={redirectUrl} />
  }

  if (isAuthenticating) {
    return null
  }

  return <>{children}</>
}

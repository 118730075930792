import React, { ReactElement } from 'react'
import Button from '@ubnt/ui-components/Button/Button'
import { Text } from '@ubnt/ui-components/aria'
import theme from 'theme'
import styled from 'theme/styled'
import PasskeyIconL from '@ubnt/icons/PasskeyIconL'
import ClosePrimaryIcon from '@ubnt/icons/ClosePrimaryIcon'
import { FormattedMessage } from 'react-intl'

interface Props {
  message: ReactElement
  onClick: () => void
  buttonText: string
  onClose?: () => void
}

export const MfaBanner: React.FC<Props> = ({
  message,
  onClick,
  buttonText,
  onClose,
}) => {
  return (
    <Alert>
      <Content>
        <PasskeyIconL variant="fill" size={20} isActive />
        <Text>{message}</Text>
        <Button variant="link" onClick={onClick}>
          <FormattedMessage id={buttonText} />
        </Button>
      </Content>
      {onClose && (
        <StyledButton
          Icon={ClosePrimaryIcon({ size: 20 })}
          variant="tertiary"
          onClick={onClose}
        />
      )}
    </Alert>
  )
}

const Alert = styled.div`
  display: flex;
  width: 100%;
  padding: 12px 16px;
  align-items: space-between;
  gap: 16px;
  margin-bottom: 16px;
  background: ${({ theme }) => theme.neutral01};
  background-opacity: 0.02;
  border-radius: 0px 0px 4px 4px;

  @media (max-width: ${theme.media.mobileXLarge}) {
    gap: 12px;
    padding: 8px 12px;
  }
`

const Content = styled.div`
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  @media (max-width: ${theme.media.mobileXLarge}) {
    height: auto;
    gap: 8px;
  }
`

const StyledButton = styled(Button)`
  padding: 0;
  height: 20px;

  &:hover {
    background: transparent;
  }

  @media (max-width: ${theme.media.mobileXLarge}) {
    height: 100%;
  }
`

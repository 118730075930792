import React, { useMemo } from 'react'
import { DropdownOption } from '@ubnt/ui-components/Dropdown'
import { ALL_KEY, ALL_LABEL } from 'sharedConstants'
import { validStripeCountries } from 'features/stripe/ui/regions'
import { useBillingCardsQuery } from 'store/queries/useBillingCardsQuery'
import { FilterDropdown } from './Components.styles'

interface Props {
  filter: string
  onChange: (filter: string) => void
}

export const CardFilter: React.FC<Props> = ({ filter, onChange }) => {
  const { customerCards, cardCountries, hasMultipleCountries } =
    useBillingCardsQuery()

  const countryFilters = useMemo(() => {
    return validStripeCountries
      .filter(({ code }) => cardCountries.some((region) => region === code))
      .map(({ name, code }) => ({
        label: name,
        value: code,
      }))
  }, [cardCountries])

  const filterOptions = useMemo(() => {
    return [{ label: ALL_LABEL, value: ALL_KEY }, ...countryFilters]
  }, [countryFilters])

  if (!customerCards.length || !hasMultipleCountries) {
    return null
  }

  return (
    <FilterDropdown
      options={filterOptions}
      value={filter}
      variant="tertiary"
      width="160px"
      inputClassName="filter-input"
      inputContainerClassName="filter-input-container"
      onChange={(option: DropdownOption) => onChange(option.value as string)}
    />
  )
}

import React, { useCallback, useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import CheckmarkIcon from '@ubnt/icons/CheckmarkIcon'
import styled from 'theme/styled'
import { StyledInput } from './registerAccount/RegisterForm'
import PasswordFeedback, { PasswordFeedbackData } from './PasswordFeedback'
import { PasswordRequirements } from './PasswordRequirements'
import { useCreateAccount } from 'store/mutations/ssoAccount/useCreateAccount'

interface Props {
  passwordFocused: boolean
  setPasswordFocused: React.Dispatch<React.SetStateAction<boolean>>
  handleBlur: (e: React.FocusEvent) => void
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handlePasswordChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  password?: string
  confirmPassword?: string
  passwordLongEnough?: boolean
  passwordErrors?: string
  confirmPasswordErrors?: string
  passwordTouched?: boolean
  confirmPasswordTouched?: boolean
  apiErrors: Record<string, string>
  passwordData: PasswordFeedbackData | null
  passwordCheckLoading: boolean
  setPasswordConfirmError: React.Dispatch<React.SetStateAction<boolean>>
  passwordConfirmError: boolean
  confirmPasswordSame: boolean
}

export const PasswordFields: React.FC<Props> = ({
  password,
  passwordFocused,
  setPasswordFocused,
  passwordLongEnough,
  passwordErrors,
  handleBlur,
  handleChange,
  apiErrors,
  passwordTouched,
  passwordData,
  passwordCheckLoading,
  confirmPassword,
  setPasswordConfirmError,
  confirmPasswordErrors,
  confirmPasswordTouched,
  passwordConfirmError,
  confirmPasswordSame,
  handlePasswordChange,
}) => {
  const intl = useIntl()
  const { resetCreateAccountErrors } = useCreateAccount()

  const handleChangeWithReset = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (apiErrors) {
        resetCreateAccountErrors()
      }
      handleChange(e)
    },
    [apiErrors, handleChange, resetCreateAccountErrors]
  )

  const handleChangePasswordWithReset = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (apiErrors) {
        resetCreateAccountErrors()
      }
      handlePasswordChange(e)
    },
    [apiErrors, handlePasswordChange, resetCreateAccountErrors]
  )

  const invalidMessage = useMemo(() => {
    if (passwordTouched && passwordErrors) {
      return passwordErrors
    }
    if (passwordData?.error) {
      return passwordData.error
    }
  }, [passwordErrors, passwordData, passwordTouched])

  const checkPasswordConfirmError = useCallback(() => {
    if (confirmPassword?.length && confirmPassword !== password) {
      setPasswordConfirmError(true)
    } else {
      setPasswordConfirmError(false)
    }
  }, [confirmPassword, password, setPasswordConfirmError])

  useEffect(() => {
    checkPasswordConfirmError()
  }, [checkPasswordConfirmError, password])

  return (
    <>
      <Wrapper>
        <StyledInput
          data-testid="password"
          type="password"
          name="password"
          label={intl.formatMessage({ id: 'COMMON_LABEL_PASSWORD' })}
          value={password}
          onChange={handleChangePasswordWithReset}
          onFocus={() => setPasswordFocused(true)}
          helperMessage={
            passwordLongEnough &&
            !passwordErrors &&
            !passwordFocused && (
              <PasswordFeedback
                passwordLongEnough={passwordLongEnough ?? false}
                passwordCheckLoading={passwordCheckLoading}
                passwordData={passwordData}
                apiErrors={apiErrors}
              />
            )
          }
          onBlur={(e) => {
            handleBlur(e)
            setPasswordFocused(false)
          }}
          invalid={!passwordFocused && invalidMessage}
          passwordToggle
          full
          variant="secondary"
        />
        {passwordFocused && (
          <PasswordRequirements
            passwordFocused={passwordFocused}
            password={password}
          />
        )}
      </Wrapper>
      <StyledInput
        data-testid="passwordConfirm"
        type="password"
        name="passwordConfirm"
        label={intl.formatMessage({ id: 'COMMON_LABEL_PASSWORD_CONFIRM' })}
        value={confirmPassword}
        onChange={(e) => {
          setPasswordConfirmError(false)
          handleChangeWithReset(e)
        }}
        onBlur={(e) => {
          checkPasswordConfirmError()
          handleBlur(e)
        }}
        invalid={
          (confirmPasswordTouched && confirmPasswordErrors) ||
          (passwordConfirmError &&
            intl.formatMessage({
              id: 'COMMON_AUTH_REGISTER_PASSWORD_NOT_SAME',
            }))
        }
        contentAfter={
          confirmPasswordSame && <StyledCheckIcon variant="twoTone" isActive />
        }
        passwordToggle
        full
        variant="secondary"
      />
    </>
  )
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  > div {
    margin: 0;
  }
`

const StyledCheckIcon = styled(CheckmarkIcon)`
  position: absolute;
  transform: translate(-10%, -10%);
`

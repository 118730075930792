import { Text } from '@ubnt/ui-components/aria'
import { media } from 'theme'
import styled from 'theme/styled'

export const Wrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.neutral03};
  border-radius: 6px;
`

export const GridWrapper = styled.div`
  display: grid;
`

export const GridRow = styled.div<{ $noBorder?: boolean }>`
  padding: 6px 4px;
  display: grid;
  grid-template-columns: 1fr 20px;
  border-top: 1px solid
    ${({ theme, $noBorder }) => ($noBorder ? 'none' : theme.neutral03)};

  .extra-data {
    display: none;
  }

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.neutral01};

    .extra-data {
      display: block;
      align-self: center;
    }
  }
  @media (max-width: ${media.mobileXLarge}) {
    .extra-data {
      display: block;
      align-self: center;
    }
  }
`

export const ValueCell = styled(Text)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${({ theme }) => theme.text2};
`

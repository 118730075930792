import React from 'react'
import { useDispatch } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button } from '@ubnt/ui-components/Button'
import { typography } from '@ubnt/ui-components/styles/designToken'
import styled from 'theme/styled'
import { Session } from 'api/sessions'
import { logout } from 'features/auth/modules/auth'
import { LockTooltip } from 'pages/security/LockTooltip'
import { CountryFlag } from 'components/CountryFlag'
import { DeviceLogoImage } from 'components/SharedComponents'
import { sessionStorageChatKey } from 'features/support/unifi/UniFiForm'
import { formatTime, getDeviceImage, formatSession } from './utils'
import { useRevokeSession } from 'store/mutations/sessions/useRevokeSession'

interface Props {
  session: Session
  isCurrentSession?: boolean
}

export const SessionItem: React.FC<Props> = ({ session, isCurrentSession }) => {
  const dispatch = useDispatch()
  const intl = useIntl()

  const { revokeSession } = useRevokeSession()

  const sessionData = formatSession({
    simplifiedUA: session.simplified_agent,
    deviceName: session.device_name,
    userAgent: session.user_agent,
    deviceModel: session.device_model,
  })

  const renderLocation = (
    city: string | null | undefined,
    country: string | null | undefined
  ) => {
    if (city && country) return `${city}, ${country}`
    if (city) return city
    if (country) return country
  }

  const onSignOut = () => dispatch(logout)

  const signOut = () => {
    sessionStorage.removeItem(sessionStorageChatKey)
    if (isCurrentSession) {
      return onSignOut()
    }
    revokeSession(session.session_id)
  }

  return (
    <Wrapper key={session.session_id}>
      <ImageWrapper>
        <DeviceLogoImage
          src={getDeviceImage({
            deviceModel: session.device_model,
            device: sessionData.device,
            deviceOs: sessionData.device_os,
          })}
        />
      </ImageWrapper>
      <TextWrapper>
        <Info>
          <Title>
            {sessionData.uaInfo}
            {isCurrentSession ? (
              <CurrentSession>
                <FormattedMessage id="COMMON_LABEL_CURRENT_SESSION" />
              </CurrentSession>
            ) : (
              <NonCurrentSession>
                {formatTime(session.created, intl)}
              </NonCurrentSession>
            )}
          </Title>
          <Text>
            {session.location?.countryCode && (
              <CountryFlag
                noMargin
                countryCode={session.location.countryCode.toLowerCase()}
                marginRight="4px"
                size="small"
              />
            )}
            {renderLocation(session.location?.city, session.location?.country)}
          </Text>
          <Text>
            <FormattedMessage
              id="COMMON_IP_ADDRESS"
              values={{ ip: session.ip }}
            />
          </Text>
        </Info>
        <LockTooltip inline={true}>
          <StyledButton
            variant="link"
            size="medium"
            weight="normal"
            onClick={signOut}
          >
            <FormattedMessage id="COMMON_AUTH_ACTION_SIGN_OUT" />
          </StyledButton>
        </LockTooltip>
      </TextWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: ${({ theme }) => `1px ${theme.neutral03} solid`};

  @media (max-width: ${({ theme }) => theme.media.mobileXLarge}) {
    padding: 16px 0;
  }
`

const ImageWrapper = styled.div`
  height: 100%;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.media.mobileXLarge}) {
    align-items: flex-start;
    justify-content: space-between;
  }
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledButton = styled(Button)`
  line-height: 24px;
  width: 100px;
`

const Title = styled.div`
  font-weight: 700;
  font: ${typography['desktop-heading-medium']};
  color: ${({ theme }) => theme.text2};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  word-break: break-word;

  @media (max-width: ${({ theme }) => theme.media.mobileXLarge}) {
    flex-direction: column;
    gap: 8px;
    align-items: start;
    margin-bottom: 8px;
  }
`

const Text = styled.div`
  font-weight: 400;
  font: ${typography['desktop-typography-caption']};
  color: ${({ theme }) => theme.text3};
  display: flex;
  align-items: center;
`

const CurrentSession = styled.span`
  height: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.ublue01};
  border-radius: 16px;
  color: ${({ theme }) => theme.ublue06};
  font-size: 10px;
  line-height: 16px;
  padding: 0 9px;
  width: max-content;
  margin-left: 8px;

  @media (max-width: ${({ theme }) => theme.media.mobileXLarge}) {
    padding: 0;
    width: -webkit-fill-available;
    max-width: 100px;
    margin: 0;
  }
`

const NonCurrentSession = styled(CurrentSession)`
  background: ${({ theme }) => theme.neutral03};
  color: ${({ theme }) => theme.neutral10};
`

import {
  deleteRequestWithCookies,
  fetchRequestWithCookies,
  postRequestWithCookies,
} from './axios'
import { api } from 'api'

const {
  api: { sso },
} = __CONFIG__

type CreateTrustedDeviceArgs = {
  device_id: string
}

interface TrustedDevice {
  device_id: string
  device_name: string
  device_model: string
  created_at: string
  simplified_agent: {
    agent: string | null
    is_ui_product: boolean
    os: string
  }
  user: string
  session: string
  is_current_device: boolean
}

export class TrustedDevicesApi {
  getAll = async () => {
    const url = new URL(sso.paths.trustedDevices, api.ssoBase)

    const { data } = await fetchRequestWithCookies<TrustedDevice[]>(url.href)
    return data
  }

  getDeviceData = async () => {
    const url = new URL(sso.paths.device, api.ssoBase)

    const { data } = await fetchRequestWithCookies<TrustedDevice>(url.href)
    return data
  }

  create = async (data: CreateTrustedDeviceArgs) => {
    const url = new URL(sso.paths.device, api.ssoBase)

    await postRequestWithCookies(url.href, { device_id: data.device_id })
  }

  deleteMany = async (data: { ids: string[] }) => {
    const url = new URL(sso.paths.trustedDevices, api.ssoBase)
    data.ids.forEach((id) => url.searchParams.append('id', id))

    await deleteRequestWithCookies(url.href)
  }
}

import React from 'react'
import { FormattedMessage, IntlShape } from 'react-intl'
import { api } from 'api'
import { AxiosError } from 'axios'
import { pick } from 'lodash'
import { isAxiosError } from 'utils/isAxiosError'
import {
  PAST_EXPIRY,
  STRIPE_VALUE_INCOMPLETE,
  STRIPE_VALUE_INVALID,
} from './common'
import { regionsThatAllowDifferentCardCountries } from 'features/stripe/config'
import { StripeCountryCode, StripeRegionCode } from 'features/stripe/ui/types'
import { validStripeCountryMap } from 'features/stripe/ui/regions'
import { getStripeMutationErrorMessage } from 'store/mutations/utils'
import { PaymentMethodErrors } from './types'

const getAddressLabel = (field: string) => {
  switch (field) {
    case 'street':
      return 'COMMON_LABEL_STREET'
    case 'region':
      return 'COMMON_LABEL_STATE'
    case 'city':
      return 'COMMON_LABEL_CITY'
    case 'postal_code':
      return 'COMMON_LABEL_POSTAL_CODE'
    default:
      return null
  }
}

/* Stripe elements cannot use the theme since Stripe does not support 
hsla colour values and the UIC palette is built using them */
export const stripeStyles = {
  base: {
    fontSize: '13px',
    color: '#000000d9',
    fontFamily: 'Lato, sans-serif',
    '::placeholder': {
      color: '#00000033',
    },
  },
  invalid: {
    color: '#f03a3e',
  },
}

export const stripeStylesDark = {
  base: {
    fontSize: '13px',
    color: '#ffffffd9',
    fontFamily: 'Lato, sans-serif',
    '::placeholder': {
      color: '#ffffff33',
    },
  },
  invalid: {
    color: '#cd3237',
  },
}

export const handleCaughtAddressError = (
  unknownError: unknown,
  intl: IntlShape,
  setAddressError: (state: { [key: string]: string | string[] } | null) => void
) => {
  if (!isAxiosError(unknownError)) return
  const error = unknownError as AxiosError
  if (!error.response?.data) return
  const data = error.response.data
  const parsedData: { [key: string]: string } = {}
  Object.keys(data).forEach((key: string) => {
    if (data[key as keyof typeof data] === 'detail') return
    const addressLabel = getAddressLabel(key)
    if (!addressLabel) return
    if (data[key as keyof typeof data][0] === 'invalid') {
      parsedData[key] = intl.formatMessage(
        { id: 'COMMON_VALIDATION_INVALID' },
        { name: intl.formatMessage({ id: addressLabel }) }
      )
    }
    if (
      data[key as keyof typeof data][0] ===
      'Ensure this field has no more than 9 characters.'
    ) {
      parsedData[key] = intl.formatMessage(
        { id: 'COMMON_VALIDATION_MAX_CHARS' },
        { name: intl.formatMessage({ id: addressLabel }), max: '9' }
      )
    }
    if (data[key as keyof typeof data][0] === 'required') {
      parsedData[key] = intl.formatMessage(
        { id: 'COMMON_VALIDATION_REQUIRED' },
        { name: intl.formatMessage({ id: addressLabel }) }
      )
    }
  })
  setAddressError(parsedData)
}

export const checkAddress = async (values: {
  name: string
  country: string
  line1: string
  line2: string
  city: string
  state: string
  postal_code: string
}) => {
  return await api.checkAddress(
    pick(values, ['city', 'country', 'postal_code', 'state', 'line1', 'line2'])
  )
}

export const cardNumberErrorMessage = (cardNumber: string) => {
  if (cardNumber === STRIPE_VALUE_INCOMPLETE)
    return <FormattedMessage id="SETTINGS_PAYMENTS_INCOMPLETE_CARD_NUMBER" />
  if (cardNumber === STRIPE_VALUE_INVALID)
    return <FormattedMessage id="SETTINGS_PAYMENTS_INVALID_CARD_NUMBER" />
  if (cardNumber === '')
    return <FormattedMessage id="SETTINGS_PAYMENTS_EMPTY_CARD_NUMBER" />
  return null
}

export const cardExpiryErrorMessage = (cardExpiry: string) => {
  if (cardExpiry === STRIPE_VALUE_INCOMPLETE)
    return <FormattedMessage id="SETTINGS_PAYMENTS_INCOMPLETE_CARD_EXPIRY" />
  if (cardExpiry === STRIPE_VALUE_INVALID)
    return <FormattedMessage id="SETTINGS_PAYMENTS_INVALID_CARD_EXPIRY" />
  if (cardExpiry === PAST_EXPIRY)
    return <FormattedMessage id="SETTINGS_PAYMENTS_INVALID_CARD_EXPIRY_PAST" />
  if (cardExpiry === '')
    return <FormattedMessage id="SETTINGS_PAYMENTS_EMPTY_CARD_EXPIRY" />
  return null
}

export const cardCvcErrorMessage = (cardCvc: string) => {
  if (cardCvc === STRIPE_VALUE_INCOMPLETE)
    return <FormattedMessage id="SETTINGS_PAYMENTS_INCOMPLETE_CARD_CVC" />
  if (cardCvc === STRIPE_VALUE_INVALID)
    return <FormattedMessage id="SETTINGS_PAYMENTS_INVALID_CARD_CVC" />
  if (cardCvc === '')
    return <FormattedMessage id="SETTINGS_PAYMENTS_EMPTY_CARD_CVC" />
  return null
}

export const parseMonth = (exp_month?: number | string): string => {
  if (!exp_month) {
    return ''
  }

  const parsedMonth =
    typeof exp_month === 'string' ? parseInt(exp_month) : exp_month

  if (parsedMonth < 10) {
    return `0${parsedMonth}`
  }
  return `${parsedMonth}`
}

export const parseYear = (exp_year?: number | string): string => {
  if (!exp_year) {
    return ''
  }

  const parsedYear =
    typeof exp_year === 'string' ? parseInt(exp_year) : exp_year

  return `${parsedYear % 100}`
}

export const getIsSourceCountryValid = (
  userStripeRegion: StripeRegionCode,
  sourceCountry: StripeCountryCode,
  userCountry: StripeCountryCode
) => {
  if (sourceCountry === userCountry) {
    return true
  }

  if (
    regionsThatAllowDifferentCardCountries.includes(userStripeRegion) &&
    userCountry
  ) {
    const userCountryRegion = validStripeCountryMap.get(userCountry)
    const sourceCountryRegion = validStripeCountryMap.get(sourceCountry)

    return userCountryRegion?.region === sourceCountryRegion?.region
  }
}

export const getErrorMessage = (
  mutationError: Error | null,
  intl: IntlShape,
  regionName?: string
) => {
  const error = getStripeMutationErrorMessage(mutationError)
  if (error?.apiError === PaymentMethodErrors.DUPLICATE_CARD) {
    return intl.formatMessage({ id: 'STRIPE_PAYMENT_ERROR_DUPLICATED_CARD' })
  }
  if (error?.apiError === PaymentMethodErrors.INVALID_COUNTRY) {
    return intl.formatMessage(
      { id: 'STRIPE_PAYMENT_ERROR_UNSUPORTED_CARD' },
      {
        region: regionName,
      }
    )
  }
  if (error?.apiError?.includes(PaymentMethodErrors.SANCTIONED)) {
    return intl.formatMessage(
      { id: 'STRIPE_PAYMENT_ERROR_SANCTIONED' },
      {
        region: regionName,
      }
    )
  }
  if (error?.stripeError?.includes(PaymentMethodErrors.POSTAL_CODE)) {
    return intl.formatMessage({ id: 'STRIPE_PAYMENT_ERROR_POSTAL_CODE' })
  }
  if (error?.stripeError) {
    return error.stripeError
  }
  if (error?.apiError) {
    return intl.formatMessage({ id: 'GENERIC_ERROR_MESSAGE' })
  }
  return null
}

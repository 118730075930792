import React from 'react'
import { ArrowRightPrimaryIcon } from '@ubnt/icons'
import { GridRow, ValueCell } from './Invoices.styles'
import { InvoiceItem } from 'pages/subscriptions/hooks/utils'

type InvoiceGridRowProps = {
  invoice: InvoiceItem
  onClick: (value: string) => void
}

export const InvoiceGridRow: React.FC<InvoiceGridRowProps> = ({
  invoice,
  onClick,
}) => {
  const date = invoice.monthLabel

  return (
    <GridRow onClick={() => onClick(invoice.id)}>
      <ValueCell>{date}</ValueCell>
      <ArrowRightPrimaryIcon className="extra-data" />
    </GridRow>
  )
}

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { BillingCustomerApi } from 'api/billing/billingCustomer'
import { useToasts } from 'pages/payments/components/useToasts'
import { QueryKey } from 'store/types'

const billingCustomerApi = new BillingCustomerApi()

export const useUpdateCustomer = () => {
  const queryClient = useQueryClient()
  const { updateCustomerSuccessToast, updateCustomerErrorToast } = useToasts()

  const updateBillingCustomer = useMutation({
    mutationFn: billingCustomerApi.update,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.BILLING_CUSTOMER] })
      updateCustomerSuccessToast()
    },
    onError: () => updateCustomerErrorToast(),
  })

  return {
    updateBillingCustomer: updateBillingCustomer.mutate,
  }
}

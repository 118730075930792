import React from 'react'
import type Stripe from 'stripe'
import { SubscriptionServiceTableItem } from '../Components.styles'

interface Props {
  plan?: Stripe.Plan | null
}

export const DataLimit: React.FC<Props> = ({ plan }) => {
  const getLimit = () => {
    if (plan?.metadata?.quota && plan?.metadata?.unit) {
      return `${plan?.metadata?.quota} ${plan?.metadata?.unit}`
    }
    if (plan?.nickname?.includes('MONTHLY_')) {
      return plan?.nickname?.replace('MONTHLY_', '')
    }
    return plan?.nickname
  }

  return (
    <SubscriptionServiceTableItem className="extra-data">
      {getLimit()}
    </SubscriptionServiceTableItem>
  )
}

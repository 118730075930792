import { CurrencyRegion } from 'features/invoices/ui/types'
import { FullSubscription } from 'features/subscriptions/fullSubscriptions/useFullSubscriptions'
import { DateTime } from 'luxon'
import { CANCEL_SUBSCRIPTION_LTE_MODAL_ID } from 'pages/subscriptions/components/subscriptionServices/CancelSubscriptionLTEModal'
import { CANCEL_SUBSCRIPTION_SITE_SUPPORT_MODAL_ID } from 'pages/subscriptions/components/subscriptionServices/CancelSubscriptionSiteSupportModal'
import { CANCEL_SUBSCRIPTION_TALK_MODAL_ID } from 'pages/subscriptions/components/subscriptionServices/CancelSubscriptionTalkModal'
import { CANCEL_SUBSCRIPTION_EXT_CLOUD_CONSOLE_MODAL_ID } from 'pages/subscriptions/components/subscriptionServices/ExternalCancelSubscriptionCloudConsoleModal'
import { CANCEL_SUBSCRIPTION_EXT_UISP_CLOUD_CONSOLE_MODAL_ID } from 'pages/subscriptions/components/subscriptionServices/ExternalCancelSubscriptionUispCloudConsoleModal'
import { CANCEL_SUBSCRIPTION_EXT_UMR_MODAL_ID } from 'pages/subscriptions/components/subscriptionServices/ExternalCancelSubscriptionUMRModal'
import { SubscriptionUrl, ProductName } from 'types/enums'
import { SITE_NAME_TO_HIDE } from '../subscriptions/utils'
import { CANCEL_SUBSCRIPTION_CYBERSECURE_MODAL_ID } from './CancelSubscriptionCyberSecureModal'
import { CANCEL_SUBSCRIPTION_MSP_SITE_SUPPORT_MODAL_ID } from './CancelSubscriptionMspSiteSupportModal'

// All subscription services share the columns for billing date
// payment, amount and the more popover
const commonColumnTitles = [
  'SETTINGS_SUBSCRIPTIONS_TABLE_COL_BILLING_DATE',
  'SETTINGS_SUBSCRIPTIONS_TABLE_COL_PAYMENT',
  'SETTINGS_SUBSCRIPTIONS_TABLE_COL_AMOUNT',
  undefined,
]
const commonColumnSizes = '100px 134px 100px 24px'

export const getSubscriptionServiceFromUrl = (url: string) => {
  switch (url) {
    case SubscriptionUrl.UNIFI_TALK: {
      return ProductName.UNIFI_TALK
    }
    case SubscriptionUrl.SITE_SUPPORT: {
      return ProductName.SITE_SUPPORT
    }
    case SubscriptionUrl.MSP_SITE_SUPPORT: {
      return ProductName.MSP_SITE_SUPPORT
    }
    case SubscriptionUrl.ULTE: {
      return ProductName.ULTE
    }
    case SubscriptionUrl.HOSTING: {
      return ProductName.HOSTING
    }
    case SubscriptionUrl.CYBERSECURE: {
      return ProductName.CYBERSECURE
    }
    case SubscriptionUrl.MOBILITY: {
      return ProductName.MOBILITY
    }
    case SubscriptionUrl.UISP_CLOUD: {
      return ProductName.UISP_CLOUD
    }
    default:
      return null
  }
}

export const getSubscriptionCancelModalId = (productLine: string) => {
  switch (productLine) {
    case ProductName.UNIFI_TALK: {
      return CANCEL_SUBSCRIPTION_TALK_MODAL_ID
    }
    case ProductName.MSP_SITE_SUPPORT: {
      return CANCEL_SUBSCRIPTION_MSP_SITE_SUPPORT_MODAL_ID
    }
    case ProductName.SITE_SUPPORT: {
      return CANCEL_SUBSCRIPTION_SITE_SUPPORT_MODAL_ID
    }
    case ProductName.ULTE: {
      return CANCEL_SUBSCRIPTION_LTE_MODAL_ID
    }
    case ProductName.HOSTING: {
      return CANCEL_SUBSCRIPTION_EXT_CLOUD_CONSOLE_MODAL_ID
    }
    case ProductName.CYBERSECURE: {
      return CANCEL_SUBSCRIPTION_CYBERSECURE_MODAL_ID
    }
    case ProductName.MOBILITY: {
      return CANCEL_SUBSCRIPTION_EXT_UMR_MODAL_ID
    }
    case ProductName.UISP_CLOUD: {
      return CANCEL_SUBSCRIPTION_EXT_UISP_CLOUD_CONSOLE_MODAL_ID
    }
    default:
      return null
  }
}

export const getSubscriptionColumns = (productLine: string) => {
  switch (productLine) {
    case ProductName.UNIFI_TALK: {
      return {
        columns: [
          'SETTINGS_SUBSCRIPTIONS_TABLE_COL_SITE_NAME',
          'SETTINGS_SUBSCRIPTIONS_TABLE_COL_QUANTITY',
          ...commonColumnTitles,
        ],
        template: `1fr 80px ${commonColumnSizes}`,
      }
    }
    case ProductName.MSP_SITE_SUPPORT: {
      return {
        columns: [
          'SETTINGS_SUBSCRIPTIONS_TABLE_COL_PRODUCT',
          ...commonColumnTitles,
        ],
        template: `1fr ${commonColumnSizes}`,
      }
    }
    case ProductName.SITE_SUPPORT: {
      return {
        columns: [
          'SETTINGS_SUBSCRIPTIONS_TABLE_COL_DEVICE_NAME',
          'SETTINGS_SUBSCRIPTIONS_TABLE_COL_DEVICE',
          ...commonColumnTitles,
        ],
        template: `1fr 120px ${commonColumnSizes}`,
      }
    }
    case ProductName.ULTE: {
      return {
        columns: [
          'SETTINGS_SUBSCRIPTIONS_TABLE_COL_UNIFI_CONSOLE',
          ...commonColumnTitles,
        ],
        template: `1fr ${commonColumnSizes}`,
      }
    }
    case ProductName.HOSTING: {
      return {
        columns: [
          'SETTINGS_SUBSCRIPTIONS_TABLE_COL_UNIFI_DEVICES',
          'SETTINGS_SUBSCRIPTIONS_TABLE_COL_QUANTITY',
          ...commonColumnTitles,
        ],
        template: `1fr 80px ${commonColumnSizes}`,
      }
    }
    case ProductName.CYBERSECURE: {
      return {
        columns: [
          'SETTINGS_SUBSCRIPTIONS_TABLE_COL_DEVICE_NAME',
          ...commonColumnTitles,
        ],
        template: `1fr ${commonColumnSizes}`,
      }
    }
    case ProductName.MOBILITY: {
      return {
        columns: [
          'SETTINGS_SUBSCRIPTIONS_TABLE_COL_NAME_LOC',
          'SETTINGS_SUBSCRIPTIONS_TABLE_COL_DATA_LIMIT',
          ...commonColumnTitles,
        ],
        template: `1fr 120px ${commonColumnSizes}`,
      }
    }
    case ProductName.UISP_CLOUD: {
      return {
        columns: [
          'SETTINGS_SUBSCRIPTIONS_TABLE_COL_PLAN',
          'SETTINGS_SUBSCRIPTIONS_TABLE_COL_QUANTITY',
          ...commonColumnTitles,
        ],
        template: `1fr 80px ${commonColumnSizes}`,
      }
    }
    default:
      return null
  }
}

export const getFormattedDate = (date: number) => {
  return DateTime.fromSeconds(date).toFormat('MMM d, yyyy')
}

export const getCurrencySymbol = (
  nameOrRegion: CurrencyRegion | string
): string => {
  switch (nameOrRegion) {
    case CurrencyRegion.GB:
    case CurrencyRegion.GBP:
      return '£'
    case CurrencyRegion.EU:
    case CurrencyRegion.EUR:
      return '€'
    case CurrencyRegion.JPY:
      return '¥'
    default:
      return '$'
  }
}

export const getSearchedSubscriptions = (
  subscriptions: FullSubscription[],
  group: string,
  searchTerm: string
) => {
  const searchValue = searchTerm.toLowerCase()
  return subscriptions.filter((subscription) => {
    if (group === ProductName.CYBERSECURE) {
      return (
        (subscription.metadata?.site_name.toLowerCase().includes(searchValue) &&
          subscription.metadata?.site_name !== SITE_NAME_TO_HIDE) ||
        subscription.metadata?.device_name
          .toLowerCase()
          .includes(searchValue) ||
        subscription.metadata?.device_shortname
          .toLowerCase()
          .includes(searchValue)
      )
    }
    if (group === ProductName.UNIFI_TALK) {
      return (
        subscription.metadata?.ui_device_name
          .toLowerCase()
          .includes(searchValue) ||
        subscription.name.toLowerCase().includes(searchValue)
      )
    }
    if (group === ProductName.SITE_SUPPORT) {
      return subscription.metadata?.deviceName
        .toLowerCase()
        .includes(searchValue)
    }
    if (group === ProductName.ULTE) {
      return (
        (subscription.metadata?.site.toLowerCase().includes(searchValue) &&
          subscription.metadata?.site !== SITE_NAME_TO_HIDE) ||
        subscription.metadata?.controller.toLowerCase().includes(searchValue) ||
        subscription.name.toLowerCase().includes(searchValue)
      )
    }
    if (group === ProductName.MOBILITY) {
      const umrData = subscription.deviceUmrInfo?.find(
        (umr) => umr.stripe_id === subscription.id
      )
      return (
        umrData?.device.name.toLowerCase().includes(searchValue) ||
        subscription.metadata?.ui_device_name
          .toLowerCase()
          .includes(searchValue) ||
        subscription.name.toLowerCase().includes(searchValue)
      )
    }
    // this is just the fallback
    return []
  })
}

import styled from 'theme/styled'
import { typography } from 'theme/typography'

export const StatusPill = styled.span`
  display: flex;
  width: 90px;
  justify-content: center;
  align-items: center;
  font: ${typography['mini-caption']};
  padding: 2px 8px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.neutral03};
  color: ${({ theme }) => theme.neutral08};
  text-align: center;

  &.open {
    background-color: ${({ theme }) => theme.ublue01};
    color: ${({ theme }) => theme.ublue06};
  }

  &.answered {
    position: relative;
    background-color: ${({ theme }) => theme.green01};
    color: ${({ theme }) => theme.green06};
  }
  /* Adding the blue dot on Answered label */
  &.answered::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 7px;
    height: 7px;
    background-color: ${({ theme }) => theme.ublue06};
    border-radius: 50%;
  }
`

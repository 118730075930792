import React, { useMemo } from 'react'
import {
  CardImg,
  SubscriptionServiceTableItem,
  WarningIcon,
} from '../Components.styles'
import { useBillingCardsQuery } from 'store/queries/useBillingCardsQuery'
import { useBillingCustomerQuery } from 'store/queries/useBillingCustomerQuery'
import { FullSubscription } from 'features/subscriptions/fullSubscriptions/useFullSubscriptions'
import { ProductName } from 'types/enums'
import { Text } from '@ubnt/ui-components/aria'
import { getPaymentMethodIcon } from 'pages/payments/utils'
import { FormattedMessage } from 'react-intl'

interface Props {
  subscription: FullSubscription
  group: string
}

export const PaymentMethod: React.FC<Props> = ({ subscription, group }) => {
  const { customerCards } = useBillingCardsQuery()
  const { customerDefaultCards } = useBillingCustomerQuery()

  const {
    isFailed,
    isSuspended,
    default_payment_method,
    default_source,
    cancelAtPeriodEnd,
  } = subscription

  const defaultCard = useMemo(() => {
    const { region } = subscription
    const regionDefault = customerDefaultCards && customerDefaultCards[region]
    return regionDefault
      ? customerCards.find((card) => card.id === regionDefault)
      : undefined
  }, [customerCards, customerDefaultCards, subscription])

  const selectedCardId = default_payment_method ?? default_source

  const selectedCard = useMemo(
    () => customerCards.find((card) => card.id === selectedCardId),
    [customerCards, selectedCardId]
  )

  const isTalk = group === ProductName.UNIFI_TALK

  const cardToDisplay = isTalk ? defaultCard : selectedCard

  if (cancelAtPeriodEnd) {
    return (
      <SubscriptionServiceTableItem className="extra-data">
        -
      </SubscriptionServiceTableItem>
    )
  }

  return (
    <SubscriptionServiceTableItem className="extra-data">
      <CardImg
        src={getPaymentMethodIcon(cardToDisplay?.card?.brand ?? 'Unknown')}
      />
      <Text color={isFailed || isSuspended ? 'red-06' : 'text-2'}>
        {cardToDisplay ? (
          `····${cardToDisplay.card?.last4}`
        ) : (
          <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_NO_CHOSEN_CARD" />
        )}
      </Text>
      {(isFailed || isSuspended) && <WarningIcon variant="fill" size={20} />}
    </SubscriptionServiceTableItem>
  )
}

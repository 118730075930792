import {
  typography as UICTypography,
  border,
  opacity,
  radius,
  shadow,
  size,
  space,
  timing,
} from '@ubnt/ui-components/styles/designToken'

import { MotifPaletteType, dark, light } from './UICPalette'
import { typography as customTypography } from './typography'

const motifPalette: Record<'light' | 'dark', MotifPaletteType> = { light, dark }

const media = {
  mobileSmall: '320px',
  mobileMedium: '375px',
  mobileLarge: '450px',
  mobileXLarge: '564px',
  xSmall: '698px',
  small: '767px',
  tablet: '834px',
  tabletLarge: '970px',
  mediumV2: '992px',
  medium: '1024px',
  large: '1200px',
  xlarge: '1920px',
}

const typography = {
  ...UICTypography,
  ...customTypography,
}

export {
  motifPalette,
  size,
  space,
  typography,
  opacity,
  shadow,
  radius,
  timing,
  border,
  media,
}

export default { media, motifPalette }

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ChatApi } from 'api/chat'
import { QueryKey } from 'store/types'

const chatApi = new ChatApi()

export const useUpdateChatAdminSchedule = () => {
  const queryClient = useQueryClient()

  const updateChatAdminSchedule = useMutation({
    mutationFn: chatApi.updateAdminScheduleOverride,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.CHAT_ADMIN_SCHEDULE],
      })
      queryClient.invalidateQueries({
        queryKey: [QueryKey.CHAT_SCHEDULE],
      })
    },
  })

  return {
    updateChatAdminSchedule: updateChatAdminSchedule.mutate,
  }
}

import InfoIcon from '@ubnt/icons/InfoIcon'
import MoreVerticalIcon from '@ubnt/icons/MoreVerticalIcon'
import { Button, Dropdown } from '@ubnt/ui-components'
import { Text } from '@ubnt/ui-components/aria'
import { InfoContainer } from 'components/SharedComponents'
import { media } from 'theme'
import styled from 'theme/styled'

const CardListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`

const TextWrap = styled.div`
  width: 100%;

  @media (max-width: ${media.xSmall}) {
    margin-bottom: 4px;
  }
`

const TitleSection = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  @media (max-width: ${media.xSmall}) {
    margin-bottom: 8px;
  }
`

const TaxTitleSection = styled(TitleSection)`
  @media (max-width: ${media.xSmall}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const FilterDropdown = styled(Dropdown)`
  .filter-input-container {
    > :last-child {
      margin: 0 4px;
    }
  }

  .filter-input {
    text-align: right;
  }
`

const CardsGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 140px 120px 158px 28px;

  @media (max-width: ${media.xSmall}) {
    grid-template-columns: 1fr 28px;

    .extra-data {
      display: none;
    }
  }
`

const CardsTableItem = styled(Text)`
  height: 32px;
  padding: 6px 0;
  border-bottom: ${({ theme }) => `1px solid ${theme.neutral03}`};
`

const CardName = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 4px;
`

const EmptyCardsTableItem = CardsTableItem

const MethodIcon = styled.img`
  width: 20px;
  border-radius: 4px;
`

const CardInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

const DefaultPill = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  padding: 2px 8px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.aqua01};
  color: ${({ theme }) => theme.ublue06};
  font-size: 10px;
  line-height: 16px;
  font-weight: 700;
`

const CardCountry = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

const PopoverIcon = styled(MoreVerticalIcon)`
  color: ${({ theme }) => theme.neutral08};
  cursor: pointer;
`

const PopoverButton = styled(Button)`
  text-align: left;
  justify-content: flex-start;
  border-radius: 0;
`

const StyledInfoIcon = styled(InfoIcon)`
  margin: -3px 0 0 3px;
`

const StyledInfoContainer = styled(InfoContainer)`
  margin-top: 16px;
`

const TaxData = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-right: 24px;

  img {
    width: 18px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  @media (max-width: ${media.xSmall}) {
    margin-top: 8px;
  }
`

export {
  TextWrap,
  TitleSection,
  TaxTitleSection,
  FilterDropdown,
  CardListContainer,
  CardsGrid,
  CardsTableItem,
  EmptyCardsTableItem,
  MethodIcon,
  CardInfo,
  DefaultPill,
  CardCountry,
  PopoverIcon,
  PopoverButton,
  StyledInfoIcon,
  StyledInfoContainer,
  TaxData,
  ButtonContainer,
  CardName,
}

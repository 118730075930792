import React, { useContext, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { Button, Input } from '@ubnt/ui-components'
import type Stripe from 'stripe'
import { FormikErrors, FormikTouched } from 'formik'
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js'
import { CountryFlag } from 'components/CountryFlag'
import { validStripeRegionMap } from 'features/stripe/ui/regions'
import { StripeRegionCode } from 'features/stripe/ui/types'
import { setVisibleModal } from 'modules/modals'
import { MotifContext } from 'motif/MotifProvider'
import { ValidationError } from 'components/Input'
import { CHANGE_REGION_MODAL_ID } from '../ChangeRegionModal'
import CardProviderOverlay from '../CardProviderOverlay'
import {
  Country,
  CountryTooltip,
  ElementWrapper,
  HalfFields,
  InputWrapper,
  Label,
  SectionWrapper,
  StripeErrors,
  StripeInputWrapper,
  StyledCardCVCElement,
  StyledCardExpiryElement,
  StyledCardNumberElement,
  Title,
} from '../Components'
import {
  cardCvcErrorMessage,
  cardExpiryErrorMessage,
  cardNumberErrorMessage,
  parseMonth,
  parseYear,
  stripeStyles,
  stripeStylesDark,
} from '../utils'
import {
  PAST_EXPIRY,
  STRIPE_VALUE_COMPLETE,
  STRIPE_VALUE_EMPTY,
  STRIPE_VALUE_INCOMPLETE,
  STRIPE_VALUE_INVALID,
} from '../common'
import { AddCardValues, CardValues } from './CardAddressForm'
import { ExpiryDate } from '../ExpiryDate'

enum CardFields {
  NUMBER = 'NUMBER',
  EXP = 'EXP',
  CVC = 'CVC',
}

interface FormProps {
  handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  values: CardValues
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void> | Promise<FormikErrors<CardValues>>
  touched: FormikTouched<CardValues>
  setFieldTouched?: (
    field: string,
    touched?: boolean,
    shouldValidate?: boolean
  ) => Promise<FormikErrors<CardValues>> | Promise<void>
  errors: FormikErrors<CardValues>
  cardRegion: StripeRegionCode
  subscriptionRegion?: StripeRegionCode
  createError?: string | null
  resetCreateError?: () => void
  editError?: string | null
  cardData?: Stripe.PaymentMethod.Card
  expiryDateError?: boolean
  setExpiryDateError?: React.Dispatch<React.SetStateAction<boolean>>
}

function isAddCardValues(item: CardValues): item is AddCardValues {
  return typeof (item as AddCardValues).cardNumber === 'string'
}

function isTouchedAddCardValues(
  item: FormikTouched<CardValues>
): item is FormikTouched<AddCardValues> {
  return typeof (item as FormikTouched<AddCardValues>).cardNumber === 'string'
}

function isErrorAddCardValues(
  item: FormikErrors<CardValues>
): item is FormikErrors<AddCardValues> {
  return typeof (item as FormikErrors<AddCardValues>).cardNumber === 'string'
}

export const CardInfoForm: React.FC<FormProps> = ({
  handleBlur,
  values,
  handleChange,
  touched,
  setFieldTouched,
  errors,
  cardRegion,
  subscriptionRegion,
  createError,
  cardData,
  editError,
  setFieldValue,
  resetCreateError,
  expiryDateError,
  setExpiryDateError,
}) => {
  const [cardBrand, setCardBrand] = useState<string | undefined>(undefined)
  const [focusedCardElement, setFocusedCardElement] = useState<
    CardFields | undefined
  >(undefined)
  const dispatch = useDispatch()
  const { motif } = useContext(MotifContext)
  const isEditPaymentMethod = !!cardData
  const cardNumberPlaceholder = `\u2022\u2022\u2022\u2022 \u2022\u2022\u2022\u2022 \u2022\u2022\u2022\u2022 ${cardData?.last4}`

  const selectedRegion = cardRegion
    ? validStripeRegionMap.get(cardRegion)
    : undefined

  const handleChangeRegion = () => {
    dispatch(
      setVisibleModal(CHANGE_REGION_MODAL_ID, {
        notSupported: false,
      })
    )
  }

  type StripeFormKey = 'cardNumber' | 'cardExpiry' | 'cardCvc' | 'cardCountry'

  const isCardFieldError = (key: StripeFormKey) => {
    if (!isAddCardValues(values) || !isTouchedAddCardValues(touched)) return
    return (
      (touched[key] && values[key] !== STRIPE_VALUE_COMPLETE) ||
      (touched[key] && values[key] === STRIPE_VALUE_INCOMPLETE) ||
      values[key] === STRIPE_VALUE_INVALID
    )
  }

  const handleStripeChange = (
    e:
      | StripeCardNumberElementChangeEvent
      | StripeCardExpiryElementChangeEvent
      | StripeCardCvcElementChangeEvent
  ) => {
    if (!isAddCardValues(values)) return

    resetCreateError?.()
    const formikValues = [
      values.cardNumber,
      values.cardExpiry,
      values.cardCvc,
      values.cardCountry,
    ]
    const invalidErrors = [
      'invalid_number',
      'invalid_expiry_year',
      'invalid_expiry_year_past',
    ]
    const incompleteErrors = [
      'incomplete_number',
      'incomplete_cvc',
      'incomplete_expiry',
    ]

    if (!e.error && e.complete) {
      setFieldValue(e.elementType, STRIPE_VALUE_COMPLETE)
    }
    if (!e.error && e.empty) {
      setFieldValue(e.elementType, STRIPE_VALUE_EMPTY)
    }
    if (
      !e.error &&
      !e.complete &&
      !e.empty &&
      (formikValues.includes(STRIPE_VALUE_COMPLETE) ||
        formikValues.includes(STRIPE_VALUE_INVALID))
    ) {
      setFieldValue(e.elementType, STRIPE_VALUE_INCOMPLETE)
    }
    if (e.error?.code && invalidErrors.includes(e.error.code)) {
      e.error.code === 'invalid_expiry_year_past'
        ? setFieldValue(e.elementType, PAST_EXPIRY)
        : setFieldValue(e.elementType, STRIPE_VALUE_INVALID)
    }
    if (e.error?.code && incompleteErrors.includes(e.error.code)) {
      setFieldValue(e.elementType, STRIPE_VALUE_INCOMPLETE)
    }
  }

  const handleStripeElementTouch = (field: string) => {
    setFieldTouched?.(field, true)
  }

  const customStripeStyles = motif === 'dark' ? stripeStylesDark : stripeStyles

  return (
    <>
      <SectionWrapper>
        <Title>
          <FormattedMessage id="SETTINGS_PAYMENTS_CARD_REGION" />
        </Title>
        {selectedRegion && (
          <InputWrapper>
            <CountryTooltip
              description={
                <FormattedMessage id="SETTINGS_PAYMENTS_CARD_REGION_TOOLTIP" />
              }
              isOpen={subscriptionRegion ? undefined : false}
              position="topRight"
              tooltipClassName="country-toolip"
            >
              <Country disabled={!!subscriptionRegion || isEditPaymentMethod}>
                <div>
                  <CountryFlag
                    countryCode={selectedRegion.code}
                    size="small"
                    noMargin
                  />
                  <span className="country-name">{selectedRegion.name}</span>
                </div>
                {!isEditPaymentMethod && (
                  <Button
                    variant="link"
                    onClick={handleChangeRegion}
                    disabled={!!subscriptionRegion}
                  >
                    <FormattedMessage id="COMMON_ACTION_CHANGE" />
                  </Button>
                )}
              </Country>
            </CountryTooltip>
          </InputWrapper>
        )}
      </SectionWrapper>
      <SectionWrapper>
        <Title>
          <FormattedMessage id="SETTINGS_PAYMENTS_CARD_INFORMATION" />
        </Title>
        <StripeInputWrapper>
          <Label
            focus={
              !isEditPaymentMethod && focusedCardElement === CardFields.NUMBER
            }
            error={!isEditPaymentMethod && isCardFieldError('cardNumber')}
          >
            <FormattedMessage id="SETTINGS_PAYMENTS_LABEL_CARD_NUMBER" />
          </Label>
          <CardProviderOverlay
            cardBrand={isEditPaymentMethod ? cardData.brand : cardBrand}
            invalidCard={
              isAddCardValues(values)
                ? values.cardNumber === STRIPE_VALUE_INVALID
                : false
            }
            disabled={isEditPaymentMethod}
          >
            <ElementWrapper
              focus={
                !isEditPaymentMethod && focusedCardElement === CardFields.NUMBER
              }
              hasError={!isEditPaymentMethod && isCardFieldError('cardNumber')}
            >
              <StyledCardNumberElement
                onChange={(e) => {
                  setCardBrand(e.brand)
                  handleStripeChange(e)
                }}
                onBlur={() => {
                  handleStripeElementTouch('cardNumber')
                  setFocusedCardElement(undefined)
                }}
                options={{
                  style: customStripeStyles,
                  classes: {
                    base:
                      isAddCardValues(values) &&
                      isTouchedAddCardValues(touched) &&
                      touched.cardNumber &&
                      values.cardNumber !== STRIPE_VALUE_COMPLETE
                        ? 'StripeElement invalid-value'
                        : undefined,
                  },
                  disabled: isEditPaymentMethod,
                  placeholder: isEditPaymentMethod
                    ? cardNumberPlaceholder
                    : undefined,
                }}
                onFocus={() => setFocusedCardElement(CardFields.NUMBER)}
                $invalid={!!createError}
                disabled={isEditPaymentMethod}
              />
            </ElementWrapper>
          </CardProviderOverlay>
          <StripeErrors>
            {isAddCardValues(values) &&
              isErrorAddCardValues(errors) &&
              isCardFieldError('cardNumber') && (
                <ValidationError>
                  {cardNumberErrorMessage(values.cardNumber) ||
                    errors.cardNumber}
                </ValidationError>
              )}
          </StripeErrors>
        </StripeInputWrapper>
        <HalfFields>
          <StripeInputWrapper>
            <Label
              focus={
                !isEditPaymentMethod && focusedCardElement === CardFields.EXP
              }
              error={
                !isEditPaymentMethod &&
                isAddCardValues(values) &&
                (isCardFieldError('cardExpiry') ||
                  values.cardExpiry === PAST_EXPIRY)
              }
            >
              <FormattedMessage id="SETTINGS_PAYMENTS_LABEL_EXPIRATION_DATE" />
            </Label>
            <ElementWrapper
              focus={focusedCardElement === CardFields.EXP}
              hasError={
                !isEditPaymentMethod &&
                isAddCardValues(values) &&
                (isCardFieldError('cardExpiry') ||
                  values.cardExpiry === PAST_EXPIRY)
              }
            >
              {isEditPaymentMethod && !isAddCardValues(values) ? (
                <ExpiryDate
                  value={{
                    expMonth: values.exp_month,
                    expYear: values.exp_year,
                  }}
                  onChange={setFieldValue}
                  placeholder={{
                    expMonth: parseMonth(cardData?.exp_month),
                    expYear: parseYear(cardData?.exp_year),
                  }}
                  expiryDateError={expiryDateError}
                  setExpiryDateError={setExpiryDateError}
                />
              ) : (
                <StyledCardExpiryElement
                  onChange={(e) => {
                    handleStripeChange(e)
                  }}
                  onBlur={() => {
                    handleStripeElementTouch('cardExpiry')
                    setFocusedCardElement(undefined)
                  }}
                  onFocus={() => setFocusedCardElement(CardFields.EXP)}
                  options={{
                    style: customStripeStyles,
                    classes: {
                      base:
                        isAddCardValues(values) &&
                        isTouchedAddCardValues(touched) &&
                        touched.cardNumber &&
                        values.cardNumber !== STRIPE_VALUE_COMPLETE
                          ? 'StripeElement invalid-value'
                          : undefined,
                    },
                  }}
                  $invalid={!!createError}
                />
              )}
            </ElementWrapper>
            <StripeErrors>
              {isAddCardValues(values) &&
                isErrorAddCardValues(errors) &&
                (isCardFieldError('cardExpiry') ||
                  values.cardExpiry === PAST_EXPIRY) && (
                  <ValidationError>
                    {cardExpiryErrorMessage(values.cardExpiry) ||
                      errors.cardExpiry}
                  </ValidationError>
                )}
            </StripeErrors>
          </StripeInputWrapper>
          <StripeInputWrapper>
            <Label
              focus={
                !isEditPaymentMethod && focusedCardElement === CardFields.CVC
              }
              error={!isEditPaymentMethod && isCardFieldError('cardCvc')}
            >
              <FormattedMessage id="SETTINGS_PAYMENTS_LABEL_SECURITY_CODE" />
            </Label>
            <ElementWrapper
              focus={focusedCardElement === CardFields.CVC}
              hasError={isCardFieldError('cardCvc')}
            >
              <StyledCardCVCElement
                onChange={handleStripeChange}
                onBlur={() => {
                  handleStripeElementTouch('cardCvc')
                  setFocusedCardElement(undefined)
                }}
                onFocus={() => setFocusedCardElement(CardFields.CVC)}
                options={{
                  style: customStripeStyles,
                  classes: {
                    base:
                      isAddCardValues(values) &&
                      isTouchedAddCardValues(touched) &&
                      touched.cardNumber &&
                      values.cardNumber !== STRIPE_VALUE_COMPLETE
                        ? 'StripeElement invalid-value'
                        : undefined,
                  },
                  placeholder: isEditPaymentMethod
                    ? '\u2022\u2022\u2022'
                    : undefined,
                  disabled: isEditPaymentMethod,
                }}
                $invalid={!!createError}
                disabled={isEditPaymentMethod}
              />
            </ElementWrapper>
            <StripeErrors>
              {isAddCardValues(values) &&
                isErrorAddCardValues(errors) &&
                isCardFieldError('cardCvc') && (
                  <ValidationError>
                    {cardCvcErrorMessage(values.cardCvc) || errors.cardCvc}
                  </ValidationError>
                )}
            </StripeErrors>
          </StripeInputWrapper>
        </HalfFields>
        <Input
          id="name"
          name="name"
          autoComplete="new-name"
          variant="secondary"
          label={<FormattedMessage id="SETTINGS_PAYMENTS_NAME_ON_CARD" />}
          onChange={handleChange}
          onBlur={handleBlur}
          invalid={
            (touched.name && errors.name) || !!createError || !!editError
          }
          value={values.name}
          full
        />
        <StripeErrors></StripeErrors>
      </SectionWrapper>
    </>
  )
}

import React from 'react'
import {
  TruncatedSubscriptionDeviceName,
  TruncatedText,
} from '../Components.styles'
import { Location } from 'api/umrDevice'
import { SITE_NAME_TO_HIDE } from '../../subscriptions/utils'
import { SubscriptionWarning } from '../../subscriptions/SubscriptionWarning'

interface Props {
  siteName?: string | null
  deviceName?: string | null
  fallback?: string | null
  location?: Location
  className?: string
  isFailed: boolean
  cardNotFound: boolean
}

export const DeviceName: React.FC<Props> = ({
  siteName,
  deviceName,
  fallback,
  location,
  className,
  isFailed,
  cardNotFound,
}) => {
  const getDeviceName = () => {
    if (siteName && deviceName && siteName !== SITE_NAME_TO_HIDE) {
      return `${deviceName} - ${siteName}`
    }

    if (deviceName && location?.region && location?.region_code) {
      return `${deviceName}, ${getLocation()}`
    }

    if (deviceName) {
      return deviceName
    }

    return fallback ?? '-'
  }

  const getLocation = () => `${location?.region}, ${location?.region_code}`

  const displayWarningIcon = isFailed || cardNotFound

  return (
    <TruncatedSubscriptionDeviceName
      className={className}
      $hasIcon={displayWarningIcon}
    >
      {displayWarningIcon && (
        <SubscriptionWarning
          message={
            isFailed
              ? 'SETTINGS_SUBSCRIPTIONS_TOOLTIP_WARNING'
              : 'SETTINGS_SUBSCRIPTIONS_PAYMENT_CARD_NOT_FOUND'
          }
        />
      )}
      <TruncatedText>{getDeviceName()}</TruncatedText>
    </TruncatedSubscriptionDeviceName>
  )
}

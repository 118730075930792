import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import styled from 'theme/styled'
import { GenericModal } from 'components/generic-modal/GenericModal'
import { useHistory } from 'react-router-dom'
import { FullSubscription } from 'features/subscriptions/fullSubscriptions/useFullSubscriptions'
import { useCancelCyberSecureSubscription } from 'store/mutations/billingSubscriptions/useCancelCyberSecureSubscription'

interface Props extends ModalProps {
  fullSubscription?: FullSubscription
}

const CancelSubscriptionCyberSecureModal: React.FC<Props> = ({
  isOpen,
  onClose,
  fullSubscription,
}) => {
  const history = useHistory()
  const {
    cancelCyberSecureSubscription,
    isCancelCyberSecureSubscriptionLoading,
    cancelCyberSecureSubscriptionErrors,
    resetCancelCyberSecureSubscriptionErrors,
  } = useCancelCyberSecureSubscription()

  const error = useMemo(() => {
    if (
      (cancelCyberSecureSubscriptionErrors &&
        Object.values(cancelCyberSecureSubscriptionErrors as Error).length) ||
      typeof cancelCyberSecureSubscriptionErrors === 'string'
    ) {
      return <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CANCEL_ERROR" />
    } else if (!fullSubscription) {
      return <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_FETCH_ERROR" />
    } else {
      return null
    }
  }, [cancelCyberSecureSubscriptionErrors, fullSubscription])

  const onConfirm = async () => {
    if (error) {
      return history.push('/supportform')
    } else {
      // 'rule-set-pro' is necessary as a fallback to cancel subscriptions made before the subscribeItem was added to the metadata in Stripe
      const item = fullSubscription?.metadata?.subscribeItem ?? 'rule_set_pro'

      fullSubscription &&
        cancelCyberSecureSubscription({ id: fullSubscription.id, item })
    }
  }

  return (
    <GenericModal
      isOpen={isOpen}
      onRequestClose={() => {
        onClose?.()
      }}
      title={<Title />}
      size="small"
      onAfterClose={() => resetCancelCyberSecureSubscriptionErrors()}
      actions={[
        {
          text: <FormattedMessage id="COMMON_ACTION_CANCEL" />,
          onClick: onClose,
        },
        {
          text: error ? (
            <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CONTACT_SUPPORT" />
          ) : (
            <FormattedMessage id="COMMON_ACTION_CONFIRM" />
          ),
          variant: 'primary',
          onClick: onConfirm,
          loader: isCancelCyberSecureSubscriptionLoading ? 'dots' : undefined,
          type: 'submit',
          disabled: isCancelCyberSecureSubscriptionLoading,
        },
      ]}
    >
      {error ? (
        <ErrorMessage>{error}</ErrorMessage>
      ) : (
        <FormattedMessage
          id="SETTINGS_SUBSCRIPTIONS_CANCEL_SUBSCRIPTION_MESSAGE"
          values={{ name: fullSubscription?.name }}
          tagName="div"
        />
      )}
    </GenericModal>
  )
}

export const CANCEL_SUBSCRIPTION_CYBERSECURE_MODAL_ID =
  'CANCEL_SUBSCRIPTION_CYBERSECURE_MODAL_ID'

const WrappedCancelSubscriptionCyberSecureUpdatesModal = () => (
  <ModalWrapper modalId={CANCEL_SUBSCRIPTION_CYBERSECURE_MODAL_ID}>
    <CancelSubscriptionCyberSecureModal />
  </ModalWrapper>
)

export default WrappedCancelSubscriptionCyberSecureUpdatesModal

const Title = () => (
  <TitleWrapper>
    <FormattedMessage
      id="SETTINGS_SUBSCRIPTIONS_CANCEL_SUBSCRIPTION"
      tagName="div"
    />
  </TitleWrapper>
)

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.red06};
`

import { DeviceBackupsWithShadow, DeviceBackupsArchived } from 'api/nca/types'

export enum SortState {
  ASC_NAME = 'asc-name',
  DESC_NAME = 'desc-name',
}

export enum TabState {
  ACTIVE = 'active',
  ARCHIVED = 'archived',
}

export type MixedDeviceBackups = DeviceBackupsWithShadow | DeviceBackupsArchived

export const isActiveDevice = (
  device: MixedDeviceBackups
): device is DeviceBackupsWithShadow => {
  return (device as DeviceBackupsWithShadow).shadow !== undefined
}

import React from 'react'
import { SkeletonLoader } from '@ubnt/ui-components/SkeletonLoader'
import Block from '@ubnt/ui-components/SkeletonLoader/Skeleton/Block'

import { Section } from 'components/Section.styles'
import { PageHeader, HeaderWrapper } from './components/Backups.styles'
import Table from '@ubnt/ui-components/SkeletonLoader/Skeleton/Table'

const BackupsSkeleton = () => (
  <Section $omitMarginTop>
    <PageHeader>
      <HeaderWrapper>
        <SkeletonLoader width={240}>
          <Block width={240} height={26} />
        </SkeletonLoader>
      </HeaderWrapper>
    </PageHeader>
    <SkeletonLoader>
      <Table colCounts={4} />
    </SkeletonLoader>
  </Section>
)

export default BackupsSkeleton

import React, { useCallback, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'theme/styled'
import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import { GenericModal } from 'components/generic-modal/GenericModal'
import { useHistory } from 'react-router'
import { FullSubscription } from 'features/subscriptions/fullSubscriptions/useFullSubscriptions'
import { useCancelMspSiteSupportSubscription } from 'store/mutations/billingSubscriptions/useCancelMspSiteSupport'

interface Props extends ModalProps {
  fullSubscription?: FullSubscription
}

const CancelSubscriptionMspSiteSupportModal: React.FC<Props> = ({
  isOpen,
  onClose,
  fullSubscription,
}) => {
  const history = useHistory()
  const {
    cancelMspSiteSupportSubscription,
    isCancelMspSiteSupportSubscriptionLoading,
    cancelMspSiteSupportSubscriptionErrors,
    resetCancelMspSiteSupportSubscriptionErrors,
  } = useCancelMspSiteSupportSubscription()

  const error = useMemo(() => {
    if (
      cancelMspSiteSupportSubscriptionErrors &&
      (Object.values(cancelMspSiteSupportSubscriptionErrors as Error).length ||
        typeof cancelMspSiteSupportSubscriptionErrors === 'string')
    ) {
      return <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CANCEL_ERROR" />
    } else if (!fullSubscription) {
      return <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_FETCH_ERROR" />
    } else {
      return null
    }
  }, [cancelMspSiteSupportSubscriptionErrors, fullSubscription])

  const onConfirm = useCallback(() => {
    if (error) {
      history.push('/supportform')
    } else {
      if (!fullSubscription) {
        return
      }

      cancelMspSiteSupportSubscription({
        id: fullSubscription.id,
      })
    }
  }, [error, history, fullSubscription, cancelMspSiteSupportSubscription])

  return (
    <GenericModal
      isOpen={isOpen}
      onRequestClose={onClose}
      title={<Title />}
      size="small"
      onAfterClose={() => resetCancelMspSiteSupportSubscriptionErrors()}
      actions={[
        {
          text: <FormattedMessage id="COMMON_ACTION_CANCEL" />,
          onClick: onClose,
        },
        {
          text: error ? (
            <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CONTACT_SUPPORT" />
          ) : (
            <FormattedMessage id="COMMON_ACTION_CONFIRM" />
          ),
          variant: 'primary',
          onClick: onConfirm,
          loader: isCancelMspSiteSupportSubscriptionLoading
            ? 'dots'
            : undefined,
          type: 'submit',
          disabled: isCancelMspSiteSupportSubscriptionLoading,
        },
      ]}
    >
      {error ? (
        <ErrorMessage>{error}</ErrorMessage>
      ) : (
        <FormattedMessage
          id="SETTINGS_SUBSCRIPTIONS_CANCEL_SUBSCRIPTION_MESSAGE"
          values={{ name: fullSubscription?.name }}
          tagName="div"
        />
      )}
    </GenericModal>
  )
}

export const CANCEL_SUBSCRIPTION_MSP_SITE_SUPPORT_MODAL_ID =
  'CANCEL_SUBSCRIPTION_MSP_SITE_SUPPORT_MODAL_ID'

const WrappedCancelSubscriptionMspSiteSupportModal = () => (
  <ModalWrapper modalId={CANCEL_SUBSCRIPTION_MSP_SITE_SUPPORT_MODAL_ID}>
    <CancelSubscriptionMspSiteSupportModal />
  </ModalWrapper>
)

export default WrappedCancelSubscriptionMspSiteSupportModal

const Title = () => (
  <TitleWrapper>
    <FormattedMessage
      id="SETTINGS_SUBSCRIPTIONS_CANCEL_SUBSCRIPTION"
      tagName="div"
    />
  </TitleWrapper>
)

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const ErrorMessage = styled.div`
  color: ${(p) => p.theme.red06};
`

import { config } from './config'
import { AddMFAOptions } from './types'

type MfaOptionsData = {
  hasVerifyCountryLimitation: boolean
  isPasskeyAvailable?: boolean
}

export const getMfaOptions = ({
  hasVerifyCountryLimitation,
  isPasskeyAvailable,
}: MfaOptionsData): AddMFAOptions => {
  const { email, totp, uiVerify, webAuthn } = config

  const isUiVerifyRecommended =
    !hasVerifyCountryLimitation && !isPasskeyAvailable

  const isPasskeyRecommended = isPasskeyAvailable

  if (isPasskeyRecommended) {
    return {
      featured: webAuthn,
      other: [uiVerify, totp, email],
    }
  }

  if (isUiVerifyRecommended) {
    return {
      featured: uiVerify,
      other: [totp, webAuthn, email],
    }
  }

  return {
    featured: email,
    other: [uiVerify, totp, webAuthn],
  }
}

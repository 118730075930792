import React from 'react'
import { LogoProps } from '@ubnt/icons/typings-index-template'
import { ProductName, SubscriptionUrl } from 'types/enums'
import styled from 'theme/styled'
import NetworkLogo from '@ubnt/icons/NetworkLogo'
import TalkLogo from '@ubnt/icons/TalkLogo'
import UispLogo from '@ubnt/icons/UispLogo'
import SiteSupportLogo from '@ubnt/icons/SiteSupportLogo'
import InternetShieldIcon from '@ubnt/icons/InternetShieldIcon'
import MobileHotspotLogo from '@ubnt/icons/MobileHotspotLogo'

export const SITE_NAME_TO_HIDE = 'Default'

export const getSubscriptionServiceText = (
  productLine: string,
  nrOfSubscriptions: number
) => {
  switch (productLine) {
    case ProductName.UNIFI_TALK:
    case ProductName.SITE_SUPPORT:
    case ProductName.ULTE: {
      const type = nrOfSubscriptions === 1 ? 'Site' : 'Sites'
      return `${nrOfSubscriptions} ${type}`
    }
    case ProductName.UISP_CLOUD:
    case ProductName.HOSTING: {
      const type = nrOfSubscriptions === 1 ? 'Hosting' : 'Hostings'
      return `${nrOfSubscriptions} ${type}`
    }
    case ProductName.CYBERSECURE:
    case ProductName.MOBILITY: {
      const type = nrOfSubscriptions === 1 ? 'Device' : 'Devices'
      return `${nrOfSubscriptions} ${type}`
    }
    default:
      const type = nrOfSubscriptions === 1 ? 'Instance' : 'Instances'
      return `${nrOfSubscriptions} ${type}`
  }
}

export const getSubscriptionServiceUrl = (productLine: string) => {
  switch (productLine) {
    case ProductName.UNIFI_TALK: {
      return SubscriptionUrl.UNIFI_TALK
    }
    case ProductName.SITE_SUPPORT: {
      return SubscriptionUrl.SITE_SUPPORT
    }
    case ProductName.MSP_SITE_SUPPORT: {
      return SubscriptionUrl.MSP_SITE_SUPPORT
    }
    case ProductName.ULTE: {
      return SubscriptionUrl.ULTE
    }
    case ProductName.HOSTING: {
      return SubscriptionUrl.HOSTING
    }
    case ProductName.CYBERSECURE: {
      return SubscriptionUrl.CYBERSECURE
    }
    case ProductName.MOBILITY: {
      return SubscriptionUrl.MOBILITY
    }
    case ProductName.UISP_CLOUD: {
      return SubscriptionUrl.UISP_CLOUD
    }
    default:
      return SubscriptionUrl.UNKNOWN
  }
}

export const getSubscriptionIcon = (
  productLine: string,
  dimensions = '20px',
  variant: LogoProps['variant'] = 'flat',
  padding = '0'
): React.ReactNode => {
  switch (productLine) {
    case ProductName.ULTE: {
      return (
        <Wrapper style={{ height: dimensions, width: dimensions, padding }}>
          <NetworkLogo
            variant={variant}
            height={dimensions}
            width={dimensions}
          />
        </Wrapper>
      )
    }
    case ProductName.UNIFI_TALK: {
      return (
        <Wrapper style={{ height: dimensions, width: dimensions, padding }}>
          <TalkLogo variant={variant} height={dimensions} width={dimensions} />
        </Wrapper>
      )
    }
    case ProductName.HOSTING: {
      return (
        <Wrapper style={{ height: dimensions, width: dimensions, padding }}>
          <NetworkLogo
            variant={variant}
            height={dimensions}
            width={dimensions}
          />
        </Wrapper>
      )
    }
    case ProductName.MOBILITY: {
      return (
        <Wrapper style={{ height: dimensions, width: dimensions, padding }}>
          <MobileHotspotLogo
            variant={variant}
            height={dimensions}
            width={dimensions}
          />
        </Wrapper>
      )
    }
    case ProductName.MSP_SITE_SUPPORT:
    case ProductName.SITE_SUPPORT: {
      return (
        <Wrapper style={{ height: dimensions, width: dimensions, padding }}>
          <SiteSupportLogo
            variant={variant}
            height={dimensions}
            width={dimensions}
          />
        </Wrapper>
      )
    }
    case ProductName.UISP_CLOUD: {
      return (
        <Wrapper style={{ height: dimensions, width: dimensions, padding }}>
          <UispLogo variant={variant} height={dimensions} width={dimensions} />
        </Wrapper>
      )
    }
    case ProductName.CYBERSECURE: {
      return (
        <Wrapper style={{ height: dimensions, width: dimensions, padding }}>
          <InternetShieldIcon
            height={dimensions}
            width={dimensions}
            variant="fill"
          />
        </Wrapper>
      )
    }
    default:
      return <div></div>
  }
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 20px;

  svg {
    color: ${({ theme }) => theme.neutral08};
  }
`

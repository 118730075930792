import React from 'react'

import { Section } from 'components/Section.styles'
import { WrappedBenefitsMoreModal } from 'features/early-access/ui/BenefitsModal'
import { WrappedEarlyAccessAboutModal } from 'features/early-access/ui/AboutEarlyAccessModal'
import { WrappedLearnMoreModal } from 'features/early-access/ui/LearnMoreModal'
import { ProtectedPage } from 'pages/ProtectedPage'
import AppHeader from 'components/AppViewHeader'

import { WrappedChangeUsernameModal } from './ChangeUsernameModal'
import { ProfileInfoFormConnected } from './ProfileInfoForm'

const Profile = () => {
  return (
    <ProtectedPage>
      <AppHeader headerId="COMMON_SIDE_NAV_PROFILE" />
      <Section $omitMarginTop>
        <ProfileInfoFormConnected />
      </Section>

      <WrappedLearnMoreModal />
      <WrappedBenefitsMoreModal />
      <WrappedChangeUsernameModal />
      <WrappedEarlyAccessAboutModal />
    </ProtectedPage>
  )
}

export default Profile

export const DAY_IN_SECONDS = 86400
export const ALL_KEY = 'all'
export const ALL_LABEL = 'All'
export const ADD_PAYMENT_VIEW_QUERY = 'addpaymentview'

export enum StripeStatus {
  VOID = 'void',
  PAID = 'paid',
  OPEN = 'open',
  DRAFT = 'draft',
  UNCOLLECTIBLE = 'uncollectible',
}

import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  CardsGrid,
  CardsTableItem,
  EmptyCardsTableItem,
} from './Components.styles'
import { CardRow } from './CardRow'
import { CardType } from 'features/payment-methods/modules/types'

interface Props {
  cards: CardType[]
}

export const CardTable: React.FC<Props> = ({ cards }) => {
  return (
    <CardsGrid>
      <CardsTableItem variant="heading-medium" color="text-1">
        <FormattedMessage id="SETTINGS_PAYMENTS_LABEL_CARD" />
      </CardsTableItem>
      <CardsTableItem
        variant="heading-medium"
        color="text-1"
        className="extra-data"
      >
        <FormattedMessage id="SETTINGS_PAYMENTS_LABEL_CARD_NAME" />
      </CardsTableItem>
      <CardsTableItem
        variant="heading-medium"
        color="text-1"
        className="extra-data"
      >
        <FormattedMessage id="SETTINGS_PAYMENTS_LABEL_CARD_DATE" />
      </CardsTableItem>
      <CardsTableItem
        variant="heading-medium"
        color="text-1"
        className="extra-data"
      >
        <FormattedMessage id="SETTINGS_PAYMENTS_LABEL_REGION" />
      </CardsTableItem>
      <EmptyCardsTableItem />
      {cards.map((card) => (
        <CardRow card={card} key={card.id} />
      ))}
    </CardsGrid>
  )
}

import { all, put, spawn, takeEvery } from 'redux-saga/effects'

import createDataModule from 'utils/moduleCreator'
import { closeVisibleModal } from 'modules/modals'
import { fetchMFA } from './mfa'
import { MFAAuthenticator, MFAType } from './types'

const {
  api: { sso, ssoUi },
} = __CONFIG__

export const mfaEditMethodDataKey = 'mfaEditMethodDataKey'

const putUrl = (
  url: string,
  { type, mfaId }: { type: MFAType; mfaId: string }
) => {
  return `${url}/${type}/${mfaId}`
}

const dataModule = createDataModule<MFAAuthenticator>(
  mfaEditMethodDataKey,
  sso.paths.mfa,
  ssoUi.base,
  undefined,
  putUrl
)

export const { update: editMfa, reducer: editMfaReducer } = dataModule

function* EditMfaDoneSaga() {
  yield put(closeVisibleModal())
  yield put(fetchMFA())
}

function* subscribeToUpdateTaxIdDoneSaga() {
  yield takeEvery(dataModule.UPDATE_DONE, EditMfaDoneSaga)
}

export function* editMfaRootSaga() {
  yield all([spawn(dataModule.rootSaga), spawn(subscribeToUpdateTaxIdDoneSaga)])
}

import React from 'react'
import ChoosePaymentMethodModal from 'pages/subscriptions/components/subscriptionServices/ChoosePaymentMethodModal'
import { Section } from 'components/Section.styles'
import { FlexWrapper } from 'components/SharedComponents'
import { NextPaymentBanner } from './NextPaymentBanner'
import { SubscriptionsTable } from './SubscriptionsTable'

export const SubscriptionsList = () => {
  return (
    <FlexWrapper column gap={16} width="100%">
      <NextPaymentBanner />
      <Section $omitMarginTop>
        <SubscriptionsTable />
      </Section>
      <ChoosePaymentMethodModal />
    </FlexWrapper>
  )
}

import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'theme/styled'
import theme, { typography } from 'theme'
import supportImage from 'assets/images/support/supportHeader.png'
import supportImageLow from 'assets/images/support/supportHeader_low.png'
import { BackButton } from 'components/BackButton'
import { MediaSizingContext } from 'components/mediaSizing/MediaSizingContext'
import { MaintenanceInfo } from 'features/status/ui'

export const LayoutProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const { isMaxTabletLarge } = useContext(MediaSizingContext)
  const [imageLoaded, setImageLoaded] = useState(false)

  useEffect(() => {
    const imageToLoad = new Image()
    imageToLoad.src = supportImage
    imageToLoad.onload = () => setImageLoaded(true)
  }, [])

  return (
    <Wrapper $isLargeTablet={isMaxTabletLarge}>
      <ButtonWrapper>
        <BackButton to="/requests" />
        <MaintenanceInfo omitMarginBottom />
      </ButtonWrapper>
      <Title $isLargeTablet={isMaxTabletLarge}>
        <FormattedMessage id="SUPPORT_HEADER_TITLE" />
      </Title>
      <Description $isLargeTablet={isMaxTabletLarge}>
        <FormattedMessage id="SUPPORT_HEADER_DESCRIPTION" />
      </Description>

      {!isMaxTabletLarge && <HeaderImage $imageLoaded={imageLoaded} />}

      {children}
    </Wrapper>
  )
}

const ButtonWrapper = styled.div`
  display: flex;
  gap: 6px;
  align-items: baseline;
  @media (max-width: ${theme.media.tabletLarge}) {
    align-self: flex-start;
  }

  @media (max-width: ${theme.media.mobileXLarge}) {
    flex-direction: column;
  }
`

const Wrapper = styled.div<{
  $isLargeTablet: boolean
}>`
  position: relative;
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: ${({ $isLargeTablet }) =>
    $isLargeTablet ? 'center' : 'flex-start'};
  padding-top: 18px;
`

const Title = styled.div<{ $isLargeTablet: boolean }>`
  max-width: 345px;
  width: 100%;
  font: ${typography['support-header']};
  color: ${({ theme }) => theme.neutral11};
  padding: 32px 0 8px 0;
  text-align: ${({ $isLargeTablet }) => ($isLargeTablet ? 'center' : 'left')};

  @media (max-width: ${theme.media.mobileLarge}) {
    line-height: normal;
  }
`

const Description = styled.div<{
  $isLargeTablet: boolean
}>`
  max-width: 345px;
  width: 100%;
  font: ${typography['body-large']};
  color: #858585;
  padding-bottom: 32px;
`

const HeaderImage = styled.div<{
  $imageLoaded: boolean
}>`
  position: absolute;
  top: -50px;
  right: -60px;
  width: 616px;
  height: 520px;
  background-size: contain;
  ${({ $imageLoaded }) => {
    if (!$imageLoaded) {
      return `
        -webkit-filter: blur(6px);
        -moz-filter: blur(6px);
        -o-filter: blur(6px);
        -ms-filter: blur(6px);
        filter: blur(6px);
        background-image: url(${supportImageLow})
      `
    }
    if ($imageLoaded) {
      return `
        background-image: url(${supportImage})
  
      `
    }
  }}
`

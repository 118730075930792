import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { api } from 'api'
import { useDebouncedCallback } from 'use-debounce'
import { PASSWORD_MIN_LENGTH } from '../checkPasswprdRequirements'
import { isAxiosError } from 'axios'
import {
  PasswordFeedbackData,
  passwordScoreBarWidth,
  passwordScoreColor,
  passwordScoreText,
} from '../PasswordFeedback'

export const useVerifyPassword = () => {
  const intl = useIntl()
  const [passwordData, setPasswordData] = useState<PasswordFeedbackData | null>(
    null
  )
  const [isVerifyPasswordLoading, setIsVerifyPasswordLoading] =
    useState<boolean>(false)
  const [isPasswordLongEnough, setIsPasswordLongEnough] =
    useState<boolean>(false)

  const verifyPassword = useDebouncedCallback(
    async (password?: string, username?: string, email?: string) => {
      if (!password) {
        return
      }
      setIsVerifyPasswordLoading(true)
      const longEnough = password.length >= PASSWORD_MIN_LENGTH

      setIsPasswordLongEnough(longEnough)

      if (longEnough) {
        const userInputs: string[] = [username, email].filter(
          (userInput): userInput is string => !!userInput
        )
        try {
          const { is_acceptable_password, suggestions, warning, score } =
            await api.checkPassword(password, userInputs)

          if (warning) {
            suggestions.unshift(warning)
          }

          if (score !== undefined) {
            setPasswordData({
              is_acceptable_password: is_acceptable_password ?? null,
              suggestions: suggestions ?? null,
              score: score,
              barWidth: passwordScoreBarWidth(score),
              feedbackColor: passwordScoreColor(score),
              text: (
                <FormattedMessage
                  id={passwordScoreText(score)}
                  values={{
                    b: (text) => <b className="intl-message">{text}</b>,
                  }}
                />
              ),
              error: null,
            })
          }
        } catch (e) {
          if (isAxiosError(e)) {
            setPasswordData({
              is_acceptable_password: null,
              suggestions: null,
              score: null,
              barWidth: null,
              feedbackColor: null,
              text: null,
              error: intl.formatMessage({
                id: 'COMMON_AUTH_PASSWORD_CHANGE_FAILED',
              }),
            })
          }
        }
      }
      setIsVerifyPasswordLoading(false)
    },
    200
  )

  return {
    verifyPassword,
    isVerifyPasswordLoading,
    isPasswordLongEnough,
    passwordData,
  }
}

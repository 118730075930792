export enum QueryKey {
  LEGAL = 'legal',
  SESSIONS = 'sessions',
  ANNOUNCEMENTS = 'announcements',
  BILLING_CUSTOMER = 'billingCustomer',
  BILLING_CARDS = 'billingCards',
  BILLING_TAX_ID = 'billingTaxId',
  BILLING_SUBSCRIPTIONS = 'billingSubscriptions',
  BILLING_PRODUCTS = 'billingProducts',
  BILLING_INVOICES = 'billingInvoices',
  BILLING_INVOICE_LINES = 'billingInvoiceLines',
  BILLING_UPCOMING_INVOICES = 'billingUpcomingInvoices',
  BILLING_INVOICE_YEARS = 'billingInvoiceYears',
  BILLING_OPEN_INVOICES = 'billingOpenInvoices',
  ACTIVITY = 'activity',
  STATUS = 'status',
  STATUS_ACTIVE = 'statusActive',
  STATUS_UPCOMING = 'statusUpcoming',
  STATUS_INCIDENTS = 'statusIncidents',
  UMR_DEVICES = 'umrDevices',
  UMR_DEVICE = 'umrDevice',
  TALK_INSTALLS = 'talkInstalls',
  OPEN_TICKETS = 'openTickets',
  CLOSED_TICKETS = 'closedTickets',
  CCD_TICKETS = 'ccdTickets',
  NCA_DEVICES = 'ncaDevices',
  NCA_BACKUPS = 'ncaBackups',
  NCA_ARCHIVED_BACKUPS = 'ncaArchivedBackups',
  TRUSTED_DEVICES = 'trustedDevices',
  TRUST_DEVICE = 'trustDevice',
  LOGIN_ISSUE_FEEDBACK = 'loginIssueFeedback',
  PASSKEY_AVAILABLE = 'passkeyAvailable',
  SHOULD_DISPLAY_PASSKEY_BANNER = 'shouldDisplayPasskeyBanner',
  CHAT_SCHEDULE = 'chatSchedule',
  CHAT_ADMIN_SCHEDULE = 'chatAdminSchedule',
}

import type Stripe from 'stripe'
import { loadStripe } from '@stripe/stripe-js'
import { fetchRequestWithCookies } from 'api/axios'
import { StripeRegionCode } from 'features/stripe/ui/types'
import { ExpandedSubscription } from 'features/subscriptions/module/types'
import { BrowserUtils } from 'utils/browserUtils'
import cardVisaSVG from 'assets/svgs/cardVisa.svg'
import cardMCSVG from 'assets/svgs/cardMC.svg'
import cardDiscSVG from 'assets/svgs/cardDisc.svg'
import cardUnionpaySVG from 'assets/svgs/cardUnionpay.svg'
import cardOtherSVG from 'assets/svgs/cardOther.svg'
import cardDinersSVG from 'assets/svgs/cardDiners.svg'
import cardAmexSVG from 'assets/svgs/cardAmex.svg'
import cardJCBSVG from 'assets/svgs/cardJCB.svg'

type QueryParams3DS = {
  subscriptionId?: string | null
  url?: string | null
  region?: StripeRegionCode | null
}

const {
  api: { billing },
  STRIPE_PUBLISHABLE_KEYS,
} = __CONFIG__

export const get3DSDataFromQuery = (): QueryParams3DS => {
  const subscriptionId = BrowserUtils.getQueryValue(
    window.location.href,
    'subscription_id'
  )

  const url = BrowserUtils.getQueryValue(window.location.href, 'return_url')

  const region = BrowserUtils.getQueryValue<StripeRegionCode>(
    window.location.href,
    'region'
  )

  return { subscriptionId, url, region }
}

export const getSubscriptionFromId = async (
  id: string,
  region: StripeRegionCode
) => {
  const url = new URL(`${billing.paths.subscriptions}/${id}`, billing.base)
  url.searchParams.append('region', region)
  url.searchParams.append('expand[]', 'latest_invoice.payment_intent')

  const { data } = await fetchRequestWithCookies<{
    [key in StripeRegionCode]: ExpandedSubscription
  }>(url.href)

  return data
}

export const initiate3DSFlow = async (
  region: StripeRegionCode,
  url: string,
  clientSecret: string,
  paymentMethod:
    | string
    | Stripe.PaymentMethod
    | Stripe.CustomerSource
    | Stripe.DeletedCustomerSource
) => {
  const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEYS[region])

  const res = await stripe?.confirmCardPayment(
    clientSecret,
    {
      payment_method:
        typeof paymentMethod === 'string' ? paymentMethod : paymentMethod.id,
      return_url: url,
    },
    { handleActions: false }
  )

  if (res?.paymentIntent?.next_action?.redirect_to_url?.url) {
    window.location.href = res.paymentIntent.next_action.redirect_to_url.url
  }
}

export const getPaymentMethodIcon = (brand?: string | null): string => {
  switch (brand?.toLowerCase()) {
    case 'mastercard':
      return cardMCSVG
    case 'discover':
      return cardDiscSVG
    case 'unionpay':
      return cardUnionpaySVG
    case 'jcb':
      return cardJCBSVG
    case 'american express':
    case 'amex':
      return cardAmexSVG
    case 'diners club':
    case 'diners':
      return cardDinersSVG
    case 'visa':
      return cardVisaSVG
    case 'unknown':
      return cardOtherSVG
    default:
      return cardOtherSVG
  }
}

export const parsePaymentMethodBrand = (display_brand: string): string => {
  const brandInfo = display_brand.replace('_', ' ').split(' ')
  let brand = ''
  brandInfo.forEach((string) => {
    brand += string[0].toUpperCase() + string.substring(1) + ' '
  })
  if (brand === 'Mastercard ') {
    brand = 'MasterCard'
  }
  if (brand === 'American Express ') {
    brand = 'Amex'
  }
  return brand
}

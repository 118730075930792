import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { dataKey as authDataKey, authReducer } from 'features/auth/modules/auth'
import {
  dataKey as assuranceLevelDataKey,
  assuranceLevelReducer,
} from 'features/auth/modules/assuranceLevel'
import {
  dataKey as authResetDataKey,
  authResetReducer,
} from 'features/auth/modules/resetPassword'
import {
  dataKey as profilePictureDataKey,
  profilePictureReducer,
} from 'features/profile-picture/module/profilePicture'
import { ReduxAction } from 'types/types'
import {
  dataKey as authResendVerificationDataKey,
  authResendVerificationReducer,
} from 'features/auth/modules/resendVerification'
import {
  dataKey as rolesDataKey,
  rolesReducer,
} from 'features/early-access/module/roles'
import { mfaDataKey, mfaReducer } from 'modules/mfa'
import { mfaSetupDataKey, mfaSetupReducer } from 'modules/mfaSetup'
import { mfaAddedDataKey, mfaAddedReducer } from 'modules/mfaAdded'
import {
  generateBackupDataKey,
  generateBackupReducer,
} from 'pages/security/components/BackupCodes/modules'
import {
  reauthMFADataKey,
  reauthMFAReducer,
} from 'features/auth/modules/reauth'

import {
  removePushDataKey,
  removePushReducer,
} from 'pages/security/components/Push/modules/removePush'
import {
  removePendingPushDataKey,
  removePendingPushReducer,
} from 'pages/security/components/Push/modules/removePendingPush'
import {
  removePendingTOTPDataKey,
  removePendingTOTPReducer,
} from 'pages/security/components/TOTP/modules/removePendingTOTP'
import {
  dataKey as deleteAccountDataKey,
  deleteAccountReducer,
} from 'features/delete-account/module/deleteAccount'
import {
  addressesDataKey,
  addressesReducer,
} from 'features/addresses/module/addresses'
import {
  dataKey as changeEmailDataKey,
  requestEmailVerificationReducer,
} from 'pages/security/changeEmail'

import {
  initiateEmailDataKey,
  initiateEmailReducer,
} from 'pages/security/components/Email/modules/initiateEmail'
import {
  removeMFAEmailDataKey,
  removeMFAEmailReducer,
} from 'pages/security/components/Email/modules/removeEmail'
import {
  sendEmailDataKey,
  sendEmailReducer,
} from 'pages/security/components/Email/modules/sendEmail'
import {
  verifyEmailDataKey,
  verifyEmailReducer,
} from 'pages/security/components/Email/modules/verifyEmail'
import {
  initiatePushDataKey,
  inititatePushReducer,
} from 'pages/security/components/Push/modules/initiatePush'
import {
  loginPushDataKey,
  loginPushReducer,
} from 'pages/security/components/Push/modules/loginPush'
import {
  verifyPushDataKey,
  verifyPushReducer,
} from 'pages/security/components/Push/modules/verifyPush'
import {
  removeMFASMSDataKey,
  removeMFASMSReducer,
} from 'pages/security/components/SMS/modules/removeMFASMS'
import {
  sendMFASMSDataKey,
  sendMFASMSReducer,
} from 'pages/security/components/SMS/modules/sendMFASMS'
import {
  initatePasskeyLoginReducer,
  initiatePasskeyLoginDataKey,
  passkeyLoginRequestOptionsDataKey,
  passkeyLoginRequestOptionsReducer,
  verifyLoginPasskeyDataKey,
  verifyPasskeyLoginReducer,
} from 'pages/security/components/Passkey/modules/loginPasskey'
import {
  removePasskeyDataKey,
  removePasskeyReducer,
} from 'pages/security/components/Passkey/modules/removePasskey'
import {
  initiatePasskeyDataKey,
  initiatePasskeyReducer,
  verifyPasskeyDataKey,
  verifyPasskeyReducer,
} from 'pages/security/components/Passkey/modules/setupPasskey'
import {
  initiateTOTPDataKey,
  inititateTOTPReducer,
} from 'pages/security/components/TOTP/modules/initiateTOTP'
import {
  removeTOTPDataKey,
  removeTOTPReducer,
} from 'pages/security/components/TOTP/modules/removeTOTP'
import {
  verifyTOTPDataKey,
  verifyTOTPReducer,
} from 'pages/security/components/TOTP/modules/verifyTOTP'
import {
  uiTaxIdDataKey,
  uiTaxIdsReducer,
} from 'features/subscriptions/module/uiTaxIds'
import {
  BusinessProfileReducer,
  businessProfiledDataKey,
} from 'features/subscriptions/module/businessProfile'
import { dataKey as modalsDataKey, modalsReducer } from './modals'
import { dataKey as profileDataKey, profileReducer } from './profile'
import { editMfaReducer, mfaEditMethodDataKey } from './mfaEdit'

export const createRootReducer = () =>
  combineReducers({
    [authDataKey]: persistReducer<ReturnType<typeof authReducer>, ReduxAction>(
      { key: authDataKey, whitelist: ['isAuthenticated'], storage },
      authReducer
    ),
    [profileDataKey]: profileReducer,
    [profilePictureDataKey]: persistReducer<
      ReturnType<typeof profilePictureReducer>,
      ReduxAction
    >(
      {
        key: profilePictureDataKey,
        whitelist: ['data'],
        storage,
      },
      profilePictureReducer
    ),
    [modalsDataKey]: modalsReducer,
    [authResetDataKey]: authResetReducer,
    [authResendVerificationDataKey]: authResendVerificationReducer,
    [rolesDataKey]: rolesReducer,
    [uiTaxIdDataKey]: uiTaxIdsReducer,
    [businessProfiledDataKey]: BusinessProfileReducer,
    [mfaDataKey]: mfaReducer,
    [mfaEditMethodDataKey]: editMfaReducer,
    [initiatePasskeyLoginDataKey]: initatePasskeyLoginReducer,
    [passkeyLoginRequestOptionsDataKey]: passkeyLoginRequestOptionsReducer,
    [verifyLoginPasskeyDataKey]: verifyPasskeyLoginReducer,
    [initiatePasskeyDataKey]: initiatePasskeyReducer,
    [verifyPasskeyDataKey]: verifyPasskeyReducer,
    [removePasskeyDataKey]: removePasskeyReducer,
    [initiateTOTPDataKey]: inititateTOTPReducer,
    [removeTOTPDataKey]: removeTOTPReducer,
    [removePendingTOTPDataKey]: removePendingTOTPReducer,
    [verifyTOTPDataKey]: verifyTOTPReducer,
    [initiatePushDataKey]: inititatePushReducer,
    [verifyPushDataKey]: verifyPushReducer,
    [removePushDataKey]: removePushReducer,
    [removePendingPushDataKey]: removePendingPushReducer,
    [loginPushDataKey]: loginPushReducer,
    [mfaSetupDataKey]: mfaSetupReducer,
    [removeMFASMSDataKey]: removeMFASMSReducer,
    [sendMFASMSDataKey]: sendMFASMSReducer,
    [initiateEmailDataKey]: initiateEmailReducer,
    [verifyEmailDataKey]: verifyEmailReducer,
    [sendEmailDataKey]: sendEmailReducer,
    [generateBackupDataKey]: generateBackupReducer,
    [reauthMFADataKey]: reauthMFAReducer,
    [removeMFAEmailDataKey]: removeMFAEmailReducer,
    [assuranceLevelDataKey]: assuranceLevelReducer,
    [mfaAddedDataKey]: mfaAddedReducer,
    [deleteAccountDataKey]: deleteAccountReducer,
    [addressesDataKey]: addressesReducer,
    [changeEmailDataKey]: requestEmailVerificationReducer,
  })

import { TaxIdType } from 'features/stripe/ui/types'

export type TaxIdState = {
  type: TaxIdType | undefined
  value: string
  country: string
  placeholder?: string
  dropdownValue: string
  region: string
}

export type RegionDropdownOption = {
  value: string
  label: string
  image?: React.ReactElement
  type: TaxIdType | undefined
  taxFormat: RegExp
  taxPlaceholder: string
  country: string
  region: string
}

export type NewTaxIdSate = {
  dropdownValue: RegionDropdownOption['value']
  country: RegionDropdownOption['country']
  type: RegionDropdownOption['type']
  placeholder: RegionDropdownOption['taxPlaceholder']
}

export enum PaymentMethodErrors {
  DUPLICATE_CARD = 'Duplicate card is not allowed.',
  INVALID_COUNTRY = 'Invalid card country.',
  SANCTIONED = 'Cannot add card since the address or ip is in sanctioned area',
  POSTAL_CODE = 'Your card could not be authorized using the postal code provided.',
}

import React from 'react'
import MailsCloseIcon from '@ubnt/icons/MailsCloseIcon'
import {
  IconMFAWrapper,
  StyledMFAImg,
} from 'pages/security/components/SetupMFAModal/MFAOption'
import UIVerify from 'assets/images/uiVerify.png'
import AuthIcon from '@ubnt/icons/AuthIcon'
import PasskeyIcon from '@ubnt/icons/PasskeyIcon'

export const config = {
  uiVerify: {
    icon: <StyledMFAImg src={UIVerify} alt="UI Verify" size="46px" />,
    title: 'SETTINGS_MFA_METHOD_PUSH',
    description: 'ADD_MFA_PROMPT_METHOD_PUSH_DESCRIPTION',
    url: '/security?addmfa=push',
  },
  totp: {
    icon: (
      <IconMFAWrapper iconBorder>
        <AuthIcon variant="twoTone" isActive size={32} />
      </IconMFAWrapper>
    ),
    title: 'SETTINGS_MFA_METHOD_TOTP',
    description: 'ADD_MFA_PROMPT_METHOD_APP_DESCRIPTION',
    url: '/security?addmfa=totp',
  },
  email: {
    icon: (
      <IconMFAWrapper iconBorder>
        <MailsCloseIcon variant="twoTone" isActive size={32} />
      </IconMFAWrapper>
    ),
    title: 'SETTINGS_MFA_METHOD_EMAIL',
    description: 'ADD_MFA_PROMPT_METHOD_EMAIL_DESCRIPTION',
    url: '/security?addmfa=email',
  },
  webAuthn: {
    icon: (
      <IconMFAWrapper iconBorder>
        <PasskeyIcon variant="twoTone" isActive size={32} />
      </IconMFAWrapper>
    ),
    title: 'SETTINGS_MFA_METHOD_PASSKEY',
    description: 'SETTINGS_MFA_METHOD_PASSKEY_DESCRIPTION',
    url: '/security?addmfa=webAuthn',
  },
}
